import React from 'react'
import { Helmet } from 'react-helmet'
import PAGE_URLS from '../../constant/Index';

const MetaTags = ({ content, metaTitle, pageName, href }) => {
  const base_url = PAGE_URLS.BASE_URL;

    return (
        <div>
            <Helmet>
                <link rel="canonical" href={base_url + href} />
                <title>{pageName}</title>
                <meta name="description" content={content} />
                <meta name="title" content={metaTitle} />
            </Helmet>
        </div>
    )
}

export default MetaTags;
