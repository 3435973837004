import Image from "../image/Image.js";
import { Link } from "react-router-dom";
import React from "react";
import PhoneOutline from "../../../assets/Icons/phone__outline.jsx";
import MailOutline from "../../../assets/Icons/mail__outline.jsx";
import LocationOutline from "../../../assets/Icons/location__outline.jsx";
import "./Footer.css";
import { useTranslation } from "react-i18next";
import RoutesLink from "../../../constant/RoutesLink.js";
const Footer = () => {
    const { t } = useTranslation();
    return (
        <div className="footer__section section">
            <div className="footer__header">
                <Image src="/images/footer__logo.png" width={120} height={162} alt="" />
                <p className="poppins__18">
                    {t("footer.heading")}
                </p>
            </div>
            <div className="footer__links">
                <Link to={RoutesLink.HOME} className='small__btn'>
                    {t("footer.link1")}
                </Link>
                <Link to={RoutesLink.REFERENCER} className='small__btn'>
                    {t("footer.link2")}
                </Link>
                <Link to={RoutesLink.ABOUT} className='small__btn'>
                    {t("footer.link3")}
                </Link>
                <Link to={RoutesLink.PRIVACY_POLICY} className='small__btn'>
                    {t("footer.link4")}
                </Link>
                <Link to={RoutesLink.TRADES_CONDITIONS} className='small__btn'>
                    {t("footer.link5")}
                </Link>
            </div>
            <div className="footer__contacts">
                <div className="contact__div border__right" style={{ display: 'flex' }}>
                    <div>
                        <PhoneOutline />
                    </div>
                    <a href="tel:+4578706445" className="poppins__18">
                        78 70 64 45
                    </a>
                </div>
                <div className="contact__div border__right" style={{ display: 'flex' }}>
                    <div>
                        <MailOutline />
                    </div>
                    <a href="mailto:info@yaksport.dk" className="poppins__18">
                        info@yaksport.dk
                    </a>
                </div>
                <div className="contact__div" style={{ display: 'flex' }}>
                    <div>
                        <LocationOutline iconColor="#333" />
                    </div>
                    <a href="https://www.google.com/maps/place/Karen+Blixens+Blvd.+7,+8220+Brabrand,+Denmark/@56.1616738,10.1340881,17z/data=!3m1!4b1!4m6!3m5!1s0x464c1547e918fbd7:0x6aac21b38ced1c24!8m2!3d56.1616738!4d10.1340881!16s%2Fg%2F11fklgzrdv?entry=ttu" target="_blank" rel="noopener noreferrer"  className="poppins__18">
                        Karen Blixens Blvd. 7, 8220 Brabrand
                    </a>
                </div>
            </div>
            <div className="footer__links copy__right">
                <Link to="/" className='poppins__18'>
                    © Yak Sport
                </Link>
                <Link to="/" className='poppins__18'>
                    CVR: 38 71 56 90
                </Link>
            </div>
            <div className="social__links">
                <Link to="https://www.facebook.com/yaksportdk/" target="_blank" rel="noopener noreferrer">
                    <span className="back__icon">
                        <i className="ri-facebook-fill" ></i>
                    </span>
                </Link>
                <Link to="https://www.instagram.com/yaksportdk/" target="_blank" rel="noopener noreferrer">
                    <span className="back__icon">
                        <i className="ri-instagram-fill"></i>
                    </span>
                </Link>
                <Link to="https://www.youtube.com/channel/UCpui6kUydOTm-Tj-cq0CRJQ" target="_blank" rel="noopener noreferrer">
                    <span className="back__icon">
                        <i className="ri-youtube-fill"></i>
                    </span>
                </Link>
                <Link to="https://www.tiktok.com/@yaksport" target="_blank" rel="noopener noreferrer">
                    <span className="back__icon">
                        <i className="ri-tiktok-fill"></i>
                    </span>
                </Link>
            </div>
        </div>
    )
}
export default Footer;