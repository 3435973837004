import React from "react";
import MailBlue from "../../../assets/Icons/mail__blue";
import CompanyBlue from "../../../assets/Icons/company__blue";
import Approved from "../../../assets/Images/approved.png"
import UserBlue from "../../../assets/Icons/user__blue";
import ComputerVideoCall from "../../../assets/Icons/computer__call"
import { useTranslation } from "react-i18next";
function ComponySection() {
  const {t} = useTranslation();
  return (
    <div className="inner__container position__rel">
      <img
        src={Approved}
        alt=""
        className="position__abs t__12 r__12 lg-t__60"
      />
      <div className="container__li">
        <CompanyBlue />
        <div className="d__flex flex__col w__full">
          <span className="li__bold row__text overflow__h w__full">
            Yak Sport ApS
          </span>
          <span className="poppins__18">{t("lead_offer_contract.behalf")}</span>
        </div>
      </div>
      <div className="container__li">
        <ComputerVideoCall className={"min__w__28 min__h__28"} />
        <div className="d__flex flex__col w__full">
          <span
            className="li__bold row__text overflow__h w__full"
            style={{ textOverflow: "ellipsis" }}
          >
            CEO
          </span>
          <span className="poppins__18 row__text overflow__h w__full">
            {t("lead_offer_contract.representativeTitle")}
          </span>
        </div>
      </div>
      <div className="container__li">
        <UserBlue />
        <div className="d__flex flex__col w__full">
          <span className="li__bold row__text overflow__h w__full">
            {t("lead_offer_contract.fullName")}
          </span>
          <span className="poppins__18">Ibrahim Öniz</span>
        </div>
      </div>
      <div className="container__li">
        <MailBlue />
        <div className="d__flex flex__col w__full">
          <span className="li__bold row__text overflow__h w__full">
            info@yaksport.dk
          </span>
          <span className="poppins__18">{t("lead_offer_contract.email")}</span>
        </div>
      </div>
      <span className="poppins__18">
        {t("lead_offer_contract.smsVerification")} 17 Feb 2024 at 09:48
      </span>
    </div>
  );
}
export default ComponySection;