import React from "react";
import { useTranslation } from "react-i18next";
import MetaTags from "../../components/SEO/MetaTags";
import PAGE_URLS from "../../constant/Index";

function TradeConditions() {
  const { t } = useTranslation()

  const description = `
  Explore YakSport's trading conditions outlining the terms and provisions for participation in holiday trips. 
  From enrollment procedures to responsibilities of both the traveler and the travel agency, 
  familiarize yourself with our comprehensive guidelines to ensure a smooth and enjoyable travel experience..
  `


  return (
    <div className="d__flex flex__col gap__20 section">
      <MetaTags href={"handelsbetingelser"} pageName={"handelsbetingelser"} content={description} metaTitle={"Trading Conditions | YakSport - Terms and Conditions for Holiday Trips"} />

      <h1 className="section__heading fs__44 ">
        {t("trade_conditions.heading")}
      </h1>
      <p className="paragraph">{t("trade_conditions.section_0_detail")}</p>

      <h1 className="section__heading">{t("trade_conditions.section_1_heading")}</h1>
      <p className="paragraph">{t("trade_conditions.section_1_detail_1")}</p>
      <p className="paragraph">{t("trade_conditions.section_1_detail_2")}</p>
      <p className="paragraph">{t("trade_conditions.section_1_detail_3")}</p>
      <p className="paragraph">{t("trade_conditions.section_1_detail_4")}</p>
      <p className="paragraph">{t("trade_conditions.section_1_detail_5")}</p>
      <p className="paragraph">{t("trade_conditions.section_1_detail_6")}</p>

      <h1 className="section__heading">{t("trade_conditions.section_2_heading")}</h1>
      <p className="paragraph">{t("trade_conditions.section_2_detail_1")}</p>
      <p className="paragraph">{t("trade_conditions.section_2_detail_2")}</p>
      <p className="paragraph">{t("trade_conditions.section_2_detail_3")}</p>
      <p className="paragraph">{t("trade_conditions.section_2_detail_4")}</p>
      <p className="paragraph">{t("trade_conditions.section_2_detail_5")}</p>
      <p className="paragraph">{t("trade_conditions.section_2_detail_6")}</p>
      <p className="paragraph">{t("trade_conditions.section_2_detail_7")}</p>
      <p className="paragraph">{t("trade_conditions.section_2_detail_8")}</p>
      <p className="paragraph">{t("trade_conditions.section_2_detail_9")}</p>
      <p className="paragraph">{t("trade_conditions.section_2_detail_10")}</p>
      <p className="paragraph">{t("trade_conditions.section_2_detail_11")}</p>

      <h1 className="section__heading">{t("trade_conditions.section_3_heading")}</h1>
      <p className="paragraph">{t("trade_conditions.section_3_detail_1")}</p>
      <p className="paragraph">{t("trade_conditions.section_3_detail_2")}</p>
      <p className="paragraph">{t("trade_conditions.section_3_detail_3")}</p>
      <p className="paragraph">{t("trade_conditions.section_3_detail_4")}</p>
      <p className="paragraph">{t("trade_conditions.section_3_detail_5")}</p>
      <p className="paragraph">{t("trade_conditions.section_3_detail_6")}</p>
      <p className="paragraph">{t("trade_conditions.section_3_detail_7")}</p>
      <p className="paragraph">{t("trade_conditions.section_3_detail_8")}</p>

      <h1 className="section__heading">{t("trade_conditions.section_4_heading")}</h1>
      <h2 className="sub__heading">{t("trade_conditions.section_4_subheading_1")}</h2>
      <p className="paragraph">{t("trade_conditions.section_4_detail_1")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_2")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_3")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_4")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_5")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_6")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_7")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_8")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_9")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_10")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_11")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_12")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_13")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_14")}</p>
      <h2 className="sub__heading">{t("trade_conditions.section_4_subheading_2")}</h2>
      <p className="paragraph">{t("trade_conditions.section_4_detail_15")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_16")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_17")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_18")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_19")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_20")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_21")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_22")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_23")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_24")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_25")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_26")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_27")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_28")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_29")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_30")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_31")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_32")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_33")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_34")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_35")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_36")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_37")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_38")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_39")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_40")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_41")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_42")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_43")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_44")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_45")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_46")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_47")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_48")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_49")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_50")}</p>
      <p className="paragraph">{t("trade_conditions.section_4_detail_51")}</p>

      <h1 className="section__heading">{t("trade_conditions.section_5_heading")}</h1>
      <p className="paragraph">{t("trade_conditions.section_5_detail_1")}</p>
      <p className="paragraph">{t("trade_conditions.section_5_detail_2")}</p>
      <p className="paragraph">{t("trade_conditions.section_5_detail_3")}</p>
      <p className="paragraph">{t("trade_conditions.section_5_detail_4")}</p>
      <p className="paragraph">{t("trade_conditions.section_5_detail_5")}</p>
      <p className="paragraph">{t("trade_conditions.section_5_detail_6")}</p>
      <p className="paragraph">{t("trade_conditions.section_5_detail_7")}</p>
      <p className="paragraph">{t("trade_conditions.section_5_detail_8")}</p>
      <p className="paragraph">{t("trade_conditions.section_5_detail_9")}</p>
      <p className="paragraph">{t("trade_conditions.section_5_detail_10")}</p>
      <p className="paragraph">{t("trade_conditions.section_5_detail_11")}</p>
      <p className="paragraph">{t("trade_conditions.section_5_detail_12")}</p>
      <p className="paragraph">{t("trade_conditions.section_5_detail_13")}</p>
      <p className="paragraph">{t("trade_conditions.section_5_detail_14")}</p>
      <p className="paragraph">{t("trade_conditions.section_5_detail_15")}</p>

      <h1 className="section__heading">{t("trade_conditions.section_6_heading")}</h1>
      <p className="paragraph">{t("trade_conditions.section_6_detail_1")}</p>
      <p className="paragraph">{t("trade_conditions.section_6_detail_2")}</p>
      <p className="paragraph">{t("trade_conditions.section_6_detail_3")}</p>
      <p className="paragraph">{t("trade_conditions.section_6_detail_4")}</p>
      <p className="paragraph">{t("trade_conditions.section_6_detail_5")}</p>
      <p className="paragraph">{t("trade_conditions.section_6_detail_6")}</p>
      <p className="paragraph">{t("trade_conditions.section_6_detail_7")}</p>


      <h1 className="section__heading">{t("trade_conditions.section_7_heading")}</h1>
      <p className="paragraph">{t("trade_conditions.section_7_detail_1")}</p>
      <p className="paragraph">{t("trade_conditions.section_7_detail_2")}</p>
      <p className="paragraph">{t("trade_conditions.section_7_detail_3")}</p>
      <p className="paragraph">{t("trade_conditions.section_7_detail_4")}</p>
      <p className="paragraph">{t("trade_conditions.section_7_detail_5")}</p>
      <p className="paragraph">{t("trade_conditions.section_7_detail_6")}</p>
      <p className="paragraph">{t("trade_conditions.section_7_detail_7")}</p>
      <p className="paragraph">{t("trade_conditions.section_7_detail_8")}</p>
      <p className="paragraph">{t("trade_conditions.section_7_detail_9")}</p>
      <p className="paragraph">{t("trade_conditions.section_7_detail_10")}</p>
      <p className="paragraph">{t("trade_conditions.section_7_detail_11")}</p>
      <p className="paragraph">{t("trade_conditions.section_7_detail_12")}</p>
      <p className="paragraph">{t("trade_conditions.section_7_detail_13")}</p>
      <p className="paragraph">{t("trade_conditions.section_7_detail_14")}</p>
      <p className="paragraph">{t("trade_conditions.section_7_detail_15")}</p>
      <p className="paragraph">{t("trade_conditions.section_7_detail_16")}</p>
      <p className="paragraph">{t("trade_conditions.section_7_detail_17")}</p>

      <h1 className="section__heading">{t("trade_conditions.section_8_heading")}</h1>
      <p className="paragraph">{t("trade_conditions.section_8_detail_1")}</p>
      <p className="paragraph">{t("trade_conditions.section_8_detail_2")}</p>
      <p className="paragraph">{t("trade_conditions.section_8_detail_3")}</p>
      <p className="paragraph">{t("trade_conditions.section_8_detail_4")}</p>
      <p className="paragraph">{t("trade_conditions.section_8_detail_5")}</p>

      <h1 className="section__heading">{t("trade_conditions.section_9_heading")}</h1>
      <p className="paragraph">{t("trade_conditions.section_9_detail_1")}</p>
      <p className="paragraph">{t("trade_conditions.section_9_detail_2")}</p>
      <p className="paragraph">{t("trade_conditions.section_9_detail_3")}</p>
      <p className="paragraph">{t("trade_conditions.section_9_detail_4")}</p>
      <p className="paragraph">{t("trade_conditions.section_9_detail_5")}</p>
      <p className="paragraph">{t("trade_conditions.section_9_detail_6")}</p>
      <p className="paragraph">{t("trade_conditions.section_9_detail_7")}</p>
      <p className="paragraph">{t("trade_conditions.section_9_detail_8")}</p>
      <p className="paragraph">{t("trade_conditions.section_9_detail_9")}</p>
      <p className="paragraph">{t("trade_conditions.section_9_detail_10")}</p>
      <p className="paragraph">{t("trade_conditions.section_9_detail_11")}</p>
      <p className="paragraph">{t("trade_conditions.section_9_detail_12")}</p>

      <h1 className="section__heading">{t("trade_conditions.section_10_heading")}</h1>
      <p className="paragraph">{t("trade_conditions.section_10_detail_1")}</p>
      <p className="paragraph">{t("trade_conditions.section_10_detail_2")}</p>
      <p className="paragraph">{t("trade_conditions.section_10_detail_3")}</p>
      <p className="paragraph">{t("trade_conditions.section_10_detail_4")}</p>
      <p className="paragraph">{t("trade_conditions.section_10_detail_5")}</p>
      <p className="paragraph">{t("trade_conditions.section_10_detail_6")}</p>
      <p className="paragraph">{t("trade_conditions.section_10_detail_7")}</p>
      <p className="paragraph">{t("trade_conditions.section_10_detail_8")}</p>
      <p className="paragraph">{t("trade_conditions.section_10_detail_9")}</p>

      <h1 className="section__heading">{t("trade_conditions.section_11_heading")}</h1>
      <p className="paragraph">{t("trade_conditions.section_11_detail_1")}</p>
    </div>
  );
}
export default TradeConditions;