// import React, { useEffect, useState } from "react";
// import { useGetLeadContractQuery } from "../redux/storeApis";
// import { useParams } from "react-router";
// function useLeadContract() {
//   const { slug } = useParams();
//   const { data: leadContract, isLoading : isLoadingLeadContract } = useGetLeadContractQuery(slug);
//   const { hotel, lead_contract, lead_services, lead_contract_conditions } = leadContract?.data || {};
//   const { offer, expired_at } = lead_contract || {};
//   const [leadContractInfo, setLeadContractInfo] = useState({
//     userDetail: {},
//     clubName: "",
//     numberOfGuest: "",
//     hotelName: "",
//     startDate: "",
//     endDate: "",
//     cityName: "",
//     airportName: "",
//     flightTime: "",
//     flightReturnTime: "",
//     pricePerPersonArray: [],
//     expired_at: "",
//     remainingDays: null,
//     lead_services: [],
//     lead_contract_conditions: [],
//     milestones_detail: {},
//     milestone_date1: "",
//     milestone_date2: "",
//     isExpired : false
//   });
//   const getRemainingDays = (expired_at) => {
//     if (!expired_at) return null;
//     const expirationDate = new Date(expired_at);
//     const currentDate = new Date();
//     return Math.ceil((expirationDate - currentDate) / (1000 * 60 * 60 * 24));
//   };
//   console.log(leadContract, "leadContract");
//   useEffect(() => {
//     if (offer && hotel) {
//       setLeadContractInfo({
//         userDetail: offer?.lead?.lead_user || {},
//         clubName: offer?.lead?.club_name || "",
//         numberOfGuest: offer?.lead?.no_guests || "",
//         hotelName: hotel?.name || "",
//         startDate: offer?.from_date || "",
//         endDate: offer?.to_date || "",
//         cityName: offer?.hotel?.city?.name || "",
//         airportName: offer?.airport?.name || "",
//         flightTime: offer?.flight_time || "",
//         flightReturnTime: offer?.flight_return_time || "",
//         pricePerPersonArray: offer?.prices || [],
//         expired_at: expired_at || "",
//         remainingDays: getRemainingDays(expired_at),
//         lead_services: lead_services || [],
//         lead_contract_conditions: lead_contract_conditions || [],
//         milestones_detail: lead_contract?.milestones_detail || {},
//         milestone_date1: lead_contract?.milestone_date1 || "",
//         milestone_date2: lead_contract?.milestone_date2 || "",
//         isExpired: remainingDays < 0
//       });
//     }
//   }, [offer, hotel, expired_at, leadContract]);
//   return {
//     ...leadContractInfo,
//     lead_contract,
//     isLoadingLeadContract,
//   };
// }
// export default useLeadContract;

import React, { useEffect, useState } from "react";
import {
  useGetLeadContractQuery,
  useGetOtpCodeMutation,
} from "../redux/storeApis";
import { useParams } from "react-router";

function useLeadContract() {
  const { slug } = useParams();
  const [getOtpCodeData, { isLoading: isLoadingOtpCode }] =
    useGetOtpCodeMutation();
  const { data: leadContract, isLoading: isLoadingLeadContract } =
    useGetLeadContractQuery(slug);
  const {
    hotel,
    lead_contract,
    lead_services,
    lead_contract_conditions,
    lead_services_with_types,
    contract_condition_with_types
  } = leadContract?.data || {};
  const { offer, expired_at } = lead_contract || {};
  const [leadContractInfo, setLeadContractInfo] = useState({
    userDetail: {},
    clubName: "",
    numberOfGuest: "",
    hotelName: "",
    startDate: "",
    endDate: "",
    cityName: "",
    airportName: "",
    flightTime: "",
    flightReturnTime: "",
    pricePerPersonArray: [],
    expired_at: "",
    remainingDays: null,
    lead_services: [],
    lead_contract_conditions: [],
    milestones_detail: {},
    milestone_date1: "",
    milestone_date2: "",
    isExpired: false,
    status: "",
    contract_condition_with_types: [],
  });
  useEffect(() => {
    if (offer && hotel) {
      setLeadContractInfo({
        userDetail: offer?.lead?.lead_user || {},
        clubName: offer?.lead?.club_name || "",
        numberOfGuest: offer?.lead?.no_guests || "",
        hotelName: hotel?.name || "",
        startDate: offer?.from_date || "",
        endDate: offer?.to_date || "",
        cityName: offer?.hotel?.city?.name || "",
        airportName: offer?.airport?.name || "",
        flightTime: offer?.flight_time || "",
        flightReturnTime: offer?.flight_return_time || "",
        pricePerPersonArray: offer?.prices || [],
        expired_at: expired_at || "",
        lead_services: lead_services_with_types || [],
        lead_contract_conditions: lead_contract_conditions || [],
        milestones_detail: lead_contract?.milestones_detail || {},
        milestone_date1: lead_contract?.milestone_date1 || "",
        milestone_date2: lead_contract?.milestone_date2 || "",
        status: lead_contract?.status || "",
        contract_condition_with_types: contract_condition_with_types || [],
      });
    }
  }, [offer, hotel, expired_at, leadContract]);

  return {
    ...leadContractInfo,
    lead_contract,
    isLoadingLeadContract,
    getOtpCodeData,
    slug,
    isLoadingOtpCode,
  };
}

export default useLeadContract;
