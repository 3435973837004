import React, { useState } from "react";
import "./ContractPage.css";
import ComponyDetail from "../../components/cLeadContract/componyDetail/ComponyDetail";
import ClubDetail from "../../components/cLeadContract/clubDetail/ClubDetail";
import ContractDetail from "../../components/cLeadContract/contractDetail/ContractDetail";
import TravelDates from "../../components/cLeadContract/travelDates/TravelDates";
import PricePerPerson from "../../components/cLeadContract/pricePerPerson/PricePerPerson";
import ComponyWellcome from "../../components/cLeadContract/componyWellcome/ComponyWellcome";
import IncludeServices from "../../components/cLeadContract/includeServices/IncludeServices";
import MilestoneDetail from "../../components/cLeadContract/milestoneDetail/MilestoneDetail";
import ContractForTraning from "../../components/cLeadContract/contractForTraning/ContractForTraning";
import ComponySection from "../../components/cLeadContract/componySection/ComponySection";
import ClientSection from "../../components/cLeadContract/clientSection/ClientSection";
import useLeadContract from "../../hooks/useLeadContract";
import { useTranslation } from "react-i18next";
import Loader from "../../assets/Loader";
import CodeModal from "./CodeModal";
import { current } from "@reduxjs/toolkit";
const ContractPage = () => {
  const {
    expired_at,
    remainingDays,
    lead_services,
    lead_contract_conditions,
    contract_condition_with_types,
    isLoadingLeadContract,
    getOtpCodeData,
    isLoadingOtpCode,
    slug,
    status
  } = useLeadContract();
  const currentDate = new Date();
  const expiryDate = new Date(expired_at);
  console.log(currentDate, "currentDate");
  const [otpCodeData, setOptCodeData] = useState();
  const { t } = useTranslation();
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const handleOpenSignCodeModal = async () => {
    setModalType("sign");
    await getOtpCodeData({ slug, status: "success" })
      .unwrap()
      .then((res) => {
        if (res?.success) {
          setOptCodeData(res?.data?.leadUser);
        }
      });
    setShowCodeModal(true);
  };
  const handleOpenRejectCodeModal = async () => {
    setModalType("reject");
    await getOtpCodeData({ slug, status: "success" })
      .unwrap()
      .then((res) => {
        if (res?.success) {
          setOptCodeData(res?.data?.leadUser);
        }
      });
    setShowCodeModal(true);
  };

  const isExpired = remainingDays !== null && remainingDays < 0;
  return (
    <>
      {isLoadingLeadContract ? (
        <Loader />
      ) : (
        <div className="section d__flex flex__col align__center w__full gap__64">
          <div className="contract__container w__full md-flex__col">
            <ComponyDetail />
            <ClubDetail />
          </div>
          <ContractDetail />
          <TravelDates />
          <PricePerPerson />
          <ComponyWellcome />
          <ContractForTraning
            lead_contract_conditions={contract_condition_with_types}
          />
          <IncludeServices lead_services={lead_services} />
          <MilestoneDetail />
          <div className="contract__container w__full md-flex__col">
            <ComponySection />
            <ClientSection />
          </div>
          <div className="hotel__offer d__flex align__center justify__between gap__24 w__full pl__44 pt__44 pb__44 pr__44 radi__24 sm-flex__col md-pl__32 md-pt__28 md-pb__28 md-pr__32 sm-gap__44 sm-pl__24 sm-pt__24 sm-pb__28 sm-pr__24">
            <div className="d__flex flex__col gap__08">
              <h1 className="opacity__white__100 fs__32 fw__500 md-fs__28 sm-text__center">
                {t("lead_offer_contract.signTheContract")}
              </h1>
              <span className="opacity__white__80 fs__20 md-fs__18 sm-text__center">
                {t("lead_offer_contract.expiredAt")}
                {expiryDate.toLocaleDateString()}
              </span>
              <span className="opacity__white__80 fs__18 md-fs__16 sm-text__center">
                {remainingDays ? remainingDays : 0}{" "}
                {t("lead_offer_contract.remainingDays")}
              </span>
            </div>
            <div className="d__flex gap__24 md-gap__16 sm-flex__col sm-w__full sm-gap__12">
              {status === "success" ||
              status === "rejected" ||
              status === "deleted" ? (
                <span
                  style={{
                    textTransform: "capitalize",
                    background:
                      status === "rejected" ? "#D55050" : "green",
                  }}
                  className="md-fs__15  opacity__white__100 pl__32 pr__32 pb__12 pt__12 radi__24 b__1__white100 min__w__200 text__center sm-min__w__full"
                >
                  {status}
                </span>
              ) : currentDate > expiryDate ? (
                <span
                  style={{
                    textTransform: "capitalize",
                    background: "red",
                  }}
                  className="md-fs__15  opacity__white__100 pl__32 pr__32 pb__12 pt__12 radi__24 b__1__white100 min__w__200 text__center sm-min__w__full"
                >
                  Expired
                </span>
              ) : (
                <>
                  <button
                    onClick={handleOpenRejectCodeModal}
                    className="md-fs__15  opacity__white__100 pl__32 pr__32 pb__12 pt__12 radi__24 b__1__white100 min__w__200 text__center sm-min__w__full"
                  >
                    {isLoadingOtpCode && modalType == "reject"
                      ? "Loading..."
                      : t("lead_offer_contract.reject")}
                  </button>

                  <button
                    onClick={handleOpenSignCodeModal}
                    className="md-fs__15 back__white__100 solid__primary__100 pl__32 pr__32 pb__12 pt__12 radi__24 min__w__200 text__center sm-min__w__full"
                  >
                    {isLoadingOtpCode && modalType == "sign"
                      ? "Loading..."
                      : t("lead_offer_contract.sign")}
                  </button>
                </>
              )}
            </div>
          </div>
          {showCodeModal && (
            <CodeModal
              slug={slug}
              setShowCodeModal={setShowCodeModal}
              modalType={modalType}
              otpCodeData={otpCodeData}
            />
          )}
        </div>
      )}
    </>
  );
};
export default ContractPage;
