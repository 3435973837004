import React from 'react'

const ThumBlue = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M2.6665 16C2.6665 14.5272 3.86041 13.3333 5.33317 13.3333C7.54231 13.3333 9.33317 15.1242 9.33317 17.3333V22.6666C9.33317 24.8758 7.54231 26.6666 5.33317 26.6666C3.86041 26.6666 2.6665 25.4727 2.6665 24V16Z" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.638 9.74168L20.2829 10.8885C19.992 11.8281 19.8465 12.298 19.9584 12.6691C20.0489 12.9693 20.2476 13.228 20.5183 13.3983C20.853 13.6087 21.3593 13.6087 22.3718 13.6087H22.9105C26.3372 13.6087 28.0506 13.6087 28.8599 14.6231C28.9524 14.739 29.0346 14.8623 29.1058 14.9916C29.7284 16.1229 29.0206 17.6468 27.605 20.6948C26.3059 23.4918 25.6564 24.8904 24.4504 25.7135C24.3336 25.7932 24.2136 25.8684 24.0907 25.9389C22.8211 26.6667 21.2479 26.6667 18.1016 26.6667H17.4191C13.6073 26.6667 11.7014 26.6667 10.5172 25.5193C9.33301 24.3719 9.33301 22.5252 9.33301 18.8319V17.5338C9.33301 15.5928 9.33301 14.6224 9.67746 13.7341C10.0219 12.8459 10.6815 12.1155 12.0006 10.6548L17.4558 4.61408C17.5926 4.46258 17.6611 4.38682 17.7214 4.33433C18.2844 3.84437 19.1533 3.89952 19.6455 4.45646C19.6983 4.51613 19.7559 4.59988 19.8712 4.76738C20.0514 5.0294 20.1416 5.16041 20.2201 5.29021C20.9235 6.45217 21.1362 7.83249 20.8141 9.14286C20.7781 9.28923 20.7314 9.44013 20.638 9.74168Z" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default ThumBlue