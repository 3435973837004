import React, { useEffect, useRef, useState } from "react";
import {
  useGetMessagesQuery,
  useSendMessageMutation,
} from "../../redux/storeApis";
import LeadLayout from "../../components/leadLayout/LeadLayout ";
import { useSnackBarManager } from "../../hooks/useSnackBarManager";
import {
  AddIcon,
  CircleArrowDownIcon,
  DeleteIcon,
  FileIcon,
  SendIcon,
} from "../../assets/Icons/AllIcons";
import SingleMessage from "./SingleMessage";
import FileCard from "./FileCard"; 
import { useTranslation } from "react-i18next";

const MessagePage = () => {
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef(null);
  const [files, setFiles] = useState([]);
  const {t} = useTranslation();
  const { data: messages, isLoading: isLoadingMessages } =
    useGetMessagesQuery();
  const showMessages = messages?.data?.messages;
  const [sendMessage, { isLoading: isLoadingSendMessage }] =
    useSendMessageMutation();
  const { fnShowSnackBar } = useSnackBarManager();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({  });
  };

  useEffect(() => {
    scrollToBottom();
  }, [showMessages]);

  const handleSendMessage = async () => {
    try {
      const formData = new FormData();
      formData.append("message", newMessage);

      files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });

      // for (let pair of formData.entries()) {
      //   console.log(pair[0], pair[1], pair);
      // }

      const res = await sendMessage(formData).unwrap();
      if (res) {
        fnShowSnackBar(t("show_messages.message_sent_successfully"));
      }
    } catch (err) {
      fnShowSnackBar(t("show_messages.something_went_wrong"), true);
      console.log(err);
    } finally {
      setNewMessage("");
      setFiles([]); 
    }
  };

  const handleFileChange = (e) => {
    setFiles([...files, ...Array.from(e.target.files)]);
  };

  return (
    <LeadLayout>
      <div className="flex flex-col gap-[32px] w-full items-center flex-1">
        <h1 className="text-[32px] font-[500] text-[#333333]">
          <span className="text-[#4A6DA7]">{t("profile.messages")}</span>
        </h1>
        <div className="flex flex-col p-[16px] w-full rounded-[16px] border-[1px] border-[#F5F5F5] sm:h-[70dvh] h-[60dvh] relative">
          <div className="flex flex-col sm:pr-[10px] w-full h-full overflow-y-scroll mb-[24px] gap-[8px]">
            {showMessages?.map((data, index) => {
              const isShowDate = showMessages?.[index + 1]?.from !== data?.from;
              return (
                <SingleMessage
                  key={index}
                  data={data}
                  message={data?.message}
                  date={data?.created_at}
                  isShowDate={isShowDate}
                  from={data?.from}
                  attachedFiles={data?.attachments}
                  
                />
              );
            })}
            <div ref={messagesEndRef} />
          </div>
          <div
            className="absolute bg-[#F6F8FB] rounded-full p-[6px] shadow right-[22px] bottom-[86px] z-[2] cursor-pointer"
            onClick={scrollToBottom}
          >
            <CircleArrowDownIcon />
          </div>
          {files?.length > 0 && (
            <div className="flex items-center h-fit justify-start p-[20px] overflow-x-scroll gap-[20px] border-[1px] border-[#CCC] rounded-[8px] absolute left-[16px] bottom-[84px] right-[16px] bg-[#FFF]">
              {files?.map((file, index) => (
                <FileCard
                  key={index}
                  index={index}
                  data={file}
                  actionType="delete"
                  handleDeleteFile={(index) => {
                    setFiles(files.filter((_, i) => i !== index));
                  }}
                />
              ))}
            </div>
          )}
          <div className="flex items-center justify-between px-[6px] gap-[12px] py-[6px] border-[1px] border-[#CCC] rounded-[8px]">
            <button className="rounded-[8px] p-[8px] bg-[#4A6DA7] group relative cursor-pointer">
              <AddIcon
                iconColor="#FFF"
                className={
                  "transition-all group-hover:rotate-[90deg] group-hover:scale-90"
                }
              />
              <input
                type="file"
                multiple
                className="absolute inset-0 opacity-0 cursor-pointer"
                onChange={handleFileChange}
              />
            </button>
            <input
              type="text"
              placeholder="Message"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
              className="text-[14px] w-full outline-none text-[#333333] placeholder:text-[#999]"
            />
            <button
              onClick={handleSendMessage}
              className="relative min-w-[40px] h-[40px] overflow-hidden rounded-[8px] p-[8px] bg-[#4A6DA7] group"
            >
              <SendIcon
                iconColor="#FFF"
                className={
                  "duration-500 scale-75 absolute bottom-[-40px] left-[-40px] transition-all group-hover:bottom-[50%] group-hover:scale-100 group-hover:left-[50%] group-hover:-translate-x-[50%] group-hover:translate-y-[50%]"
                }
              />
              <SendIcon
                iconColor="#FFF"
                className={
                  "duration-500 absolute -translate-x-[50%] translate-y-[50%] bottom-[50%] left-[50%] transition-all scale-100 group-hover:scale-75 group-hover:bottom-[50%] group-hover:left-[40px] group-hover:translate-x-[0] group-hover:translate-y-[0px]"
                }
              />
            </button>
          </div>
        </div>
      </div>
    </LeadLayout>
  );
};

export default MessagePage;
