import React from "react";
import useLeadContract from "../../../hooks/useLeadContract";
function HTMLRenderer({ encodedDetail }) {
  const decodeAndConvertToHTML = (encodedString) => {
    const decodedString = document.createElement("textarea");
    decodedString.innerHTML = encodedString;
    const originalForm = decodedString.value;
    return `<p>${originalForm}</p>`;
  };
  const htmlString = decodeAndConvertToHTML(encodedDetail);
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}
function ComponyWellcome() {
  const selectedLanguage = localStorage.getItem("i18nextLng") || "en";
  const { lead_contract } = useLeadContract();
  return (
    <>
      <HTMLRenderer encodedDetail={lead_contract?.detail?.[selectedLanguage]} />
    </>
  );
}
export default ComponyWellcome;
