import DZText from '../../../text/DZText';
import './featureCard.css';

const FeatureCard = ({ featureIcon, featureText }) => {
  return (
    <div className="feature__card">
      {featureIcon}
      <DZText className={'poppins__18'}>{featureText}</DZText>
    </div>
  )
}

export default FeatureCard