import React from "react";

import "./HotelCards.css";
import HotelCard from "../../../components/shared/hotelCard/HotelCard";
import DZText from "../../text/DZText.js";

const HotelCards = ({ SectionHeading, cardsData }) => {
  return (
    <div className="hotel__cards section">
      <h1 className="section__heading">
        <DZText className={'section__heading'} >{SectionHeading}</DZText>
      </h1>
      <div className="cards__container flex">
        {cardsData?.map((hotel, index) => {
          return <HotelCard key={index} hotel={hotel} showPriceSection={false} />;
        })}
      </div>
    </div>
  );
};

export default HotelCards;
