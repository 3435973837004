import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs, Pagination } from "swiper/modules";
import "./ImageSlider.css";

const ImageSlider = ({ data, path }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div
        className="d__flex w__full gap__16 section"
        style={{ marginTop: "-40px" }}
      >
      
      <Swiper
        onSwiper={setThumbsSwiper}
        direction={"vertical"}
        watchSlidesProgress={true}
        className="max__h__500 min__w__240  max__w__240 sm-d__none yakslider__hotel lg-d__none"
        spaceBetween={16}
        slidesPerView={3}
        freeMode={true}
      >
        {data?.map((data, index) => (
          <SwiperSlide key={index} className="overflow__h radi__12 ">

            <img
              src={path + data?.image}
              alt={data?.image}
              className=" w__full obj__fit h__200"
            />
          </SwiperSlide>

        ))}
      </Swiper>
      <Swiper
        spaceBetween={10}
        navigation={true}
        modules={[FreeMode, Navigation, Thumbs, Pagination]}
        className="h__full sm-max__h__400  radi__12 overflow__h  yakslider__hotel"
        style={{ height: "500px" }}
        thumbs={{ swiper: thumbsSwiper }}
      >
        {data?.map((data, index) => (
          <SwiperSlide key={index} className="h__full">
            <img
              src={path + data?.image}
              alt={data?.image + "sdf"}
              className="radi__12 md-h__full w__full sm-w__auto sm-max__w__auto"
              style={{ width: '100%'}}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ImageSlider;
