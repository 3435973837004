import React from 'react'

const BedBlue = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 26" fill="none">
            <path d="M28.3327 20.3334H1.66602" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M28.3327 25V18.3333C28.3327 15.8192 28.3327 14.5621 27.5516 13.781C26.7706 13 25.5135 13 22.9993 13H6.99935C4.48519 13 3.22811 13 2.44706 13.781C1.66602 14.5621 1.66602 15.8192 1.66602 18.3333V25" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.3327 13V11.1571C20.3327 10.4804 20.2106 10.2541 19.5855 9.98734C18.2835 9.43185 16.7031 9 14.9993 9C13.2956 9 11.7152 9.43185 10.4132 9.98734C9.78806 10.2541 9.66602 10.4804 9.66602 11.1571L9.66602 13" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" />
            <path d="M25.6654 13V6.81409C25.6654 5.8919 25.6654 5.43081 25.4376 4.99537C25.2099 4.55993 24.8855 4.33454 24.2365 3.88377C21.6202 2.06637 18.4349 1 14.9987 1C11.5625 1 8.37724 2.06637 5.76087 3.88377C5.11193 4.33454 4.78746 4.55993 4.55975 4.99537C4.33203 5.43081 4.33203 5.8919 4.33203 6.81409V13" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" />
        </svg>
    )
}

export default BedBlue