import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import 'swiper/css/navigation';
import useReferencerPageData from "../../hooks/useReferencerPageData";
import DZText from "../../components/text/DZText";
import DZRenderHtml from "../../components/renderHtml/DZRenderHtml";
import { getLocalStorage } from "../../components/shared/local_storage";
import { Config, KEYS } from "../../constant/Index";
import { Navigation } from 'swiper/modules';
function Referencer() {
  const { referencerData } = useReferencerPageData();
  console.log(referencerData);
  const selectedLanguage = getLocalStorage();


  return (
    <div className="flex flex-col gap-[48px] w-full px-[20px]">

      <h1 className="text-center fs__32 text-[var(--solid-primary-100)] fw__600">
        See what our client says
      </h1>

      <Swiper
        loop={true}
        spaceBetween={10}
        slidesPerView={1}
        freeMode={true}
        className="w__full max__w__1100 gallery__hotel referncer__slider"
        navigation={true} modules={[Navigation]}
      >
        {referencerData?.map((slide, index) => {
          console.log('object', slide)

          return (
            <SwiperSlide key={index} className='!flex flex-col gap-[10px] items-center cursor-grab w-full'>
              <div className="flex flex-col gap-[24px]">
                <DZText className={'text-center fs__20 fw__600 text-[var(--solid-black-80)]'}>{slide?.title}</DZText>
                <DZRenderHtml className={'text-center fs__16 font-[400] text-[var(--solid-black-60)]'} encodedDetail={slide?.description?.en} />
              </div>
              {/* <span className="text-center font-[500] text-[var(--solid-primary-100)]">
              {slide?.name}
              </span> */}
              <img src={ Config?.hotelReferenceImage + slide?.image }  className="w-[50px] rounded-full" alt="" />
            </SwiperSlide>
          )
        })}
      </Swiper>
      {/* <DZText className={'text-center fs__20 fw__600 text-[var(--solid-black-80)]'}>Great stay in Antalya {slide?.title}</DZText> */}
      {/* <DZRenderHtml className={'text-center text-[var(--solid-black-80)]'} encodedDetail={slide?.description?.en} /> */}
      {/* <DZText className={'text-center text-[var(--solid-black-80)]'}>{slide?.description}</DZText> */}
    </div>
  );
}
export default Referencer;