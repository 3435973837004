import React from "react";
import CrossIcon from "../../../assets/Icons/crossIcon.png";
import UserIcon from "../../../assets/Icons/user__blue";
import MailIcon from "../../../assets/Icons/mail__blue";
import PhoneIcon from "../../../assets/Icons/phone__blue";
import BackIcon from "../../../assets/Icons/backIcon.png";
import Input from "../input/Input";
import { useTranslation } from "react-i18next";
function SecondSection({
  inquiryModel,
  setInquiryModel,
  handleSecondNext,
  handleClose,
  inquiryFirstData,
  setInquiryFirstData,
  isLoading,
  errors,
}) {
  const { t } = useTranslation();
  const onChange = (e) => {
    setInquiryFirstData({
      ...inquiryFirstData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <>
      {
        <>
          <div
            className={` inquiry__model ${
              inquiryModel?.second && "inquiry__model__open"
            } `}
          >
            <img
              src={CrossIcon}
              className="cursor__p position__abs r__20 t__20"
              onClick={handleClose}
              alt=""
            />
            <img
              src={BackIcon}
              className="cursor__p position__abs l__20 t__20"
              onClick={() => setInquiryModel({ first: true, second: false })}
              alt=""
            />
            <div className="d__flex flex__col gap__12 align__center w__full">
              <h2 className="model__heading">{t("inquiry_model.heading")}</h2>
              <p className="poppins__16">{t("inquiry_model.detail")}</p>
            </div>
            <div className="d__grid grid__tcol__2 gap__18 align__center w__full sm-d__flex sm-flex__col">
              <Input
                icon={<UserIcon className="w__24 h__24"  />}
                placeholder={t("inquiry_model.first_name")}
                type={"text"}
                onChange={onChange}
                name={"firstName"}
                error={errors?.firstName}
              />
              <Input
                icon={<UserIcon className="w__24 h__24" />}
                placeholder={t("inquiry_model.last_name")}
                type={"text"}
                onChange={onChange}
                name={"lastName"}
                error={errors?.lastName}
              />
              <Input
                icon={<MailIcon className="w__24 h__24"  />}
                placeholder={t("inquiry_model.email")}
                type={"text"}
                onChange={onChange}
                name={"email"}
                error={errors?.email}
                style={{ gridColumn: "span 2" }}
              />
              <Input
                icon={<PhoneIcon className="w__24 h__24"  />}
                style={{ gridColumn: "span 2" }}
                placeholder={t("inquiry_model.phone")}
                type={"number"}
                onChange={onChange}
                name={"phone"}
                error={errors?.phone}
              />
            </div>
            <div className="d__flex gap__16 align__center w__full h__4">
              <div className="d__flex gap__16 align__center w__full h__full back__primary__100 radi__10"></div>
              <div className="d__flex gap__16 align__center w__full h__full back__primary__100 radi__10"></div>
            </div>
            <div
              onClick={handleSecondNext}
              className="d__flex flex__col gap__16 align__center w__full"
            >
              <span className="cursor__p w__full md-fs__15 back__primary__100 opacity__white__100 pl__32 pr__32 pb__12 pt__12 radi__24 min__w__200 text__center sm-min__w__full">
                {isLoading? "Loading...":t("inquiry_model.submit")}
              </span>
            </div>
          </div>
        </>
      }
    </>
  );
}
export default SecondSection;