import React from "react";
import ClubBlue from "../../../assets/Icons/club__blue";
import UserBlue from "../../../assets/Icons/user__blue";
import PhoneBlue from "../../../assets/Icons/phone__blue";
import SignBlue from "../../../assets/Icons/sign__blue";
import useLeadContract from "../../../hooks/useLeadContract";
import { useTranslation } from "react-i18next";
function ClubDetail() {
  const { t } = useTranslation();
  const { userDetail, clubName } = useLeadContract();
  return (
    <div className="inner__container">
      <div className="container__li">
        <ClubBlue />
        <div className="d__flex flex__col w__full">
          <span className="li__bold row__text overflow__h w__full">
            {clubName}
          </span>
          <span className="poppins__18">
            {t("lead_offer_contract.club_name")}
          </span>
        </div>
      </div>
      <div className="container__li">
        <UserBlue />
        <div className="d__flex flex__col w__full">
          <span
            className="li__bold row__text overflow__h w__full"
            style={{ textOverflow: "ellipsis" }}
          >
            {userDetail?.first_name} {userDetail?.last_name}
          </span>
          <span className="poppins__18 row__text overflow__h w__full">
            {t("lead_offer_contract.name")}
          </span>
        </div>
      </div>
      <div className="container__li">
        <PhoneBlue className={""} />
        <div className="d__flex flex__col w__full">
          <span className="li__bold row__text overflow__h w__full">
            {userDetail?.mobile}
          </span>
          <span className="poppins__18">{t("lead_offer_contract.mobile")}</span>
        </div>
      </div>
      <div className="container__li">
        <SignBlue />
        <div className="d__flex flex__col w__full">
          <span className="li__bold row__text overflow__h w__full">
            {t("lead_offer_contract.after_this")}
          </span>
          <span className="poppins__18">
            {t("lead_offer_contract.recipient")}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ClubDetail;
