import React from 'react'

const clock__blue = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <circle cx="14.9998" cy="15" r="13.3333" stroke="#4A6DA7" stroke-width="2" />
            <path d="M16 8.33337C16 7.78109 15.5523 7.33337 15 7.33337C14.4477 7.33337 14 7.78109 14 8.33337H16ZM17.7071 16.2929C17.3166 15.9024 16.6834 15.9024 16.2929 16.2929C15.9024 16.6835 15.9024 17.3166 16.2929 17.7071L17.7071 16.2929ZM18.2929 19.7071C18.6834 20.0977 19.3166 20.0977 19.7071 19.7071C20.0976 19.3166 20.0976 18.6835 19.7071 18.2929L18.2929 19.7071ZM14 8.33337V13H16V8.33337H14ZM16.2929 17.7071L18.2929 19.7071L19.7071 18.2929L17.7071 16.2929L16.2929 17.7071ZM16 15C16 15.5523 15.5523 16 15 16V18C16.6569 18 18 16.6569 18 15H16ZM15 16C14.4477 16 14 15.5523 14 15H12C12 16.6569 13.3431 18 15 18V16ZM14 15C14 14.4478 14.4477 14 15 14V12C13.3431 12 12 13.3432 12 15H14ZM15 14C15.5523 14 16 14.4478 16 15H18C18 13.3432 16.6569 12 15 12V14Z" fill="#4A6DA7" />
        </svg>
    )
}

export default clock__blue