import React, { useEffect, useRef, useState } from "react";
import CrossIcon from "../../../assets/Icons/crossIcon.png";
import CalenderBlack from "../../../assets/Icons/calender__black";
import DashboardBlue from "../../../assets/Icons/dashboard__blue";
import FlightBlue from "../../../assets/Icons/flight__blue";
import BudgetIcon from "../../../assets/Icons/BudgetIcon";
import ClubIcon from "../../../assets/Icons/club__blue";
import UserMultiple from "../../../assets/Icons/user__multiple";
import LocationOutline from "../../../assets/Icons/location__blue";
import Input from "../input/Input";
import DateCalender from "../../cHome/hero/SearchFilter/Components/DateCalender/DateCalender";
import SportIcon from "../SportIcon/SportIcon";
import CategoryPopup from "../../cHome/hero/SearchFilter/Components/CategoryPopup/CategoryPopup";
import LocationPopup from "../../cHome/hero/SearchFilter/Components/LocationPopup/LocationPopup";
import { useSnackBarManager } from "../../../hooks/useSnackBarManager";
import { useTranslation } from "react-i18next";
import FlightPopup from "../../cHome/hero/SearchFilter/Components/FlightPopup/FlightPopup";
import useSearchFilter from "../../../hooks/searchFilter/useSearchFilter";
import useHomePageDataManager from "../../../hooks/useHomePageDataManager";
import { getLocalStorage } from "../local_storage";
import { Config, KEYS } from "../../../constant/Index";

function FirstSection({
  inquiryModel,
  setInquiryModel,
  handleClose,
  inquiryFirstData,
  setInquiryFirstData,
  errors,
  handleFirstNext,
}) {

  const datePopupRef = useRef(null);
  const categoryPopupRef = useRef(null);
  const locationPopupRef = useRef(null);
  const flightPopupRef = useRef(null);
  const { fnShowSnackBar } = useSnackBarManager();
  const { t } = useTranslation();
  const { setAirportData, airportData } = useSearchFilter();
  const { airportsByCountryIds, airportsByCityIds, countries } =
    useHomePageDataManager();
  const selectedLanguage = getLocalStorage(KEYS.lang);
  useEffect(() => {
    setAirportData(
      inquiryFirstData?.countryId
        ? airportsByCountryIds[inquiryFirstData?.countryId] ?? []
        : airportsByCityIds[inquiryFirstData?.cityId] ?? []
    );
  }, [airportsByCountryIds, airportsByCityIds, inquiryFirstData]);
  const [showPopup, setShowPopup] = useState("");
  const handleChange = (e) => {
    // if (e.target.name === "guests") {
    //   const inputValue = parseInt(e.target.value);
    //   const newValue = inputValue < 8 ? 8 : inputValue;
    //   setInquiryFirstData((prevInquiryFirstData) => ({
    //     ...prevInquiryFirstData,
    //     [e.target.name]: newValue,
    //   }));
    // } else {
    setInquiryFirstData((prevInquiryFirstData) => ({
      ...prevInquiryFirstData,
      [e.target.name]: e.target.value,
    }));
    // }
  };
  const handleChangeGuestValue = (e) => {
    if (inquiryFirstData?.guests < 8) {
      setInquiryFirstData((prevInquiryFirstData) => ({
        ...prevInquiryFirstData,
        "guests": 8,
      }));
    }
  };
  const handleTogglePopup = (type) => {
    if (type == showPopup) {
      setShowPopup("");
    } else {
      setShowPopup(type);
    }
  };
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        categoryPopupRef.current &&
        !categoryPopupRef.current.contains(e.target) &&
        !locationPopupRef.current.contains(e.target) &&
        !flightPopupRef.current.contains(e.target) &&
        !datePopupRef.current.contains(e.target)
      ) {
        setShowPopup("");
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showPopup]);
  return (
    <>
      {
        <div
          className={`inquiry__model ${
            inquiryModel?.first && "inquiry__model__open"
          }`}
        >
          <img
            src={CrossIcon}
            className="cursor__p position__abs r__20 t__20"
            onClick={handleClose}
            alt=""
          />
          <div className="d__flex flex__col gap__12 align__center w__full">
            <h2 className="model__heading">{t("inquiry_model.heading")}</h2>
            <p className="poppins__16">{t("inquiry_model.detail")}</p>
          </div>
          <div className="d__grid grid__tcol__2 input__group gap__18 align__center w__full sm-d__flex sm-flex__col">
            <Input
              placeholder={t("inquiry_model.club_name")}
              type={"text"}
              icon={<ClubIcon className={"w__24 h__24"} />}
              style={{ gridColumn: "span 2" }}
              name={"clubName"}
              value={inquiryFirstData.clubName}
              onChange={handleChange}
              error={errors?.clubName}
            />
            <div
              className="d__flex flex__col align__center w__full position__rel"
              ref={datePopupRef}
            >
              <Input
                placeholder={t("inquiry_model.select_date")}
                type={"text"}
                icon={<CalenderBlack className="w__24 h__24" />}
                name={"date"}
                value={`${
                  inquiryFirstData?.from_date
                    ? `${Config.fnMultilingualDate(inquiryFirstData?.from_date)} - `
                    : ""
                }${inquiryFirstData?.to_date ? Config.fnMultilingualDate(inquiryFirstData?.to_date) : ""}`}
                onClick={() => handleTogglePopup("date")}
                onChange={handleChange}
                error={errors?.from_date}
              />
              <DateCalender
                closePopup={() => handleTogglePopup("")}
                inputFiled={showPopup}
                date={inquiryFirstData?.date}
                setInquiryFirstData={setInquiryFirstData}
              />
            </div>
            <div
              className="d__flex flex__col align__center w__full position__rel"
              ref={categoryPopupRef}
            >
              <Input
                placeholder={t("inquiry_model.select_sport")}
                type={"text"}
                icon={
                  <SportIcon
                    sport={inquiryFirstData?.sport?.en || "Football"}
                    className="w__24 h__24"
                  />
                }
                error={errors?.sport}
                name={"sport"}
                value={inquiryFirstData?.sport?.[selectedLanguage]}
                onClick={() => handleTogglePopup("category")}
                onChange={handleChange}
              />
              <CategoryPopup
                closePopup={() => handleTogglePopup("")}
                inputFiled={showPopup}
                searchFields={inquiryFirstData}
                setInquiryFirstData={setInquiryFirstData}
              />
            </div>
            <Input
              placeholder={t("inquiry_model.guests")}
              type={"number"}
              icon={<UserMultiple className="w__24 h__24" />}
              name={"guests"}
              error={errors?.guests}
              value={inquiryFirstData.guests}
              onChange={handleChange}
              onBlur={handleChangeGuestValue}
            />
            <div
              className="d__flex flex__col align__center w__full position__rel"
              ref={locationPopupRef}
            >
              <Input
                placeholder={t("inquiry_model.selectPlace")}
                type={"text"}
                icon={<LocationOutline className="w__24 h__24" />}
                error={errors?.country}
                name={"country"}
                value={
                  typeof inquiryFirstData.country === "string"
                    ? inquiryFirstData.country ||
                      (typeof inquiryFirstData.city === "string"
                        ? inquiryFirstData.city
                        : "")
                    : typeof inquiryFirstData.city === "string"
                    ? inquiryFirstData.city
                    : ""
                }
                onClick={() => handleTogglePopup("location")}
                onChange={handleChange}
              />
              <LocationPopup
                inputFiled={showPopup}
                setInquiryFirstData={setInquiryFirstData}
                handleClosePopup={() => handleTogglePopup("")}
              />
            </div>
            <div
              className="d__flex flex__col align__center w__full position__rel"
              ref={flightPopupRef}
            >
              <Input
                placeholder={t("inquiry_model.airport")}
                type={"text"}
                icon={<FlightBlue className="w__24 h__24" />}
                error={errors?.flight}
                name={"airport"}
                value={inquiryFirstData?.flight}
                onClick={() => handleTogglePopup("flight")}
                onChange={handleChange}
              />
              <FlightPopup
                inputFiled={showPopup}
                setInquiryFirstData={setInquiryFirstData}
                handleClosePopup={() => handleTogglePopup("")}
                airportData={airportData}
              />
            </div>
            <Input
              placeholder={t("inquiry_model.budget")}
              type={"number"}
              icon={<BudgetIcon className="w__24 h__24" />}
              name={"budget"}
              value={inquiryFirstData.budget}
              onChange={handleChange}
              error={errors?.budget}
            />
            <div
              className="input__field textarea__field"
              style={{ gridColumn: "span 2" }}
            >
              <BudgetIcon className="w__24 h__24" />
              <textarea
                name="description"
                value={inquiryFirstData.description}
                onChange={handleChange}
                placeholder={t("inquiry_model.description")}
              />
            </div>
          </div>
          <div className="d__flex gap__16 align__center w__full h__4">
            <div className="d__flex gap__16 align__center w__full h__full back__primary__100 radi__10"></div>
            <div className="d__flex gap__16 align__center w__full h__full back__primary__20 radi__10"></div>
          </div>
          <div
            onClick={handleFirstNext}
            className="d__flex flex__col gap__16 align__center w__full"
          >
            <span className="cursor__p w__full md-fs__15 back__primary__100 opacity__white__100 pl__32 pr__32 pb__12 pt__12 radi__24 min__w__200 text__center sm-min__w__full">
              {t("inquiry_model.next")}
            </span>
          </div>
        </div>
      }
    </>
  );
}

export default FirstSection;
