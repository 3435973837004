"use client";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import Image from "../../shared/image/Image";
import "./FullScreenGallery.css";
import { Config } from "../../../constant/Index";
import useHomePageDataManager from "../../../hooks/useHomePageDataManager";
export default function FullScreenGallery({
  handleCloseGallery,
  selectedImageIndex = 2,
}) {
  const { galleryImages } = useHomePageDataManager();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  useEffect(() => {
    return () => {
      // if (thumbsSwiper.destroyed()) {
      if (thumbsSwiper) {
        thumbsSwiper.destroyed();
      }
    };
  }, []);
  return (
    <>
      <div className="w__full h__full d__flex flex__col gap__12 position__fixed t__1 align__center justify__center z__in__9">
        <Swiper
          loop={true}
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="w__full h__600 max__w__1100 gallery__hotel"
          style={{ boxShadow: "0 4px 10px 2000px rgba(0, 0, 0, .95)" }}
          initialSlide={selectedImageIndex}
        >
          {galleryImages?.map((image) => {
            const src = image?.image;
            return (
              <SwiperSlide>
                <Image
                  width={10000}
                  height={10000}
                  className="w__full h__full obj__fit"
                  src={`${Config.galleryImage}${src}`}
                  alt="#"
                  style={{
                    objectFit: "cover",
                  }}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={10}
          slidesPerView={12}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className=" w__full h__100 max__w__full gallery__hotel"
          style={{
            position: "absolute",
            bottom: "10px",
          }}
          initialSlide={selectedImageIndex}
        >
          {galleryImages?.map((image) => {
            const src = image?.image;
            return (
              <SwiperSlide>
                <Image
                  width={10000}
                  height={10000}
                  className="w__full h__full obj__fit"
                  src={`${Config.galleryImage}${src}`}
                  alt="#"
                  style={{
                    objectFit: "cover",
                  }}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="position__abs z__in__1 r__1 t__1 d__flex align__center justify__between w__full p__12">
          <span className="opacity__white__40">10/16</span>
          <div className="d__flex align__center gap__12">
            <i
              onClick={handleCloseGallery}
              className="ri-close-line opacity__white__100 fs__24 cursor__p"
            ></i>
          </div>
        </div>
      </div>
    </>
  );
}
