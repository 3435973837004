import React, { useState } from "react";
import DZText from "../../text/DZText";
import CrossIcon from "../../../assets/Icons/crossIcon.png";
import MailIcon from "../../../assets/Icons/mail__outline";
import Input from "../input/Input";
import { useForgotPasswordMutation } from "../../../redux/storeApis";
import { useSnackBarManager } from "../../../hooks/useSnackBarManager";
import { Config } from "../../../constant/Index";

const ForgotPasswordModal = ({ setForgotModal }) => {

  const [forgotPassword, { isLoading: isLoadingForgotPassword }] =useForgotPasswordMutation();
  const { fnShowSnackBar } = useSnackBarManager();

  const allModalTypes = {email: "email", otp: "otp", new_password: "new_password", };

  const [loading, setLoading] = useState(false);
  const [modalType, setModalType] = useState(allModalTypes.email);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");

  const fnForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const result = await forgotPassword(email);
      if (result?.data?.success) {setModalType(allModalTypes.otp);
      } else {
        fnShowSnackBar(
          result?.error?.data?.message ??
            "Something went wrong. Please try again later."
        );
      }
      if (result?.error) {
        fnShowSnackBar(result?.error?.data?.message ??"Something went wrong. Please try again later.");
      } else {
        setModalType(allModalTypes.otp);
      }
    } catch (error) {
      fnShowSnackBar("Something went wrong. Please try again later.");
    }
  };

  const fnSendOtp = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${Config.serverApiUrl}verify-email-forgot-password`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, verification_code: otp }),
        }
      );

      if (response.ok) {
        setModalType(allModalTypes.new_password);
      } else {
        fnShowSnackBar("Verification failed. Please check your verification code and try again.");
        setModalType(allModalTypes.otp);
      }
    } catch (error) {
      fnShowSnackBar("Something went wrong. Please try again later.");
    }
    setLoading(false);
  };

  const fnNewPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      fnShowSnackBar("Passwords do not match.");
      return;
    }
   
    setLoading(true);
    try {
      const response = await fetch(`${Config.serverApiUrl}new-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email,
          verification_code: otp,
          password: newPassword,
        }),
      });

      if (response.ok) {
        fnShowSnackBar("Password updated successfully.");
        setForgotModal(false);
      } else {
        fnShowSnackBar("Failed to update password. Please try again.");
      }
    } catch (error) {
      fnShowSnackBar("Something went wrong. Please try again later.");
    }
    setLoading(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      modalType === allModalTypes.email
        ? fnForgotPassword(e)
        : modalType === allModalTypes.otp
        ? fnSendOtp()
        : fnNewPassword(e);
    } else if (e.key === "Escape") {
      if (
        modalType === allModalTypes.new_password ||
        modalType === allModalTypes.otp
      ) {
        setModalType(allModalTypes.email);
      } else if (modalType === allModalTypes.email) {
        setForgotModal(false);
      }
    }
  };

  return (
    <>
      <span className={`model__back`}></span>

      <div className={`login__model`}>
        <img
          src={CrossIcon}
          className="cursor__p position__abs r__20 t__20"
          alt=""
          onClick={() => setForgotModal(false)}
        />
        <div className="d__flex flex__col gap__12 align__center w__full">
          <h2 className="model__heading">Forget Password?</h2>
          <p className="poppins__16">Enter your email to send OTP</p>
        </div>
        {modalType === allModalTypes.email && (
          <Input
            icon={<MailIcon className="w__24 h__24" />}
            placeholder="Enter your email address"
            type="text"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            required={true}
          />
        )}
        {modalType === allModalTypes.otp && (
          <Input
            placeholder="Enter your otp"
            type="text"
            name="otp"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            onKeyDown={handleKeyDown}
            required={true}
          />
        )}
        {modalType === allModalTypes.new_password && (
          <>
            <Input
              placeholder="Enter your new password"
              type="password"
              name="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              onKeyDown={handleKeyDown}
              required={true}
            />
            <Input
              placeholder="Confirm your new password"
              type="password"
              name="confirm-new-password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              onKeyDown={handleKeyDown}
              required={true}
            />
          </>
        )}
        <DZText
          onClick={(e) => {
            modalType === allModalTypes.email
              ? fnForgotPassword(e)
              : modalType === allModalTypes.otp
              ? fnSendOtp()
              : fnNewPassword(e);
          }}
          className={
            "cursor__p w__full md-fs__15 back__primary__100 opacity__white__100 pl__32 pr__32 pb__12 pt__12 radi__24 min__w__200 text__center sm-min__w__full"
          }
        >
          {loading || isLoadingForgotPassword ? "Loading..." : "Submit"}
        </DZText>
      </div>
    </>
  );
};

export default ForgotPasswordModal;
