import React from 'react'

const HotelBlue = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="29" viewBox="0 0 30 29" fill="none">
  <path d="M3 7.83331V22.5C3 25.0141 3 26.2712 3.78105 27.0523C4.5621 27.8333 5.81918 27.8333 8.33333 27.8333H21.6667C24.1808 27.8333 25.4379 27.8333 26.219 27.0523C27 26.2712 27 25.0141 27 22.5V7.83331" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M21.6654 7.83332C21.6654 4.15142 18.6806 1.16666 14.9987 1.16666C11.3168 1.16666 8.33203 4.15142 8.33203 7.83332" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M17.6654 27.8334L17.6654 22.5C17.6654 21.0272 16.4715 19.8333 14.9987 19.8333C13.5259 19.8333 12.332 21.0272 12.332 22.5V27.8334" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.9993 2.5H4.9622C4.54821 2.5 4.12913 2.60945 3.79682 2.93864C2.8076 3.91859 2.23681 5.55016 1.66602 7.83333H8.33268M18.9993 2.5H25.0365C25.4505 2.5 25.8696 2.60945 26.2019 2.93864C27.1911 3.91859 27.7619 5.55016 28.3327 7.83333H21.666" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7 13.1667H7.66667M7 17.8333H7.66667" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M22.332 13.1667H22.9987M22.332 17.8333H22.9987" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13 9.16666V11.1667M13 13.1667V11.1667M17 9.16666V11.1667M17 13.1667V11.1667M13 11.1667H17" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default HotelBlue 