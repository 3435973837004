import React, { useEffect, useState } from "react";
import "./input.css";
import LockBlack from "../../../assets/Icons/lock__black";
import MailIcon from "../../../assets/Icons/mail__outline";
import { useTranslation } from "react-i18next";
function Input({
  value,
  type,
  onChange,
  name,
  placeholder,
  className,
  style,
  icon,
  onClick,
  error,
  onBlur,
  onKeyDown,
  disabled
}) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <span 
    onClick={onClick} 
    className="main__input__field__div   " 
    style={style}
    >
      <div
        className={` 
        ${
          error ? "error__input__field" : ""
        } input__field`}
      >
        <span className="cursor__p">{icon}</span>
        <input
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={className}
          style={style}
          autoComplete="off"
          onBlur={onBlur}
          disabled={disabled}
          onKeyDown={onKeyDown}
        />
        {type === "password" && (
          <span className="w__24 h__24" onClick={togglePasswordVisibility}>
            {showPassword ? (
              <i className="ri-eye-off-line fs__20 fs__20"></i>
            ) : (
              <i className="ri-eye-line fs__20 fs__20"></i>
            )}
          </span>
        )}
      </div>
      {error && (
        <div style={{position: "absolute"}} className="error__of__input__field">
          <i className="ri-error-warning-line"></i>
          {error || t("input_filed.filed_required")}
        </div>
      )}
    </span>
  );
}
export default Input;