import React, { useState } from "react";
import LeadLayout from "../../components/leadLayout/LeadLayout ";
import LeadOfferCard from "./LeadsPageCard";
import { useGetUserLeadsQuery } from "../../redux/storeApis";
import Loader from "../../assets/Loader";
import ViewLeadModal from "../../components/shared/viewLeadModal/ViewLeadModal";
import { useTranslation } from "react-i18next";

const LeadsPage = () => {
  const { data: userLeads, isLoading : isLoadingUserLeads } = useGetUserLeadsQuery();
  const {t} = useTranslation()
  const userLeadsData = userLeads?.data?.user_leads;
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  return (
    <>
    <LeadLayout>
      <div className="flex flex-col gap-[32px] w-full min-h-[80vh]">
        <h1 className="text-center text-[32px] text-[#333333] font-[500]">
          <span className="text-[#4A6DA7]">{t("profile.my_leads")}</span>
        </h1>
        {
          isLoadingUserLeads ? <Loader /> 
          :  <div className="grid grid-cols-3 max-[1024px]:grid-cols-2 max-sm:grid-cols-1 w-full gap-[16px]" >
            {userLeadsData?.map((data) => (
              <LeadOfferCard
                key={data?.id}
                heading={data?.club_name}
                subHeading={data?.hotel?.name}
                type={data?.lead_type} 
                status={data?.status}
                createdDate={data?.created_at}
                user={`${data?.lead_user?.first_name} ${data?.lead_user?.last_name}`}
                data={data}
                onClick={() => {
                  setShowModal(true);
                  setSelectedData(data)
                }}
                buttonText={t("profile.view_lead")}
            />
          ))}
          </div>
        }
      </div>
      
    </LeadLayout>
    {showModal && <ViewLeadModal onClose={()=>setShowModal(false)} data={selectedData} />}
    </>
  );
};
export default LeadsPage;
