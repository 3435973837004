import React, { useEffect, useState } from "react";
import "./InquiryModel.css";
import FirstSection from "./FirstSection.js";
import SecondSection from "./SecondSection.js";
import { useAddLeadMutation } from "../../../redux/storeApis.js";
import { useSnackBarManager } from "../../../hooks/useSnackBarManager.js";
import { useTranslation } from "react-i18next";
import useLoaderShow from "../../../hooks/useLoaderShow.js";
import useValidationFunction from "../../../hooks/useValidationFunction.js";
const InquiryModel = ({ inquiryModel, setInquiryModel, from_date, to_date, date, guests, country,  countryId,  city,
  cityId,  hotelId,  sport,  sportId,  lead_type = "free_lead",  }) => {

  const { validateData, isEmpty, getFirstErrorMessage } =useValidationFunction();
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  const [addLeadMutation, { isLoading }] = useAddLeadMutation();
  const { fnShowSnackBar } = useSnackBarManager();

  console.log('guests check', guests);

  const [inquiryFirstData, setInquiryFirstData] = useState({
    clubName: "",
    date: date || "",
    from_date: from_date || "",
    to_date: to_date || "",
    sport: sport || undefined,
    sportId: sportId || undefined,
    guests: guests ?? 8,
    country: country || "",
    countryId: countryId || "",
    city: city || "",
    city_id: cityId || "",
    hotel_id: hotelId || "",
    flight: "",
    flightId: "",
    budget: "",
    description: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    remember: false,
  });
  const handleClose = () => {
    setInquiryModel({ first: false, second: false });
    setInquiryFirstData({
      clubName: "",
      date: date || "",
      from_date: from_date || "",
      to_date: to_date || "",
      sport: sport || undefined,
      sportId: sportId || undefined,
      guests: guests || 8,
      country: country || "",
      countryId: countryId || "",
      city: city || "",
      city_id: cityId || "",
      flight: "",
      hotel_id: "",
      flightId: "",
      budget: "",
      description: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
      remember: false,
    });
  };
  const fValidateStateDate = {
    clubName: inquiryFirstData?.clubName,
    from_date: inquiryFirstData?.from_date,
    sport: inquiryFirstData?.sport,
    guests: inquiryFirstData?.guests,
    country: inquiryFirstData?.country,
    budget: inquiryFirstData?.budget,
    flight: inquiryFirstData?.flight,
  };
  const sValidateStateDate = {
    firstName: inquiryFirstData?.firstName,
    lastName: inquiryFirstData?.lastName,
    email: inquiryFirstData?.email,
    phone: inquiryFirstData?.phone,
  };

  useEffect(()=>{
    setInquiryFirstData({...inquiryFirstData, guests, from_date, to_date, date })
  },[guests, from_date, to_date, date]);

  const handleSecondNext = () => {
    const newErrors = validateData(sValidateStateDate);
    setErrors(newErrors);
    if (!isEmpty(newErrors)) {
      fnShowSnackBar(getFirstErrorMessage(newErrors), true);
      return;
    }
    const data = {
      first_name: inquiryFirstData?.firstName,
      last_name: inquiryFirstData?.lastName,
      email: inquiryFirstData?.email,
      mobile: inquiryFirstData?.phone,
      sport: inquiryFirstData?.sportId,
      country: inquiryFirstData?.countryId,
      city_id: cityId,
      hotel_id: hotelId,
      airport_id: inquiryFirstData?.flightId,
      club_name: inquiryFirstData?.clubName,
      no_guests: inquiryFirstData?.guests,
      budget: inquiryFirstData?.budget,
      from_date: inquiryFirstData?.date?.[0]?.format("YYYY-MM-DD"),
      to_date: inquiryFirstData?.date?.[1]?.format("YYYY-MM-DD"),
      description: inquiryFirstData?.description,
      lead_type: lead_type,
      country_code: "+45",
    };
    addLeadMutation(data)
      .then((response) => {
        console.log(response);
        if (response?.data?.success) {
          setInquiryModel({ first: false, second: false });
          fnShowSnackBar(t(response?.data?.message));
        } else {
          fnShowSnackBar(response?.error?.data?.message|| t("inquiry_model.api_error"), true);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        fnShowSnackBar(t("inquiry_model.api_error"), true);
      })
  };
  const handleFirstNext = () => {
    const newErrors = validateData(fValidateStateDate);
    setErrors(newErrors);
    if (!isEmpty(newErrors)) {
      fnShowSnackBar(getFirstErrorMessage(newErrors), true);
      return;
    }
    setInquiryModel({ first: false, second: true });
  };
  return (
    <>
      {(inquiryModel.first || inquiryModel.second) && (
        <span className={` model__back `}></span>
      )}
      <span
        style={{
          display: `${
            inquiryModel.first || inquiryModel.second ? "contents" : "none"
          }`,
        }}
      >
        <FirstSection
          inquiryModel={inquiryModel}
          setInquiryFirstData={setInquiryFirstData}
          inquiryFirstData={inquiryFirstData}
          handleClose={handleClose}
          setInquiryModel={setInquiryModel}
          errors={errors}
          handleFirstNext={handleFirstNext}
        />
        <SecondSection
          isLoading={isLoading}
          inquiryModel={inquiryModel}
          setInquiryModel={setInquiryModel}
          handleSecondNext={handleSecondNext}
          handleClose={handleClose}
          inquiryFirstData={inquiryFirstData}
          setInquiryFirstData={setInquiryFirstData}
          errors={errors}
        />
      </span>
    </>
  );
};
export default InquiryModel;
