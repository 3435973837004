import { useState, useEffect } from "react";
import { useGetLeadOfferQuery } from "../redux/storeApis";
import { useParams } from "react-router-dom";

export default function useLeadOfferData() {
  const {slug} = useParams()
  const { data: lead_offer, isLoading : isLoadingLeadOffer } = useGetLeadOfferQuery(slug);
  const [leadOfferData, setLeadOfferData] = useState({
    hotel: [],
    leadOffer: [],
    remainingDays: "",
    stayingDays: "",
    sports: [],
    numberOfGuest: 0,
    from_date: "",
    to_date: "",
    leadofferprices: [],
    lead_services: [],
    lead_contract_conditions: [],
    lead_services_with_types: [],
  });
  console.log(lead_offer?.data?.leadOffer, "lead_offer?.data?.leadOffer")
  useEffect(() => {
    if (lead_offer) {
      setLeadOfferData({
        hotel: lead_offer?.data?.hotel || [],
        leadOffer: lead_offer?.data?.leadOffer || [],
        lead_services: lead_offer?.data?.lead_services || [],
        remainingDays: lead_offer?.data?.remainingDays || "",
        stayingDays: lead_offer?.data?.stayingDays || "",
        sports: lead_offer?.data?.sports || [],
        numberOfGuest: lead_offer?.data?.leadOffer?.lead?.no_guests || 0,
        from_date: lead_offer?.data?.leadOffer?.from_date || 0,
        to_date: lead_offer?.data?.leadOffer?.to_date || 0,
        leadofferprices: lead_offer?.data?.leadOffer?.leadofferprices  || [],
        lead_services_with_types: lead_offer?.data?.lead_services_with_types || [],
      });
    }
  }, [lead_offer]);

  // const findHotelsCount = (dataArray, id) => {
  //   if (id) {
  //     const filteredData = dataArray?.find(
  //       (data) => data?.city_id == id || data?.country_id == id
  //     );
  //     return filteredData?.count || "0";
  //   } else {
  //     return "0";
  //   }
  // };

  // const findCityHotelsCount = (id) => {
  //   return findHotelsCount(homeData?.cityCountArray, id);
  // };

  // const findCountryHotelsCount = (id) => {
  //   return findHotelsCount(homeData?.countryCountArray, id);
  // };

  return {
    ...leadOfferData,
    isLoadingLeadOffer,
    // findCityHotelsCount,
    // findCountryHotelsCount,
  };
}
