import React from "react";
import useLeadContract from "../../../hooks/useLeadContract";
import { Config } from "../../../constant/Index";
import { useTranslation } from "react-i18next";

function PricePerPerson() {
  const { pricePerPersonArray } = useLeadContract();
  const {t} = useTranslation()
  return (
    <div className="d__flex flex__col gap__24 w__full">
      <h2 className="fw__600 solid__black__80 fs__30 md-fs__26">
        {t("lead_offer_contract.price_per_person")}
      </h2>
      <div className="d__grid grid__tcol__3 gap__24 md-grid__tcol__2 sm-grid__tcol__1">
        {pricePerPersonArray?.map((price, Index) => {
          return (
            <div key={Index} className="d__flex align__center gap__24 pl__24 pr__24 pb__20 pt__20 b__1__black10 radi__12">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={30}
                height={25}
                viewBox="0 0 30 25"
                fill="none"
              >
                <path
                  d="M18.3327 12.5001C18.3327 14.3411 16.8403 15.8334 14.9993 15.8334C13.1584 15.8334 11.666 14.3411 11.666 12.5001C11.666 10.6592 13.1584 9.16678 14.9993 9.16678C16.8403 9.16678 18.3327 10.6592 18.3327 12.5001Z"
                  stroke="#4A6DA7"
                  strokeWidth={2}
                />
                <path
                  d="M24.3327 11.3554C23.8992 11.2926 23.4541 11.2448 22.9993 11.213M6.99935 13.7871C6.54464 13.7553 6.0995 13.7075 5.66602 13.6447"
                  stroke="#4A6DA7"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.9993 22.5C13.2226 23.3298 10.8888 23.8333 8.33268 23.8333C6.91145 23.8333 5.55893 23.6777 4.33268 23.3968C2.33211 22.9386 1.66602 21.7352 1.66602 19.6814V5.31862C1.66602 4.00551 3.05272 3.10363 4.33268 3.39679C5.55893 3.67766 6.91145 3.83332 8.33268 3.83332C10.8888 3.83332 13.2226 3.32981 14.9993 2.49999C16.7761 1.67017 19.1099 1.16666 21.666 1.16666C23.0872 1.16666 24.4398 1.32232 25.666 1.60319C27.775 2.08623 28.3327 3.32714 28.3327 5.31862V19.6814C28.3327 20.9945 26.946 21.8964 25.666 21.6032C24.4398 21.3223 23.0872 21.1667 21.666 21.1667C19.1099 21.1667 16.7761 21.6702 14.9993 22.5Z"
                  stroke="#4A6DA7"
                  strokeWidth={2}
                />
              </svg>
              <div className="d__flex flex__col gap__4">
                <span className="fs__18 fw__600 solid__black__80">
                  {price?.price} {Config.currency}
                </span>
                <span className="fs__16 solid__black__60 md-fs__14">
                  {price?.label}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PricePerPerson;
