import React, { useEffect, useState } from "react";
import LeadLayout from "../../components/leadLayout/LeadLayout ";
import DZText from "../../components/text/DZText";
import LockBlack from "../../assets/Icons/lock__black";
import MailIcon from "../../assets/Icons/mail__outline";
import Input from "../../components/shared/input/Input";
import {
  useGetMeDataQuery,
  useUpdateLeadUserMutation,
} from "../../redux/storeApis";
import ForgotPasswordModal from "../../components/shared/forgotPasswordModal/ForgotPasswordModal";
import { useSnackBarManager } from "../../hooks/useSnackBarManager";
import useLoaderShow from "../../hooks/useLoaderShow";
import { UserIcon } from "../../assets/Icons/AllIcons";
import PhoneOutline from "../../assets/Icons/phone__outline";
import Loader from "../../assets/Loader";
import { useTranslation } from "react-i18next";

const ProfilePage = () => {
  const [updateLeadUser, { isLoading: isLoadingUpdateUser }] =
    useUpdateLeadUserMutation();
  const { data: user, isLoading: isLoadingUser } = useGetMeDataQuery();
  const userData = user?.data?.lead_user;
  const { t } = useTranslation();
  const { fnShowSnackBar } = useSnackBarManager();
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    currentPassword: "",
    newPassword: "",
  });
  const [forgotModal, setForgotModal] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (userData) {
      setData({
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        mobile: userData?.mobile,
        email: userData?.email,
      });
    }
  }, [userData]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!data?.first_name) {
      fnShowSnackBar("Please enter first name", true);
    } else if (!data?.last_name) {
      fnShowSnackBar("Please enter last name", true);
    } else if (!data?.mobile) {
      fnShowSnackBar("Please enter mobile number", true);
    } else {
      console.log(data);
      try {
        await updateLeadUser(data).unwrap();
        fnShowSnackBar("Profile updated successfully", false);
      } catch (error) {
        console.error("Failed to update profile:", error);
        alert("Failed to update profile");
      }
    }
  };

  return (
    <LeadLayout>
      <div className="flex flex-col gap-8 w-full min-h-[70vh]">
        <h1 className="text-center text-4xl text-[#333333] font-medium">
          <span className="text-[#4A6DA7]">{t("profile.profile")}</span>
        </h1>
        {isLoadingUser ? (
          <Loader />
        ) : (
          <form
            onSubmit={handleSubmit}
            className="flex flex-col w-full gap-4 lg:px-32"
          >
            <div className="flex flex-col gap-4 items-center w-full">
              <Input
                icon={<UserIcon iconColor={"#333333"} className="w-6 h-6" />}
                placeholder="First Name"
                type="text"
                name="first_name"
                value={data.first_name}
                onChange={handleChange}
                required
              />
              <Input
                icon={<UserIcon iconColor={"#333333"} className="w-6 h-6" />}
                placeholder="Last Name"
                type="text"
                name="last_name"
                value={data.last_name}
                onChange={handleChange}
                required
              />
              <Input
                icon={<PhoneOutline className="w-6 h-6" />}
                placeholder="Mobile Number"
                type="number"
                name="mobile"
                value={data.mobile}
                onChange={handleChange}
                required
              />
              <Input
                icon={<MailIcon className="w-6 h-6" />}
                placeholder="Your Email"
                type="email"
                name="email"
                value={data.email}
                onChange={handleChange}
                required
                disabled={true}
              />
            </div>
            <div className="flex flex-col gap-4 items-center w-full">
              <button
                type="submit"
                className="w-full md:text-base bg-primary-100 bg-[#4A6DA7] text-white py-3 rounded-full min-w-200 text-center sm:min-w-full"
                disabled={isLoadingUpdateUser}
              >
                {isLoadingUpdateUser ? "Updating..." : "Update"}
              </button>
            </div>
          </form>
        )}
        {forgotModal && <ForgotPasswordModal setForgotModal={setForgotModal} />}
      </div>
    </LeadLayout>
  );
};

export default ProfilePage;
