import React, { useState } from 'react'
import './TriPlanner.css'
import DZText from '../../text/DZText'
import InquiryModel from '../../shared/inquiryModel/InquiryModel'

const TriPlanner = () => {
    
    const [inquiryModel, setInquiryModel] = useState({ first: false, second: false, });
    const handleInquiryModel = () => {
        setInquiryModel({ first: true, second: false });
    };

    return (
        <div className='w-full flex items-center justify-center'>
            <InquiryModel inquiryModel={inquiryModel} setInquiryModel={setInquiryModel} />

            <div className="trip__planner section">
                <DZText className="trip__heading">{'tri_planner.heading'}</DZText>
                <div className="btn__container">
                    <a href="#" className="btn">
                        <DZText>{'tri_planner.first_btn'}</DZText>
                    </a>
                    <btn className="btn" onClick={()=> handleInquiryModel()}>
                        <DZText>{'tri_planner.second_btn'}</DZText>
                    </btn>
                </div>
            </div>

        </div>
    )
}
export default TriPlanner