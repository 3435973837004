import React from 'react'

const sign__blue = ({className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 26 30" fill="none" className={className}>
            <path d="M9 28.3333C9.47978 28.3333 11.2544 27.5234 13.0658 25.9037M13.0658 25.9037C14.6104 24.5225 16.1818 22.5524 17 19.9935C18.7778 14.4337 8.11111 19.9935 11.6667 24.6268C12.1041 25.1968 12.5753 25.6135 13.0658 25.9037ZM13.0658 25.9037C15.2028 27.1678 17.7081 26.032 19.4055 24.7259C19.9243 24.3267 20.1837 24.1271 20.3385 24.1893C20.4933 24.2514 20.5838 24.6086 20.7648 25.3229C21.3441 27.6092 23.0553 29.4547 25 26.4806" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M23.666 16.3333L23.666 9.52094C23.666 7.23514 23.666 6.09224 23.3087 5.17943C22.7343 3.71196 21.5196 2.55445 19.9796 2.00711C19.0217 1.66665 17.8223 1.66665 15.4236 1.66665C11.2258 1.66665 9.12685 1.66665 7.4505 2.26245C4.75553 3.22029 2.62977 5.24595 1.6246 7.81401C0.999348 9.41143 0.999347 11.4115 0.999347 15.4117L0.999347 18.8479C0.999346 22.9914 0.999346 25.0632 2.12961 26.5019C2.45345 26.9142 2.83751 27.2801 3.27011 27.5887C3.75956 27.9379 4.31883 28.1738 4.99935 28.3333" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.00065 15C1.00065 12.5454 2.9905 10.5556 5.4451 10.5556C6.33281 10.5556 7.37937 10.7111 8.24247 10.4798C9.00934 10.2744 9.60834 9.67536 9.81382 8.90849C10.0451 8.04538 9.88954 6.99882 9.88954 6.11111C9.88954 3.65651 11.8794 1.66667 14.334 1.66667" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default sign__blue