"use client";
import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import UsersBlack from "../../assets/Icons/users__black";
import CalenderBlack from "../../assets/Icons/calender__black";
import MailBlue from "../../assets/Icons/mail__blue";
import ClockBlue from "../../assets/Icons/clock__blue";
import ThumbBlue from "../../assets/Icons/thumb__blue";
import FlightBlue from "../../assets/Icons/flight__blue";
import SportBlue from "../../assets/Icons/sport__blue";
import LocationBlue from "../../assets/Icons/location__blue";
import InquiryModel from "../../components/shared/inquiryModel/InquiryModel";
import { useGetHotelDetailQuery } from "../../redux/storeApis";
import ImageSlider from "./components/ImageSlider";
import Attributes from "./components/Attributes";
import HotelSportTab from "./components/HotelSportTab";
import { Config, KEYS } from "../../constant/Index";
import GoogleMapMark from "./components/GoogleMapMark";
import ClimateChart from "./components/ClimateChart";
import useRemoveHtmlTags from "../../hooks/useRemoveHtmlTags";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { getLocalStorage } from "../../components/shared/local_storage";
import Input from "../../components/shared/input/Input";
import DateCalender from "../../components/cHome/hero/SearchFilter/Components/DateCalender/DateCalender";
import Loader from "../../assets/Loader";
import DZText from "../../components/text/DZText";
import DZRenderHtml from "../../components/renderHtml/DZRenderHtml";
import { LoadingAnimation } from "../../assets/Animations/LoadingAnimation";

const HotelDetail = () => {
  // Create a ref for the div element
  const mapDivRef = useRef(null);

  const { t } = useTranslation();
  const { slug } = useParams();
  const [inquiryModel, setInquiryModel] = useState({
    first: false,
    second: false,
  });
  const [hotel, setHotel] = useState(null);
  const [activeSport, setActiveSport] = useState(null);
  const [sportAttributes, setSportAttributes] = useState(null);
  const [hotelImages, setHotelImages] = useState(null);
  const [sportImages, setSportImages] = useState(null);
  const [sports, setSports] = useState(null);
  const searchFilter = JSON?.parse(getLocalStorage("searchField"));
  //change is here
  const [showPopup, setShowPopup] = useState("");
  //change is here
  const datePopupRef = useRef(null);
  //change is here
  const [inquiryFirstData, setInquiryFirstData] = useState({
    clubName: "",
    date: searchFilter?.date || [],
    from_date: searchFilter?.from_date || "",
    to_date: searchFilter?.to_date || "",
    sport: undefined,
    sportId: undefined,
    guests: searchFilter?.guests || 8,
    country: "",
    countryId: "",
    city: "",
    city_id: "",
    hotel_id: "",
    flight: "",
    flightId: "",
    budget: "",
    description: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    remember: false,
  });
  const { data: hotel_data, isLoading: isLoadingHotelData } =
    useGetHotelDetailQuery(slug);
  const { removeHtmlTags } = useRemoveHtmlTags();
  const handleOpenInquiryModel = () => {
    setInquiryModel({ first: true });
  };
  // Function to scroll to the target div
  const scrollToMap = (event) => {
    event.preventDefault();
    if (mapDivRef.current) {
      mapDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const markers = [
    {
      id: 1,
      name: "Hotel",
      icon: "https://images-ext-2.discordapp.net/external/4y9y1KUEaEhDLyBaRoMq-F_Kh3UBD2zjbCNuR2LzA5Q/https/ys-prod.fra1.digitaloceanspaces.com/web/icons/hotel.png?format=webp&quality=lossless&width=67&height=67",
      position: {
        lat: hotel_data?.data?.hotel?.lat,
        lng: hotel_data?.data?.hotel?.long,
      },
    },
    {
      id: 2,
      name: "Sport",
      icon: "https://images-ext-2.discordapp.net/external/sFWRlZS1qt7dXRoMeqnbkkgCgtAfO2SNMmQ9wbDb6so/https/ys-prod.fra1.digitaloceanspaces.com/web/icons/sports.png?format=webp&quality=lossless&width=67&height=67",
      position: {
        lat: hotel_data?.data?.hotel?.sports_lat,
        lng: hotel_data?.data?.hotel?.sports_long,
      },
    },
    {
      id: 3,
      name: "Airport",
      icon: "https://images-ext-2.discordapp.net/external/BGECIbSSxc7ITsJpB5EOXRLhtf5Nur1Za5ZhCVuM4NU/https/ys-prod.fra1.digitaloceanspaces.com/web/icons/airport.png?format=webp&quality=lossless&width=67&height=67",
      position: {
        lat: hotel_data?.data?.hotel?.airport_lat,
        lng: hotel_data?.data?.hotel?.airport_long,
      },
    },
  ];
  const selectedLanguage = getLocalStorage(KEYS.lang);
  const convertIntoArray = (data) => {
    return data?.split(",")?.map(Number);
  };
  const weatherData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    day_temp: convertIntoArray(hotel?.weather?.day_temp), // Example temperature data
    night_temp: convertIntoArray(hotel?.weather?.night_temp), // Example precipitation data
  };
  const sliderData = activeSport ? sportImages : hotelImages;
  const isExistSliderData = sliderData?.length > 0;
  const isFacilities = activeSport
    ? sportAttributes && sportAttributes.length > 0
    : hotel?.hotel_attributes && hotel.hotel_attributes.length > 0;

  const detail = activeSport ? hotel?.hotel_sports?.detail : hotel?.detail;
  const encodedDetail = detail?.[selectedLanguage];
  useEffect(() => {
    setHotel(hotel_data?.data?.hotel);
    setHotelImages(
      hotel_data?.data?.hotel?.images?.filter(
        (image) => image?.sports_id == null
      )
    );
    if (hotel_data?.data?.hotel?.hotel_sports && hotel_data?.data?.sports) {
      const hotelSportsIds = hotel_data.data.hotel.hotel_sports.map(
        (hotelSport) => hotelSport.sports_id
      );
      console.log("object", hotel_data.data.hotel);

      const matchingSports = hotel_data.data.sports.filter((sport) =>
        hotelSportsIds.includes(sport.id)
      );
      setSports(matchingSports);
    } else {
      setSports([]);
    }
  }, [hotel_data]);
  //change is here
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (datePopupRef.current && !datePopupRef.current.contains(e.target)) {
        setShowPopup("");
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showPopup]);
  function getEmbedUrl() {
    if (hotel?.source === "YouTube") {
      return Config.youtube + hotel?.video;
    } else if (hotel?.source === "Dailymotion") {
      return Config.dailymotion + hotel?.video;
    } else if (hotel?.source === "Vimeo") {
      return Config.vimeo + hotel?.video;
    } else {
      return "";
    }
  }
  //change is here
  const handleTogglePopup = () => {
    showPopup === "date" ? setShowPopup("") : setShowPopup("date");
    // if (type == showPopup) {
    //   setShowPopup("");
    // } else {
    //   setShowPopup(type);
    // }
  };
  const onBlurGuest = (e) => {
    console.log(e.target.value);
    if (e.target.value < 8) {
      setInquiryFirstData({ ...inquiryFirstData, guests: 8 });
    }
  };
  const handleSelectedSport = (activeSport) => {
    console.log(activeSport, "activeSport");
    setActiveSport(activeSport);
    setSportAttributes(
      hotel?.hotel_sports_attributes?.filter(
        (attribute) => attribute?.sports_id == activeSport?.id
      )
    );
    setSportImages(
      hotel?.images?.filter(
        (image) =>
          image?.sports_id != null && image?.sports_id == activeSport?.id
      )
    );
  };
  console.log(hotel_data?.data?.hotel?.id);
  return (
    <>
      <InquiryModel
        inquiryModel={inquiryModel}
        setInquiryModel={setInquiryModel}
        guests={inquiryFirstData?.guests}
        country={searchFilter?.country}
        countryId={searchFilter?.countryId}
        city={searchFilter?.city}
        cityId={hotel_data?.data?.hotel?.city?.id}
        sport={searchFilter?.sport}
        sportId={searchFilter?.sportId}
        hotelId={hotel_data?.data?.hotel?.id}
        lead_type={"direct"}
        from_date={inquiryFirstData?.from_date}
        to_date={inquiryFirstData?.to_date}
        date={inquiryFirstData?.date}
      />
      {isLoadingHotelData ? (
        <Loader />
      ) : (
        <>
          <div className="w__full d__flex flex__col section">
            <h1 className="fs__44 fw__500 solid__black__80 lg-fs__32">
              {hotel?.name}
            </h1>
            <div className="d__flex align__center gap__12">
              <span className="solid__black__80 fs__18 lg-fs__16">
                {/* {hotel?.city?.name?.en}, {hotel?.country?.name?.en} */}
                <DZText>{hotel?.city?.name}</DZText>,{" "}
                <DZText>{hotel?.country?.name}</DZText>
              </span>
              <a
                href="#location"
                onClick={scrollToMap}
                className="d__flex align__center gap__6"
              >
                <span className="solid__primary__100 fs__18 lg-fs__16">
                  {t("hotel_detail.look_map")}
                </span>
                <LocationBlue />
              </a>
            </div>
          </div>
          {isExistSliderData ? (
            <ImageSlider
              data={sliderData}
              path={activeSport ? Config.sportImage : Config.hotelSportImage}
            />
          ) : (
            <LoadingAnimation
              loaderStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          )}
          <div className="d__flex flex__col gap__44 w__full section">
            <HotelSportTab
              handleSelectedSport={handleSelectedSport}
              activeSport={activeSport}
              sports={sports}
              hotel={hotel?.name}
            />
            <div className="d__flex flex-row align__start gap__80 w__full max-xl:flex-col">
              <div className="d__flex flex__col gap__36 w__full">
                {/* <DZRenderHtml encodedDetail={ hotel?.detail?.[selectedLanguage] ?? hotel?.detail?.en } /> */}
                {encodedDetail ? (
                  <DZRenderHtml encodedDetail={encodedDetail} />
                ) : null}
                {isFacilities ? (
                  <Attributes
                    data={
                      activeSport ? sportAttributes : hotel?.hotel_attributes
                    }
                  />
                ) : null}
                {hotel?.video && (
                  <>
                    <h2 className="solid__black__80 fs__30 fw__500 lg-fs__24 md-fs__20">
                      {t("hotel_detail.video_from")} {hotel?.name}
                    </h2>
                    <iframe
                      style={{ borderRadius: "0.6rem" }}
                      className="radi__16 max__w__full min__h__360"
                      src={getEmbedUrl(hotel?.video)}
                      width="100%"
                      height="100%"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </>
                )}
                <div ref={mapDivRef}>
                  {markers && <GoogleMapMark markers={markers} />}
                </div>
                <h2 className="solid__black__80 fs__30 fw__500 lg-fs__24 md-fs__20">
                  {/* {t("hotel_detail.the_temperature_in")} {hotel?.city?.name?.en} */}
                  <DZText>{"hotel_detail.the_temperature_in"}</DZText>{" "}
                  <DZText>{hotel?.city?.name}</DZText>
                </h2>
                <ClimateChart data={weatherData} />
                <h2 className="solid__black__80 fs__30 fw__500 lg-fs__24 md-fs__20">
                  {t("hotel_detail.distances")}
                </h2>
                <div className="w__full b__1__black10 p__24 d__flex flex__col gap__16 radi__16">
                  <div className="d__flex align__center justify__between w__full">
                    <div className="d__flex align__center gap__12">
                      <FlightBlue />
                      <span className="fs__16 fw__400 solid__black__60">
                        {t("hotel_detail.airport")}
                      </span>
                    </div>
                    <span className="fs__16 fw__400 solid__black__60">
                      {hotel?.airport_distance} {Config.distance_unit}
                    </span>
                  </div>
                  <div className="d__flex align__center justify__between w__full">
                    <div className="d__flex align__center gap__12">
                      <SportBlue />
                      <span className="fs__16 fw__400 solid__black__60">
                        {t("hotel_detail.sport")}
                      </span>
                    </div>

                    <span className="fs__16 fw__400 solid__black__60">
                      {hotel?.sports_distance} {Config.distance_unit}
                    </span>
                  </div>
                  <div className="d__flex align__center justify__between w__full">
                    <div className="d__flex align__center gap__12">
                      <SportBlue />
                      <span className="fs__16 fw__400 solid__black__60">
                        {t("hotel_detail.center")}
                      </span>
                    </div>
                    <span className="fs__16 fw__400 solid__black__60">
                      {hotel?.center_distance} {Config.distance_unit}
                    </span>
                  </div>
                </div>
              </div>
              <div className="d__flex flex__col gap__52 min-w-[450px] position__sticky t__24 max-xl:w-full max-xl:min-w-[340px]">
                <div className="d__flex flex__col align__center gap__32 back__white__100 shadow__6__60__007 p__32 radi__16">
                  <div className="d__flex align__center flex__col gap__12">
                    <span className="fs__28 fw__500 solid__black__80 text-center">
                      {t("hotel_detail.request_a_quote")}
                    </span>
                    <span className="fs-16  fw__400 max__w__300 solid__black__60 text__center">
                      {t("hotel_detail.your_travel")}
                    </span>
                  </div>
                  <div className="d__flex flex__col gap__16 w__full relative">
                    <Input
                      icon={<UsersBlack ClassNames="" />}
                      placeholder={t("hotel_detail.guests")}
                      type="number"
                      value={inquiryFirstData?.guests}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setInquiryFirstData({
                          ...inquiryFirstData,
                          guests: e.target.value,
                        });
                      }}
                      onBlur={onBlurGuest}
                    />
                    {/* change is here */}
                    <Input
                      icon={<CalenderBlack classNames="" />}
                      placeholder={t("hotel_detail.arrival_date")}
                      type="text"
                      value={
                        inquiryFirstData?.from_date || inquiryFirstData?.to_date
                          ? `${Config.fnMultilingualDate(inquiryFirstData?.from_date)} - ${Config.fnMultilingualDate(inquiryFirstData?.to_date)}`
                          : ""
                      }
                      onClick={handleTogglePopup}
                    />

                    {showPopup === "date" && (
                      <DateCalender
                        ref={datePopupRef}
                        inputFiled={"date"}
                        closePopup={() => setShowPopup("")}
                        setInquiryFirstData={setInquiryFirstData}
                        date={inquiryFirstData?.date}
                      />
                    )}
                  </div>
                  <span
                    onClick={handleOpenInquiryModel}
                    className="back__primary__100 text__center opacity__white__100 radi__60 w__full p__16 fs__18 fw__400 cursor__p"
                  >
                    {t("hotel_detail.send_inquiry")}
                  </span>
                </div>
                <div className="w__full d__flex align__center gap__16">
                  <div className="d__flex align__center justify__center flex__col gap__12 pt__16 pl__12 pr__12 pb__16 b__1__black10 radi__12 w__full h__140">
                    <MailBlue />
                    <span className="fs__14 solid__black__80 text__center">
                      {t("hotel_detail.free_and_without")}
                    </span>
                  </div>
                  <div className="d__flex align__center justify__center flex__col gap__12 pt__16 pl__12 pr__12 pb__16 b__1__black10 radi__12 w__full h__140">
                    <ClockBlue />
                    <span className="fs__14 solid__black__80 text__center">
                      {t("hotel_detail.driving")} <br />{" "}
                      {t("hotel_detail.response_times")}
                    </span>
                  </div>
                  <div className="d__flex align__center justify__center flex__col gap__12 pt__16 pl__12 pr__12 pb__16 b__1__black10 radi__12 w__full h__140">
                    <ThumbBlue />
                    <span className="fs__14 solid__black__80 text__center">
                      {t("hotel_detail.over_15_years")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default HotelDetail;
