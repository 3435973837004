import React from "react";
import ServicesCard from "./ServicesCard";
import { useTranslation } from "react-i18next";
import TransportBlue from "../../../assets/Icons/transport__blue";
import BedBlue from "../../../assets/Icons/bed__blue";
import CookingBlue from "../../../assets/Icons/cooking__blue";
import AtletikBlue from "../../../assets/Icons/atletik__blue";
import ReloadBlue from "../../../assets/Icons/reload__blue";
import DashboardBlue from "../../../assets/Icons/dashboard__blue";
import { Config } from "../../../constant/Index";
function IncludeServices({ lead_services }) {
  const { t } = useTranslation();
  const include_services = [
    {
      heading: t("lead_offer_contract.transport"),
      icon: <TransportBlue />,
      services: lead_services?.filter((s) => s.type == "Transport"),
    },
    {
      heading: t("lead_offer_contract.stay"),
      icon: <BedBlue />,
      services: lead_services?.filter((s) => s.type == "Stay"),
    },
    {
      heading: t("lead_offer_contract.meals"),
      icon: <CookingBlue />,
      services: lead_services?.filter((s) => s.type == "Meal"),
    },
    {
      heading: t("lead_offer_contract.workout"),
      icon: <AtletikBlue className="w__26 h__26" />,
      services: lead_services?.filter((s) => s.type == "Workout"),
    },
    {
      heading: "YakSport",
      icon: <ReloadBlue className="w__26 h__26" />,
      services: lead_services?.filter((s) => s.type == "YakSport"),
    },
    {
      heading: t("lead_offer_contract.other"),
      icon: <DashboardBlue className="w__28 h__28" />,
      services: lead_services?.filter((s) => s.type == "Other"),
    },
  ];


  return (
    <div className="d__flex flex__col gap__24 w__full">
      <h2 className="fw__600 solid__black__80 fs__30 md-fs__26">
        {t("lead_offer_contract.included_services")}
      </h2>
      {lead_services?.map((data, index) => {
        // if (data?.services?.length < 1 || data?.services == undefined)
        //   return null;
        return (
          <ServicesCard
            key={index}
            heading={data?.name}
            // icon={data?.icon}
            services={data?.lead_services}
          />
        );
      })}
    </div>
  );
}
export default IncludeServices;