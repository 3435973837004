import React from "react";
import useHomePageDataManager from "../../hooks/useHomePageDataManager";
import { useParams } from "react-router-dom";
import Loader from "../../assets/Loader"
import DZText from "../../components/text/DZText";
import DZRenderHtml from "../../components/renderHtml/DZRenderHtml";
import { getLocalStorage } from "../../components/shared/local_storage";
import  { KEYS } from "../../constant/Index";
import MetaTags from "../../components/SEO/MetaTags";

const Page = () => {
  const { slug } = useParams();
  const { pages } = useHomePageDataManager();

  const page = pages?.find((page) => page?.url === slug);
  const selectedLanguage = getLocalStorage(KEYS.lang)

  return (
    <>
      <MetaTags href={slug} content={page?.meta_description?.en} metaTitle={page?.meta_description?.en}  />

      {page?.title?.en && page?.heading_description?.en ? <div style={{ maxWidth: "1400px" }}>
        <h1
          className="fs__44 fw__500 solid__black__80 lg-fs__32"
          style={{ color: "#4A6DA7" }}
        >
          {/* {page?.title?.en || ""} */}
          <DZText>{page?.title}</DZText>
        </h1>
        {<DZRenderHtml encodedDetail={ page?.heading_description?.[selectedLanguage] ?? page?.heading_description?.en } />}
      </div>: <Loader/>}
    </>
  );
};
export default Page;
