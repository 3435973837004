import React, { useState } from "react";
import DashboardWhite from "../../../assets/Icons/dashboard__white";
import Image from "../../shared/image/Image";
import FullScreenGallery from "../../shared/FullScreenGallery/index";
import useHomePageDataManager from "../../../hooks/useHomePageDataManager";
import { Config } from "../../../constant/Index";
import "./GallerySection.css";
import { useTranslation } from "react-i18next";
import Loader from "../../../assets/Loader";
import DZText from "../../text/DZText";
const GallerySection = () => {
  const { t } = useTranslation();
  const { galleryImages } = useHomePageDataManager();
  const [isSliderEnabled, setIsSliderEnabled] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const handleGalleryOpen = (index) => {
    setSelectedImageIndex(index);
    setIsSliderEnabled(!isSliderEnabled);
  };
  const handleCloseGallery = () => {
    setIsSliderEnabled(!isSliderEnabled);
  };
  return (
    <div className="gallery__section section">
      <DZText className={'section__heading'}>{'gallery_section.heading'}</DZText>
      <div className="gallery__container">
        {galleryImages?.map((image, index) => {
          if (index > 4) return null;
          const src = image?.image;
          return (
            <div
              key={index}
              className="gallery__image cursor__p"
              onClick={() => handleGalleryOpen(index)}
            >
              <img
                src={`${Config.galleryImage}${src}`}
                alt="#"
                className="img"
              />
            </div>
          );
        })}
      </div>
      <div onClick={() => handleGalleryOpen(0)} className="big__btn cursor__p">
        <DashboardWhite />
        <DZText>{'gallery_section.show_all_btn'}</DZText>
      </div> 
      {isSliderEnabled ? (
        <FullScreenGallery
          selectedImageIndex={selectedImageIndex}
          handleCloseGallery={handleCloseGallery}
        /> 
      ): ""}
    </div>
  );
};
export default GallerySection;
