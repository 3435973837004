import Lottie from "react-lottie";
import emptyLoader from '../../animations/emptyLoader.json'

export const LoadingAnimation = ({ loaderStyle, containerClassName }) => {
    const mergeStyles = { height: "200px", width: "200px", ...loaderStyle };
    return (
      <div className={containerClassName} style={{display:'flex', minHeight:'460px', alignItems:'center'  }}>
        <Lottie options={{ animationData: emptyLoader }} style={mergeStyles} />
      </div>
    )
  };
  