import React, { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap';
import { useGetFaqsQuery } from '../../redux/storeApis';
import Loader from '../../assets/Loader';
import LeadLayout from '../../components/leadLayout/LeadLayout ';
import DZText from '../../components/text/DZText';

const FaqsPage = () => {
    
    const { data : allFaqs, isLoading : isLoadingFaqs } = useGetFaqsQuery();

    let answerRefs = useRef([]);
    answerRefs.current = [];

    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);

    useEffect(() => {
        answerRefs.current.forEach((ref, index) => {
            if (selectedQuestionIndex == index) { gsap.to(ref, { height: 'auto', opacity: 1, duration: 0.5 }); } 
            else { gsap.to(ref, { height: 0, opacity: 0, duration: 0.5 });  }
        });
    }, [selectedQuestionIndex]);

    const fnAddToRef = (e) => {
        if (e && !answerRefs.current.includes(e)) {
            answerRefs.current.push(e);
        }
    };

    const fnOpenHideQuestion = (questionIndex) => {
        if(selectedQuestionIndex == questionIndex){ setSelectedQuestionIndex(null); }
        else { setSelectedQuestionIndex(questionIndex) }
    };

    return (
        <LeadLayout>
            <div className="flex flex-col items-center gap-[64px] sm:px-[7.7vw] sm:py-[96px] py-[48px] px-[24px] w-full min-h-[80vh]" >
            { isLoadingFaqs ? <Loader/> 
              : <>
                    <div className="flex flex-col items-center gap-[20px]">
                        <h3 className='text-[#101828] sm:text-[36px] text-[32px] font-[600] text-center'>
                            <DZText>{'faqs.frequently_asked_questions'}</DZText>
                        </h3>
                        <span className='text-[#475467] sm:text-[20px] text-[16px] text-center'>
                            <DZText>{'faqs.everything_you_need_to_know_about_the_product_and_billing'}</DZText>
                        </span>
                    </div>
        
                    <div className="flex flex-col md:w-[768px] gap-[32px] w-full">
                        {allFaqs?.map((faq, index) => (
                            <div key={index} className="flex flex-col items-start mb-[16px] w-full">
                                <div className="flex items-start justify-between gap-[24px] w-full cursor-pointer" onClick={() => fnOpenHideQuestion(index)}>
                                    <div className="flex flex-col gap-[8px]">
                                        <h4 className='text-[#101828] font-[500] sm:text-[18px] text-[16px] select-none'>
                                            <DZText>{faq?.question}</DZText>
                                        </h4>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" className='min-w-[32px]'>
                                        <path d={selectedQuestionIndex == index ? "M8 12H16" : "M12 8V16M8 12H16"} stroke="#98A2B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div ref={fnAddToRef} style={{ height: 0, overflow: 'hidden', opacity: 0 }}>
                                    <p className='text-[#475467] mt-[8px] select-none'>
                                        <DZText>{faq?.answer}</DZText>
                                    </p>
                                </div>
                                <hr className='border-[#EAECF0]' />
                            </div>
                        ))}
                    </div>
              </>
            }
            </div>
        </LeadLayout>
    );
};

export default FaqsPage