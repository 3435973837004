import React from 'react'

const DeparutreBlue = ({className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="30" viewBox="0 0 28 30" fill="none" className={className}>
            <path d="M22 1.66675V4.33341M6 1.66675V4.33341" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.3333 21.6668L11.3333 16.7965C11.3333 16.5408 11.151 16.3335 10.9261 16.3335H10M16.1729 21.6668L17.9789 16.799C18.0633 16.5714 17.8838 16.3335 17.6276 16.3335H15.3333" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" />
            <path d="M1.33398 15.3243C1.33398 9.51458 1.33398 6.60971 3.00348 4.80486C4.67297 3 7.35997 3 12.734 3H15.2673C20.6413 3 23.3283 3 24.9978 4.80486C26.6673 6.60971 26.6673 9.51458 26.6673 15.3243V16.009C26.6673 21.8188 26.6673 24.7236 24.9978 26.5285C23.3283 28.3333 20.6413 28.3333 15.2673 28.3333H12.734C7.35997 28.3333 4.67297 28.3333 3.00348 26.5285C1.33398 24.7236 1.33398 21.8188 1.33398 16.009V15.3243Z" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 9.66675H22" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default DeparutreBlue