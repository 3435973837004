import React from 'react'

const CompanyBlue = ({className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" className={className}>
            <path d="M1.66602 28.3333H28.3327" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.33203 28.3333L4.33203 8.00161C4.33203 7.16928 4.33203 6.75311 4.55606 6.41866C4.78008 6.08422 5.18352 5.89811 5.99039 5.52587L11.9904 2.75791C13.9363 1.86022 14.9092 1.41137 15.6206 1.81704C16.332 2.2227 16.332 3.22635 16.332 5.23365L16.332 28.3333" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M25.6654 28.3333L25.6654 13.9814C25.6654 13.1795 25.6654 12.7786 25.433 12.4564C25.2007 12.1342 24.7823 11.9549 23.9456 11.5963L16.332 8.33333" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 11L11.6667 11M9 16.3333H11.6667M9 21.6667H11.6667" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default CompanyBlue