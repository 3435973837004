import React from 'react'

const CheckBoxBlack = ({ ClassName = "" }) => {
    return (
        <div className='d__flex align__center justify__center'>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" className={ClassName}>
                <path d="M14.2983 5.96663C14.3332 6.66672 14.3332 7.49953 14.3332 8.49996C14.3332 11.4855 14.3332 12.9783 13.4057 13.9058C12.4782 14.8333 10.9854 14.8333 7.99984 14.8333C5.01428 14.8333 3.52149 14.8333 2.594 13.9058C1.6665 12.9783 1.6665 11.4855 1.6665 8.49996C1.6665 5.5144 1.6665 4.02162 2.594 3.09412C3.52149 2.16663 5.01428 2.16663 7.99984 2.16663C8.71446 2.16663 9.34355 2.16663 9.89984 2.17935" stroke="#656565" stroke-width="1.25" stroke-linecap="round" />
                <path d="M5.33301 8.16663C5.33301 8.16663 6.33301 8.16663 7.66634 10.5C7.66634 10.5 11.0389 4.38885 14.333 3.16663" stroke="#656565" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
    )
}

export default CheckBoxBlack