import React from "react";
import {
  FaqsIcon,
  LeadIcon,
  LeadOfferIcon,
  LeadsContractIcon,
  MessageIcon,
  UserIcon,
} from "../../../assets/Icons/AllIcons";
import { Link, useLocation } from "react-router-dom";
import RoutesLink from "../../../constant/RoutesLink";
import MailBlue from "../../../assets/Icons/mail__blue";
import { Badge } from "@mui/material";
import { useTranslation } from "react-i18next";

const LeadsSideBar = ({ badgeText }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <div className="flex flex-col gap-[16px] lg:min-w-[240px] sm:min-w-[200px] max-sm:fixed max-sm:left-0 right-0 bottom-[32px] max-sm:flex-row max-sm:items-center max-sm:justify-center z-[1]">
      <Link
        to={RoutesLink.LEADS_PAGE}
        className={`flex items-center gap-[8px] rounded-full px-[24px] py-[16px] cursor-pointer max-sm:px-[16px] z-[1] ${
          pathname == RoutesLink.LEADS_PAGE
            ? " bg-[#4A6DA7] "
            : " bg-[#F6F8FB] "
        } `}
      >
        <LeadIcon
          iconColor={pathname == RoutesLink.LEADS_PAGE ? "#F2F2F2" : "#333333"}
        />
        <span
          className={` ${
            pathname == RoutesLink.LEADS_PAGE
              ? "text-[#F2F2F2]"
              : "text-[#333333]"
          } "text-[#F2F2F2]"} max-sm:hidden `}
        >
          {t("profile.my_leads")}
        </span>
      </Link>
      <Link
        to={RoutesLink.LEADS_OFFERS_PAGE}
        className={`flex items-center gap-[8px] rounded-full px-[24px] py-[16px] cursor-pointer max-sm:px-[16px] z-[1] ${
          pathname == RoutesLink.LEADS_OFFERS_PAGE
            ? " bg-[#4A6DA7] "
            : " bg-[#F6F8FB] "
        } `}
      >
        <LeadOfferIcon
          iconColor={
            pathname == RoutesLink.LEADS_OFFERS_PAGE ? "#F2F2F2" : "#333333"
          }
        />
        <span
          className={` ${
            pathname == RoutesLink.LEADS_OFFERS_PAGE
              ? "text-[#F2F2F2]"
              : "text-[#333333]"
          } "text-[#F2F2F2]"} max-sm:hidden `}
        >
          {t("profile.leads_offer")}
        </span>
      </Link>
      <Link
        to={RoutesLink.LEADS_CONTRACT_PAGE}
        className={`flex items-center gap-[8px] rounded-full px-[24px] py-[16px] cursor-pointer max-sm:px-[16px] z-[1] ${
          pathname == RoutesLink.LEADS_CONTRACT_PAGE
            ? " bg-[#4A6DA7] "
            : " bg-[#F6F8FB] "
        } `}
      >
        <LeadsContractIcon
          iconColor={
            pathname == RoutesLink.LEADS_CONTRACT_PAGE ? "#F2F2F2" : "#333333"
          }
        />
        <span
          className={` ${
            pathname == RoutesLink.LEADS_CONTRACT_PAGE
              ? "text-[#F2F2F2]"
              : "text-[#333333]"
          } "text-[#F2F2F2]"} max-sm:hidden `}
        >
          {t("profile.lead_offer_contract")}
        </span>
      </Link>
      <Link
        to={RoutesLink.MESSAGE_PAGE}
        className={`flex items-center gap-[8px] rounded-full px-[24px] py-[16px] cursor-pointer max-sm:px-[16px] z-[1] ${
          pathname == RoutesLink.MESSAGE_PAGE
            ? " bg-[#4A6DA7] "
            : " bg-[#F6F8FB] "
        } `}
      >
        <MessageIcon iconColor={  pathname == RoutesLink.MESSAGE_PAGE ? "#F2F2F2" : "#333333"  } />

        <span
          className={` ${
            pathname == RoutesLink.MESSAGE_PAGE
              ? "text-[#F2F2F2]"
              : "text-[#333333]"
          } "text-[#F2F2F2]"} max-sm:hidden `}
        >
          {t("profile.messages")}
        </span>
        {/* <Badge badgeContent={badgeText} color="primary">
          <MailIcon color="action" />
        </Badge> */}
      </Link>

      <Link
        to={RoutesLink.FAQS}
        className={`flex items-center gap-[8px] rounded-full px-[24px] py-[16px] cursor-pointer max-sm:px-[16px] z-[1] ${
          pathname == RoutesLink.FAQS
            ? " bg-[#4A6DA7] "
            : " bg-[#F6F8FB] "
        } `}
      >
        <FaqsIcon iconColor={  pathname == RoutesLink.FAQS ? "#F2F2F2" : "#333333"  } />
        <span
          className={` ${
            pathname == RoutesLink.FAQS
              ? "text-[#F2F2F2]"
              : "text-[#333333]"
          } "text-[#F2F2F2]"} max-sm:hidden `}
        >
          {t("profile.faqs")}
        </span>
      </Link>

      <Link
        to={RoutesLink.PROFILE_PAGE}
        className={`flex items-center gap-[8px] rounded-full px-[24px] py-[16px] cursor-pointer max-sm:px-[16px] z-[1] ${
          pathname == RoutesLink.PROFILE_PAGE
            ? " bg-[#4A6DA7] "
            : " bg-[#F6F8FB] "
        } `}
      >
        <UserIcon
          iconColor={
            pathname == RoutesLink.PROFILE_PAGE ? "#F2F2F2" : "#333333"
          }
        />
        <span
          className={` ${
            pathname == RoutesLink.PROFILE_PAGE
              ? "text-[#F2F2F2]"
              : "text-[#333333]"
          } "text-[#F2F2F2]"} max-sm:hidden `}
        >
          {" "}
          {t("profile.profile")}
        </span>
      </Link>
      <div className="sm:hidden absolute inset-[-10px] rounded-full bg-[#4A6DA7] blur-3xl"></div>
    </div>
  );
};
export default LeadsSideBar;
