import React, { useState } from "react";
import MoneyBlue from "../../assets/Icons/money__blue";
import DeparutreBlue from "../../assets/Icons/departure__blue";
import UserMultiple from "../../assets/Icons/user__multiple";
import DateBlue from "../../assets/Icons/calender__blue";
import useLeadOfferData from "../../hooks/useLeadOfferData";
import ImageSlider from "../hotelDetail/components/ImageSlider";
import IncludeServices from "../../components/cLeadContract/includeServices/IncludeServices";
import { Config, KEYS } from "../../constant/Index";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackBarManager } from "../../hooks/useSnackBarManager";
import { useLeadOfferStatusMutation } from "../../redux/storeApis";
import { LoadingButton } from "@mui/lab";
import DZText from "../../components/text/DZText";
import DZRenderHtml from "../../components/renderHtml/DZRenderHtml";
import { getLocalStorage } from "../../components/shared/local_storage";
import Loader from "../../assets/Loader";

// function HTMLRenderer({ encodedDetail }) {
//   const decodeAndConvertToHTML = (encodedString) => {
//     const decodedString = document.createElement("textarea");
//     decodedString.innerHTML = encodedString;
//     const originalForm = decodedString.value;
//     return `<p>${originalForm}</p>`;
//   };
//   const htmlString = decodeAndConvertToHTML(encodedDetail);
//   return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
// }

function formatDate(inputDate) {
  // Create a new Date object with the input date
  const date = new Date(inputDate);

  // Extract day, month, and year
  const day = date.getDate();
  // Month is zero-indexed, so add 1 to get the correct month
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  // Format the date as dd-mm-yyyy
  const formattedDate = `${day < 10 ? "0" : ""}${day}-${month < 10 ? "0" : ""
    }${month}-${year}`;

  return formattedDate;
}

const LeadOffer = () => {
  const { t } = useTranslation();
  const [updateStatus, statusResponse] = useLeadOfferStatusMutation();
  const { fnShowSnackBar } = useSnackBarManager();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const location = useLocation();

  
  const currentDate = new Date()
  
  const selectedLanguage = getLocalStorage(KEYS.lang)
  
  const {
    hotel,
    leadOffer,
    remainingDays,
    stayingDays,
    sports,
    numberOfGuest,
    from_date,
    to_date,
    leadofferprices,
    lead_services,
    isLoadingLeadOffer,
    lead_services_with_types,
  } = useLeadOfferData(location.state);
  console.log(lead_services_with_types, "lead_services_with_types")
  console.log(leadOffer?.detail, "")
  const expireDate = new Date(leadOffer?.expired_at);
  const handleUpdateStatus = (status) => {
    status === "rejected" ? setLoading(true) : setLoading1(true);
    updateStatus({ slug: leadOffer?.slug, status: status })
      .then((response) => {
        console.log(response);
        if (response?.data?.success) {
          fnShowSnackBar(t(response?.data?.message));
        } else {
          fnShowSnackBar(response?.error?.data?.message);
        }
        setLoading(false);
        setLoading1(false);
      })
      .catch((error) => {
        console.error("API Error:", error);
        fnShowSnackBar(t("inquiry_model.api_error"));
      })
      .finally(() => {
        setLoading(false);
        setLoading1(false);

        window.location.reload();
      });
  };

  return (
    <>
      {isLoadingLeadOffer ? <Loader />
        : <div className="d__flex flex__col gap__64 w__full section">
          <div className="d__flex flex__col gap__36">
            <DZRenderHtml encodedDetail={leadOffer?.detail?.[selectedLanguage] ?? leadOffer?.detail?.en} />
            <IncludeServices lead_services={lead_services_with_types} />
          </div>
          <div className="wfull dflex flexcol section">
            <h1 className="fs44 fw500 solidblack80 lg-fs32">{hotel?.name}</h1>
            <div className="dflex aligncenter gap12">
              <span className="solidblack80 fs18 lg-fs16">
                {/* {hotel?.city?.name?.en}, {hotel?.country?.name?.en} */}
                <DZText>{hotel?.city?.name}</DZText>, <DZText>{hotel?.country?.name}</DZText>
              </span>
            </div>
          </div>
          <ImageSlider data={hotel?.images} path={Config.hotelSportImage} />
          <div className="d__flex flex__col gap__24">
            <h2 className="fw__600 solid__black__80 fs__30 md-fs__26">
              <DZText>{"lead_offer.details"}</DZText>
            </h2>
            <div className="d__grid grid__tcol__2 gap__24 sm-grid__tcol__1">
              <div className="d__flex align__center gap__24 pl__24 pr__24 pb__20 pt__20 b__1__black10 radi__12">
                <DeparutreBlue className={"w__26 h__26"} />
                <div className="d__flex flex__col gap__4">
                  <DZText className="fs__18 fw__600 solid__black__80" >{"lead_offer.departure_date"}</DZText>
                  <span className="fs__16 solid__black__60 md-fs__14">
                    {from_date} {"("}
                    <DZText>{"lead_offer.BillundAntalya"}</DZText>
                    {")"}
                  </span>
                </div>
              </div>
              <div className="d__flex align__center gap__24 pl__24 pr__24 pb__20 pt__20 b__1__black10 radi__12">
                <UserMultiple ClassName="w__28 h__28" />
                <div className="d__flex flex__col gap__4">
                  <DZText className="fs__18 fw__600 solid__black__80" >{"lead_offer.number_of_travelers"}</DZText>

                  <span className="fs__16 solid__black__60 md-fs__14">
                    ~ {numberOfGuest} <DZText>{"lead_offer.traveler"}</DZText>
                  </span>
                </div>
              </div>
              <div className="d__flex align__center gap__24 pl__24 pr__24 pb__20 pt__20 b__1__black10 radi__12">
                <DateBlue ClassName="w__28 h__28" />
                <div className="d__flex flex__col gap__4">
                  <DZText className="fs__18 fw__600 solid__black__80" >{"lead_offer.number_of_travel_days"}</DZText>
                  <span className="fs__16 solid__black__60 md-fs__14">
                    {stayingDays} <DZText>{"lead_offer.days"}</DZText>
                  </span>
                </div>
              </div>
              <div className="d__flex align__center gap__24 pl__24 pr__24 pb__20 pt__20 b__1__black10 radi__12">
                <DeparutreBlue className={"w__26 h__26"} />
                <div className="d__flex flex__col gap__4">
                  <DZText className="fs__18 fw__600 solid__black__80" >{"lead_offer.return_date"}</DZText>
                  <span className="fs__16 solid__black__60 md-fs__14">
                    {to_date}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="d__flex flex__col gap__24">
            <h2 className="fw__600 solid__black__80 fs__30 md-fs__26">
              <DZText>{"lead_offer.price_per_person"}</DZText>
            </h2>
            <div className="d__grid grid__tcol__3 gap__24 md-grid__tcol__2 sm-grid__tcol__1">
              {leadofferprices?.map((leadOfferPrice, index) => {
                return (
                  <div
                    key={index}
                    className="d__flex align__center gap__24 pl__24 pr__24 pb__20 pt__20 b__1__black10 radi__12"
                  >
                    <MoneyBlue className={"w__24 h__24"} />
                    <div className="d__flex flex__col gap__4">
                      <span className="fs__18 fw__600 solid__black__80">
                        {leadOfferPrice?.price} {Config.currency}
                      </span>
                      <span className="fs__16 solid__black__60 md-fs__14">
                        {leadOfferPrice?.label}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="hotel__offer d__flex align__center justify__between gap__24 w__full pl__44 pt__44 pb__44 pr__44 radi__24 sm-flex__col md-pl__32 md-pt__28 md-pb__28 md-pr__32 sm-gap__44 sm-pl__24 sm-pt__24 sm-pb__28 sm-pr__24">
            <div className="d__flex flex__col gap__08">
              {/* <span className="opacity__white__80 fs__18 md-fs__16 sm-text__center">
            {t("lead_offer.make_decision")}
          </span> */}
              <DZText className={"opacity__white__80 fs__18 md-fs__16 sm-text__center"}>{'lead_offer.make_decision'}</DZText>
              <h1 className="opacity__white__100 fs__32 fw__500 md-fs__28 sm-text__center">
                {hotel?.name}
              </h1>
              <span className="opacity__white__80 fs__20 md-fs__18 sm-text__center">
                {/* {hotel?.country?.name?.en}, {hotel?.city?.name?.en} */}
                <DZText>{hotel?.country?.name}</DZText>, <DZText>{hotel?.city?.name}</DZText>
              </span>

              {leadOffer.status === "success" ||
                leadOffer.status === "rejected" ||
                leadOffer.status === "deleted"
                ? (
                  ""
                ) : (
                  <>
                    {currentDate <= expireDate && ( 
                      <>
                        <span className="opacity__white__80  fs__18 md-fs__16 sm-text__center">
                          {remainingDays} Remaining days
                        </span>
                        <span
                          className="opacity__white__70  fs__18 md-fs__16 sm-text__center"
                          style={{ color: "#FE8182" }}
                        >
                          Expired At: {formatDate(leadOffer?.expired_at)}
                        </span>
                      </>
                    )}
                  </>
                )}
            </div>
            {leadOffer.status === "success" ||
              leadOffer.status === "rejected" ||
              leadOffer.status === "deleted"
              ? (
                <span
                  style={{
                    textTransform: "capitalize",
                    background: leadOffer.status === "rejected" ? "#D55050" : "green",
                  }}
                  className="md-fs__15  opacity__white__100 pl__32 pr__32 pb__12 pt__12 radi__24 b__1__white100 min__w__200 text__center sm-min__w__full"
                >
                  {leadOffer.status}
                </span>
              ) : (
                <div className="d__flex gap__24 md-gap__16 sm-flex__col sm-w__full sm-gap__12">
                  {currentDate > expireDate ? (<span
                    style={{
                      textTransform: "capitalize",
                      background: leadOffer.status === "expired" ? "#D55050" : "red",
                    }}
                    className="md-fs__15  opacity__white__100 pl__32 pr__32 pb__12 pt__12 radi__24 b__1__white100 min__w__200 text__center sm-min__w__full"
                  >
                    Expired
                  </span>) : (
                    <>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3, cursor: 'pointer', border: '1px solid var(--opacity-white-100)', padding: '12px 32px', minWidth: "200px", background: 'transparent', borderRadius: '60px', color: 'var(--opacity-white-100)' }}
                        onClick={() => handleUpdateStatus("rejected")}
                        loading={loading}
                        className="md-fs__15  opacity__white__100 pl__32 pr__32 pb__12 pt__12 radi__24 b__1__white100 min__w__200 text__center sm-min__w__full cursor__p"
                      >
                        Reject
                      </LoadingButton>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3, cursor: 'pointer', border: '1px solid var(--opacity-white-100)', padding: '12px 32px', minWidth: "200px", background: 'var(--opacity-white-100)', borderRadius: '60px', color: 'var(--solid-primary-100)' }}
                        onClick={() => handleUpdateStatus("success")}
                        loading={loading1}
                        className="md-fs__15  opacity__white__100 pl__32 pr__32 pb__12 pt__12 radi__24 b__1__white100 min__w__200 text__center sm-min__w__full cursor__p"
                      >
                        Accept
                      </LoadingButton>
                    </>
                  )}



                  {/* <span
              className="md-fs__15 back__white__100 solid__primary__100 pl__32 pr__32 pb__12 pt__12 radi__24 min__w__200 text__center sm-min__w__full cursor__p"
              onClick={() => handleUpdateStatus("success")}
            >
              Accept
            </span> */}
                </div>
              )}
          </div>
        </div>
      }
    </>
  );
};
export default LeadOffer;
