import React, { useState } from "react";
import {
  CalenderAddIcon,
  CalenderRemoveIcon,
  UserIcon,
} from "../../assets/Icons/AllIcons";
import { useTranslation } from "react-i18next";

const LeadsPageCard = ({
  heading,
  subHeading,
  type,
  status,
  createdDate,
  expiryDate,
  user,
  buttonText,
  data,
  onClick = () => {},
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const formateCreatedDate = new Date(createdDate).toLocaleDateString();
  const formateExpiryDate = new Date(expiryDate).toLocaleDateString();
  const openModal = () => {
    setModalOpen(true);
  };

  return (
    <div className="flex flex-col gap-6 rounded-lg border border-[#E5E5E5] p-4">
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-1">
          <span className="text-lg font-semibold text-[#333] capitalize">
            {heading}
          </span>
          <span className="text-sm text-[#333] capitalize">{subHeading}</span>
        </div>
        <div className="flex items-center justify-between gap-1.5">
          <div className="flex items-center gap-1.5">
            <span className="relative flex h-2 w-2">
              <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-[#FFA671] opacity-75"></span>
              <span className="relative inline-flex h-2 w-2 rounded-full bg-[#FFA671]"></span>
            </span>
            <span className="text-sm text-[#666666]">
              {t("lead_card.status")}:{" "}
              <span className="text-[#FFA671] capitalize">{status}</span>
            </span>
          </div>
          <span className="text-sm text-[#666666]">
            {t("lead_card.type")}:{" "}
            <span className="text-[#4A6DA7] capitalize">{type}</span>
          </span>
        </div>
      </div>
      <hr className="border-[#E5E5E5]" />
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-1.5">
          <CalenderAddIcon />
          <span className="text-sm text-[#666]">
            {t("lead_card.created")}:{" "}
            <span className="text-[#4A6DA7] capitalize">
              {formateCreatedDate}
            </span>
          </span>
        </div>
        {expiryDate && (
          <div className="gap flex items-center gap-[6px]">
            <CalenderRemoveIcon iconColor={"#4A6DA7"} className={"w-[18px]"} />
            <span className="text-sm text-[#666]">
              {t("lead_card.expiry_date")}:{" "}
              <span className="text-[#4A6DA7] capitalize">
                {formateExpiryDate}
              </span>
            </span>
          </div>
        )}
        <div className="flex items-center gap-1.5">
          <UserIcon iconColor={"#4A6DA7"} className={"w-[18px]"} />
          <span className="text-sm text-[#666]">
            {t("lead_card.user")}:{" "}
            <span className="text-[#4A6DA7] capitalize">{user}</span>
          </span>
        </div>
      </div>
      <hr className="border-[#E5E5E5]" />
      <div className="flex w-full justify-end">
        <button
          onClick={onClick}
          className="w-fit rounded-full border border-[#FF6B13] px-5 py-1.5 text-sm text-[#FF6B13]"
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default LeadsPageCard;
