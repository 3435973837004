import React from "react";
import LeadLayout from "../../components/leadLayout/LeadLayout ";
import { useGetUserLeadsOfferContractQuery } from "../../redux/storeApis";
import LeadOfferCard from "../leadsPage/LeadsPageCard";
import Loader from "../../assets/Loader";
import { useTranslation } from "react-i18next";
const LeadContractPage = () => {
  const { data: userLeadContract, isLoading: isLoadingUserLeadContract } =
    useGetUserLeadsOfferContractQuery();
  const { t } = useTranslation();
  const userLeadContractData =
    userLeadContract?.data?.user_lead_offer_contracts;
  const handleOnClick = (slug) => {
    window.open(`https://new-yaksport.danzeetech.com/lead-contract/${slug}`);
  };
  return (
    <LeadLayout>
      <div className="flex flex-col gap-[32px] w-full min-h-[80vh]">
        <h1 className="text-center text-[32px] text-[#333333] font-[500]">
          <span className="text-[#4A6DA7]">
            {t("profile.lead_offer_contract")}
          </span>
        </h1>
        {isLoadingUserLeadContract ? (
          <Loader />
        ) : (
          <div className="grid grid-cols-3 max-[1024px]:grid-cols-2 max-sm:grid-cols-1 w-full gap-[16px]">
            {userLeadContractData?.map((data) => {
              console.log(data?.offer, "data?.offer");
              const { expired_at, created_at, lead } = data?.offer;
              const { club_name, lead_type, lead_user } = lead;
              const { first_name, last_name } = lead_user;
              return (
                <LeadOfferCard
                  key={data?.id}
                  heading={club_name}
                  subHeading={data?.hotel?.name}
                  type={lead_type}
                  status={data?.status}
                  createdDate={created_at}
                  expiryDate={expired_at}
                  user={`${first_name} ${last_name}`}
                  onClick={() => handleOnClick(data?.slug)}
                  buttonText={"View Contract"}
                />
              );
            })}
          </div>
        )}
      </div>
    </LeadLayout>
  );
};

export default LeadContractPage;
