import { useState, useEffect } from "react";
import { useGetHomePageDataQuery } from "../redux/storeApis";

export default function useHomePageDataManager() {
  const { data: allHomeData, isLoading : isLoadingAllHomeData } = useGetHomePageDataQuery();
// console.log(allHomeData, "allHomeData");
  const [homeData, setHomeData] = useState({
    airportsByCityIds: [],
    airportsByCountryIds: [],
    cities: [],
    cityCountArray: [],
    countries: [],
    countryCountArray: [],
    galleryImages: [],
    pages: [],
    popularSportsHotels: [],
    references: [],
    regionAirports: [],
    sports: [],
    regionAirports: [],
  });

  useEffect(() => {
    if (allHomeData) {
      setHomeData({
        airportsByCityIds: allHomeData?.data?.airtportsByCityIds || [],
        airportsByCountryIds: allHomeData?.data?.airtportsByCountryIds || [],
        cities: allHomeData?.data?.cities || [],
        cityCountArray: allHomeData?.data?.cityCountArray || [],
        countries: allHomeData?.data?.countries || [],
        countryCountArray: allHomeData?.data?.countryCountArray || [],
        galleryImages: allHomeData?.data?.galleryImages || [],
        pages: allHomeData?.data?.pages || [],
        popularSportsHotels: allHomeData?.data?.popularSportsHotels || [],
        references: allHomeData?.data?.references || [],
        regionAirports: allHomeData?.data?.region_airports || [],
        sports: allHomeData?.data?.sports || [],
        regionAirports: allHomeData?.data?.region_airports || [],
      });
    }
  }, [allHomeData]);

  const findHotelsCount = (dataArray, id) => {
    if (id) {
      const filteredData = dataArray?.find(
        (data) => data?.city_id == id || data?.country_id == id
      );
      return filteredData?.count || "0";
    } else {
      return "0";
    }
  };

  const findCityHotelsCount = (id) => {
    return findHotelsCount(homeData?.cityCountArray, id);
  };

  const findCountryHotelsCount = (id) => {
    return findHotelsCount(homeData?.countryCountArray, id);
  };

  return {
    ...homeData,
    isLoadingAllHomeData,
    findCityHotelsCount,
    findCountryHotelsCount,
  };
}