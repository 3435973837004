import React from 'react'

const computer__call = ({className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" className={className}>
            <path d="M17.666 1.6665H12.3327C7.96038 1.6665 5.77422 1.6665 4.22456 2.75159C3.65122 3.15304 3.15255 3.65171 2.7511 4.22505C1.66602 5.77471 1.66602 7.96086 1.66602 12.3332C1.66602 16.7055 1.66602 18.8916 2.7511 20.4413C3.15255 21.0146 3.65122 21.5133 4.22456 21.9147C5.77422 22.9998 7.96038 22.9998 12.3327 22.9998H17.666C22.0383 22.9998 24.2245 22.9998 25.7741 21.9147C26.3475 21.5133 26.8461 21.0146 27.2476 20.4413C28.3327 18.8916 28.3327 16.7055 28.3327 12.3332C28.3327 7.96086 28.3327 5.77471 27.2476 4.22505C26.8461 3.65171 26.3475 3.15304 25.7741 2.75159C24.2245 1.6665 22.0383 1.6665 17.666 1.6665Z" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" />
            <path d="M18.3327 28.3333L17.912 27.7749C16.9637 26.5159 16.7286 24.5925 17.3284 23M11.666 28.3333L12.0867 27.7749C13.035 26.5159 13.2701 24.5925 12.6703 23" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" />
            <path d="M8.33203 28.333H21.6654" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" />
            <path d="M9.66602 17.6667C12.1173 14.2062 17.8244 14.0173 20.3327 17.6667M17.666 9.66667C17.666 11.1394 16.4721 12.3333 14.9993 12.3333C13.5266 12.3333 12.3327 11.1394 12.3327 9.66667C12.3327 8.19391 13.5266 7 14.9993 7C16.4721 7 17.666 8.19391 17.666 9.66667Z" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" />
        </svg>
    )
}

export default computer__call