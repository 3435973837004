import React, { useEffect, useRef, useState } from "react";
import { getLocalStorage, setLocalStorage } from "../shared/local_storage";
import {
  useChangeRegionMutation,
  useGetRegionDataQuery,
} from "../../redux/storeApis";
import { KEYS, LANGUAGES_CODE } from "../../constant/Index";
import { useTranslation } from "react-i18next";
import useOutsideClick from "../../hooks/useOutsideClick";
function LangIcon({ userDropRef, showLanDrop, setShowLanDrop, placeuse }) {
  const languageModelRef = useRef(null);
  const { data: languages } = useGetRegionDataQuery();
  const [changeRegion] = useChangeRegionMutation();
  const { i18n } = useTranslation();
  const selectedLanguage = getLocalStorage(KEYS.lang);
  const [languagesData, setLanguagesData] = useState();
  useEffect(() => {
    if (selectedLanguage) {
      console.log(selectedLanguage);
    } else {
      setLocalStorage(KEYS.lang, LANGUAGES_CODE.Danish);
    }
  }, []);
  useEffect(() => {
    const languagesArray = Object?.values(languages?.data || {});
    if (Array.isArray(languagesArray)) {
      setLanguagesData(languages?.data?.regions);
    }
  }, [languages]);
  const toggleLanDrop = () => {
    setShowLanDrop(!showLanDrop);
  };
  const handleClick = (lang) => {
    i18n.changeLanguage(lang?.language_symbol);
    setLocalStorage(KEYS.lang, lang?.language_symbol);
    window.location.reload();
  };
  useOutsideClick(languageModelRef, () => setShowLanDrop(false));
  return (
    <div className="d__flex flex__col" style={{ position: "relative" }}>
      <i
        onClick={toggleLanDrop}
        className="fs__24 solid__black__60 bx bx-world cursor__p"
      ></i>
      {showLanDrop && (
        <div
          ref={languageModelRef}
          className="d__flex flex__col gap__8"
          style={{
            position: "absolute",
            top: "110%",
            background: "#FFF",
            zIndex: "1",
            padding: "16px",
            borderRadius: "10px",
            boxShadow: "0 4px 20px 0px rgba(0, 0, 0, .08)",
            width: "200px",
          }}
        >
          {languagesData?.map((language) => {
            return (
              <button
                onClick={() =>
                  handleClick({
                    language: language?.language,
                    language_symbol: language?.name,
                  })
                }
                style={{
                  color:
                    selectedLanguage == language?.name ? "#333" : "#4A6DA7",
                  width: "100%",
                  textAlign: "start",
                  userSelect: "none",
                }}
              >
                {language?.language}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}
export default LangIcon;