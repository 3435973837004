import React from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
const ClimateChart = ({ data }) => {
  const chartData = {
    labels: data.labels,
    datasets: [
      {
        label: 'Day Temperature (°C)',
        data: data.day_temp,
        fill: true,
        borderColor: '#DBE2ED',
        tension: 0.1,
      },
      {
        label: 'Night Temperature (°C)',
        data: data.night_temp,
        fill: true,
        borderColor: '#B7C5DC',
        tension: 0.1,
      },
    ],
  };
  return (
    <div className='w__full'>
      <Line 
        datasetIdKey='id'
        data={chartData}
        className='w__full'
      />
    </div>
  );
};
export default ClimateChart;