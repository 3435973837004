// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "../constant/Index";
import {
  getLocalStorage,
  setLocalStorage,
} from "../components/shared/local_storage";
export const APIs_WITHOUT_LOADING = ["getAllOrders"];

const TAG_TYPES = {
  messages : "messages"
};

export const yakSportsApi = createApi({
  reducerPath: "yakSportsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: Config.serverApiUrl,
    tagTypes: [
      TAG_TYPES.messages,
    ],
    prepareHeaders: (headers) => {
      const language = getLocalStorage(Config.language);
      const langHeaderValue = language === "en-US" ? "en" : language;
      console.log(langHeaderValue, "language");

      if (language === "en-US") {
        setLocalStorage(Config.language, language);
      }
      headers.set(
        "Authorization",
        `Bearer ${getLocalStorage(Config.userToken)}`
      );
      // headers.set("Authorization", getLocalStorage(Config.userToken));
      headers.set("lang", langHeaderValue);
      // headers.set("Content-Type", "application/json");
      // headers.set("Accept", "application/json");

      return headers;
    },
  }),

  endpoints: (builder) => ({
    getMeData: builder.query({ query: () => `me` }),
    getHomePageData: builder.query({ query: (id) => `home-data` }),
    getRegionData: builder.query({ query: (id) => `web-regions` }),
    getSearchFiledData: builder.query({ query: () => `faa-tilbud` }),
    getReferencerData: builder.query({ query: () => `referencer` }),
    getAllHotels: builder.query({
      query: (data) =>
        `hotels?${data}`,
    }),

    getFilteredHotels : builder.query({ query : (data) => `ajax/hotels?${data}`}),

    getHotelDetail: builder.query({ query: (slug) => `hotel/${slug}` }),
    // addSearchHotels: builder.mutation({
    //   query: (data) => {
    //     return {
    //       url: `hotel`,
    //       method: "POST",
    //       body: data,
    //     };
    //   },
    // }),

    getMessages: builder.query({
      query: () => 'messages', // Replace with your API endpoint
      providesTags: () => [TAG_TYPES.messages],
    }),
    sendMessage: builder.mutation({
      query: (message) => ({
        url: 'messages', // Replace with your API endpoint
        method: 'POST',
        body: message,
      }),
      invalidatesTags: [TAG_TYPES.messages],
    }),

    
    getUnreadMessages: builder.query({
      query: () => 'get-unread-messages', // Replace with your API endpoint
      // providesTags: () => [TAG_TYPES.messages],
    }),

    getLeadOffer: builder.query({
      query: (slug) => `lead-offer-slug-update/${slug}`,
    }),
    getLeadContract: builder.query({
      query: (slug) => `lead-contract-slug-update/${slug}`,
    }),
    getAllOrders: builder.query({
      query: () => "all-orders-list",
      transformResponse: (response, meta, arg) => response,
    }),
    updateOrder: builder.mutation({
      query(body) {
        return { url: `admin-orders/${body?.id}`, method: "PATCH", body };
      },
    }),
    changeRegion: builder.mutation({
      query(body) {
        return { url: `change-region`, method: "POST", body };
      },
    }),
    acceptOrder: builder.mutation({
      query(body) {
        return { url: `orders-accept`, method: "POST", body };
      },
    }),
    addLead: builder.mutation({
      query(data) {
        return {
          url: `leads`,
          method: "POST",
          body: data,
        };
      },
    }),
    loginUser: builder.mutation({
      query(data) {
        return {
          url: `lead-user/login`,
          method: "POST",
          body: data,
        };
      },
    }),
    leadOfferStatus: builder.mutation({
      query(data) {
        return {
          url: `leadOffer/statusUpdate`,
          method: "POST",
          body: data,
        };
      },
    }),
    sendSubscriptionId: builder.mutation({
      query(body) {
        return { url: `add-admin-push-token`, method: "POST", body };
      },
    }),
    getContractPassenger: builder.query({
      query: (slug) => `contract-passenger/${slug}`,
    }),
    addContractPassenger: builder.mutation({
      query(data) {
        return {
          url: `store-passenger-list`,
          method: "POST",
          body: data,
        };
      },
    }),
    getOtpCode: builder.mutation({
      query({ slug, status }) {
        return {
          url: `send-otp-code/${slug}`,
          method: "POST",
        };
      },
    }),

    updateContractStatus: builder.mutation({
      query({ slug, data }) {
        return {
          url: `update-contract/${slug}`,
          method: "POST",
          body: data,
        };
      },
    }),

    forgotPassword: builder.mutation({
      query: (email) => ({
        url: "forgot-password",
        method: "POST",
        body: { email },
      }),
    }),

    verifyEmailCode: builder.mutation({
      query: (data) => ({
        url: "verify-email-code",
        method: "POST",
        body: data,
      }),
    }),

    setNewPassword: builder.mutation({
      query: (data) => ({
        url: "new-password",
        method: "POST",
        body: data,
      }),
    }),
    // user-leads
    getUserLeads: builder.query({
      query: () => "user-leads",
    }),
    getUserLeadsOffers: builder.query({
      query: () => "user-lead-offers",
    }),
    getUserLeadsOfferContract: builder.query({
      query: () => "user-lead-offer-contracts",
    }),
    updateLeadUser: builder.mutation({
      query: (data) => ({
        url: "update-lead-user",
        method: "POST",
        body: data,
      }),
    }),

    //FAQs Apis
    getFaqs : builder.query({  query: () => "faq", transformResponse:(response)=> response?.data }), 

  }),
});
export const {
  useGetMeDataQuery,
  useGetHomePageDataQuery,
  useGetRegionDataQuery,
  useGetAllHotelsQuery,
  useGetHotelDetailQuery,
  useGetMessagesQuery,
  useSendMessageMutation,
  useGetUnreadMessagesQuery,
  useGetLeadOfferQuery,
  useGetLeadContractQuery,
  useGetSearchFiledDataQuery,
  useAddLeadMutation,
  useLeadOfferStatusMutation,
  useChangeRegionMutation,
  useGetAllOrdersQuery,
  useUpdateOrderMutation,
  useAcceptOrderMutation,
  useSendSubscriptionIdMutation,
  useGetReferencerDataQuery,
  useGetContractPassengerQuery,
  useAddContractPassengerMutation,
  useLoginUserMutation,
  useGetOtpCodeMutation,
  useForgotPasswordMutation,
  useVerifyEmailCodeMutation,
  useSetNewPasswordMutation,
  useUpdateContractStatusMutation,
  useGetUserLeadsQuery,
  useGetUserLeadsOffersQuery,
  useGetUserLeadsOfferContractQuery,
  useUpdateLeadUserMutation,

  useGetFilteredHotelsQuery,

  //Faq Apis
  useGetFaqsQuery,

} = yakSportsApi;
