import React from "react";
import { DeleteIcon, FileIcon } from "../../assets/Icons/AllIcons";
import { Download } from "@mui/icons-material";
import FileCard from "./FileCard";

function SingleMessage({
  data,
  message = "Message Value will be here",
  date,
  from,
  isShowDate = false,
  attachedFiles = [],
}) {
  const formattedDate = new Date(date).toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  const linkify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text?.replace(
      urlRegex,
      (url) =>
        ` <a href="${url}" class="text-[var(--solid-blue-5)] underline" target="_blank" rel="noopener noreferrer" class="text-blue-500 underline">${url}</a> `
    );
  };
  const createMarkup = (text) => {
    return { __html: linkify(text) };
  };
  return (
    <div
      className={`${`flex flex-col gap-[6px]  w-full`} ${
        from === "user" ? " items-end " : " items-start "
      }`}
    >
      {message && <p
        className={` ${
          from === "user"
            ? "bg-[#4A6DA7] text-[#FFFFFF] rounded-tr-[0px] "
            : "bg-[#F3F6FB] text-[#333333] rounded-tl-[0px]"
        } text-[14px] px-[24px] py-[16px]  rounded-[20px] max-w-[460px] `}
        dangerouslySetInnerHTML={createMarkup(message)}
      ></p>}
      {attachedFiles?.length > 0 &&
        attachedFiles?.map((data, index) => {
          return (
            <FileCard isUser={from === "user"} key={index} data={data} index={index} />
          );
        })}
      {isShowDate && (
        <span className="text-[#CCCCCC] text-[12px]">{formattedDate}</span>
      )}
    </div>
  );
}
export default SingleMessage;