import Image from "../../../components/shared/image/Image.js"
import React from 'react'
import styles from './Hero.css';
import FeatureSection from '../featureSection/index.js'
import SearchFilter from './SearchFilter/SearchFilter.jsx'
import { useTranslation } from 'react-i18next';
import DZText from "../../text/DZText.js";
const Hero = () => {
    const { t } = useTranslation();
    return (
        <div className='header__section section'>
            <div className="hero__section">
                <div className="hero__content">
                    <div className="hero__top">
                        <h1 className="heading">
                            {t('hero.heading1')} 
                            &nbsp;
                            <DZText>{'hero.heading2'}</DZText>
                        </h1>
                        <DZText className={'paragraph'}>{'hero.detail'}</DZText>
                    </div>
                    <div className="invisible"></div>
                    <SearchFilter />
                </div>
                <div className="hero__img">
                    <div className="img">
                        <Image className='' src="/images/home/hero/hero1.png" width={400} height={500} alt="" />
                    </div>
                    <div className="img">
                        <Image className='' src="/images/home/hero/hero2.png" width={400} height={500} alt="" />
                    </div>
                </div>
            </div>
            <FeatureSection />
        </div>
    )
}
export default Hero