import React from 'react'

const SportBlue = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
            <path d="M1 5.57143C1 3.41644 1 2.33894 1.58579 1.66947C2.17157 1 3.11438 1 5 1H17C18.8856 1 19.8284 1 20.4142 1.66947C21 2.33894 21 3.41644 21 5.57143V12.4286C21 14.5836 21 15.6611 20.4142 16.3305C19.8284 17 18.8856 17 17 17H5C3.11438 17 2.17157 17 1.58579 16.3305C1 15.6611 1 14.5836 1 12.4286V5.57143Z" stroke="#4A6DA7" stroke-width="1.5" stroke-linejoin="round" />
            <circle cx="11" cy="9" r="2" stroke="#4A6DA7" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M11 7V2M11 11V16" stroke="#4A6DA7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M21 6H18.5C17.9477 6 17.5 6.44772 17.5 7V11C17.5 11.5523 17.9477 12 18.5 12H21" stroke="#4A6DA7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1 6H3.5C4.05228 6 4.5 6.44772 4.5 7V11C4.5 11.5523 4.05228 12 3.5 12H1" stroke="#4A6DA7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default SportBlue