import React from 'react'
import MetaTags from '../../components/SEO/MetaTags';
import PAGE_URLS from '../../constant/Index';

function About() {

  const description = `
  Learn about YakSport, the leading Danish travel agency specializing in training camps for clubs, associations, and schools. 
  With over 15 years of experience, we prioritize excellent communication, transparent pricing, and high-standard accommodations. 
  Trust YakSport for unforgettable training trips to Southern Europe and beyond.
  `
 

  return (
    <div className='d__flex flex__col gap__20 section'>
      <MetaTags href={"about"} pageName={"about"} content={description} metaTitle={"About YakSport: Danish Travel Agency for Training Camps"} />

      <h1 className="section__heading" style={{color: 'var(--solid-primary-100)'}}>
        Om Yak Sport
      </h1>

      <p className="paragraph">
        Vi er et dansk rejsebureau med speciale inden for træningslejre til danske klubber, foreninger og skoler. Hvert år sender vi hundredvis af danske sportsentusiaster til Sydeuropa og resten af verden – hvor den gode oplevelse er fællestræk for alle vores rejser.
      </p>

      <p className="paragraph" style={{ textDecoration: 'underline', color: 'var(--solid-primary-100)', fontWeight: '500'}}>
        Hos Yak Sport sætter vi fokus på:
      </p>

      <div className="d__flex flex__col gap__12">

        <p className="paragraph">
          God kommunikation med kunden – Hurtig responstid på mail og telefon.
        </p>

        <p className="paragraph">
          Gennemsigtige priser - Hvor alle kan være med og ingen pludselige ekstra regninger til kunden.
        </p>

        <p className="paragraph"> 
          Hoteller og træningsfaciliteter der lever op til den høje standard vores kunder efterspørger.
        </p>

      </div>

      <p className="paragraph">
        Et træningsophold til Sydeuropa vil være et ideelt valg til at kickstarte sæsonen, hvor truppen kun skal fokusere på træningspas, personlig udvikling og sammenhold – Og det skal selvfølgelig også være sjovt!
      </p>

      <p className="paragraph" style={{ textDecoration: 'underline', color: 'var(--solid-primary-100)', fontWeight: '500'}}>
        Over 15 års erfaring med træningslejre:
      </p>

      <p className="paragraph">
        Vi har det helt rigtige know how inden for træningslejre, og sammensætter rejsen efter gruppens specifikke ønsker og behov. Hele pakken er bare bedst hos Yak Sport, og derfor genbestiller vores kunder atter og atter hos os. Du kan være helt tryg når du bestiller din rejse igennem Yak Sport, vi er nemlig medlem af rejsegarantifonden (reg. nr. 2848), som betyder at i er sikret i mod enhver tab.
      </p>

      <p className="paragraph">
        Send en rejseforespørgsel og lad os hjælpe jer med jeres næste træningslejr.
      </p>

    </div>
  )
}

export default About;