"use client";
import React, { useDebugValue, useEffect, useRef, useState } from "react";
import { useGetAllHotelsQuery, useGetFilteredHotelsQuery } from "../../redux/storeApis";
import HotelCard from "../../components/shared/hotelCard/HotelCard";
import "./hotels.css";
import PAGE_URLS, { Config, KEYS } from "../../constant/Index";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Loader from "../../assets/Loader";
import MetaTags from "../../components/SEO/MetaTags";
import { getLocalStorage, setLocalStorage } from "../../components/shared/local_storage";
import DZText from "../../components/text/DZText";
import { FlightDownIcon, FlightUpIcon } from "../../assets/Icons/AllIcons";
import { useSelector } from "react-redux";
import { selectedIsLoading } from "../../redux/LoaderReducer";
import { LoadingAnimation } from "../../assets/Animations/LoadingAnimation";

const Hotels = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const searchFilteredSlug = location?.search?.replace("?", "");

  const modalRef = useRef(null);
  const showLoader  = useSelector(selectedIsLoading);

  const activeBtnClassName = 'text-[var(--solid-primary-5)] px-[20px] py-[12px] bg-[var(--solid-primary-100)] rounded-[16px] cursor-pointer select-none';
  const inActiveBtnClassName = 'text-[var(--solid-primary-100)] px-[20px] py-[12px] bg-[var(--solid-primary-5)] rounded-[16px] cursor-pointer select-none';

  const searchFilteredData = JSON.parse(getLocalStorage('searchField'));

  const from_date = Config.fn_date_yyyy_mm_dd(searchFilteredData?.from_date);
  const to_date = Config.fn_date_yyyy_mm_dd(searchFilteredData?.to_date);

  const description = `
  Discover our handpicked selection of premier hotels for your training camp or vacation. 
  From luxurious resorts like The Sense Deluxe and Club Mega Saray in Antalya, Turkey, 
  to the stunning Aliathon Resort in Nicosia, Cyprus, find the perfect accommodation for your next trip with YakSport.
  `;

  const { data: hotel_data, isLoading: isLoadingHotelData } = useGetAllHotelsQuery();

  console.log('searchFilteredSlug', searchFilteredSlug)
  
  const [allHotels, setAllHotels] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilterModal, setShowFilterModal] = useState(false);
  
  const [selectedData, setSelectedData] = useState({stars : false, sport_ids : [], city_ids : []});

  const constructQueryString = (params) => {
    const queryString = Object?.keys(params)?.map(key => {
        if (Array.isArray(params[key])) {
          return params[key]?.map(item => `${key}[]=${item}`)?.join('&');
        }
        return `${key}=${params[key]}`;
      })?.join('&');
    return queryString;
  };

  const [selectedHotelSlug, setSelectedHotelSlug] = useState(null);

  const { data : filteredHotels, isLoading : isLoadingFilteredHotels } = useGetFilteredHotelsQuery(selectedHotelSlug??searchFilteredSlug);

  useEffect(() => { setAllHotels(filteredHotels?.data?.hotels); }, [filteredHotels]);

  console.log(filteredHotels)

  console.log('city id', selectedData?.city_ids);
  console.log('slug city id', searchFilteredSlug);

  useEffect(() => { 
    fnSearchSelectedFilterModal();
  },[selectedData]);

  useEffect(() => { 
    setSelectedHotelSlug(searchFilteredSlug);
  },[searchFilteredSlug, showLoader]);

  const hotelsPerPage = 8;
  const indexOfLastHotel = currentPage * hotelsPerPage;
  const indexOfFirstHotel = indexOfLastHotel - hotelsPerPage;
  const currentHotels = allHotels?.slice(indexOfFirstHotel, indexOfLastHotel);
  const totalPages =  Math?.ceil(allHotels?.length / hotelsPerPage);

  useEffect(() => {
    if(showFilterModal) {
     const handleOutsideClick = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) { setShowFilterModal(false); }
     };
     document.addEventListener('mousedown', handleOutsideClick);

     return () => { document.removeEventListener('mousedown', handleOutsideClick); };
    }
 }, [showFilterModal]);

  const handlePageChange = (newPage) => { setCurrentPage(newPage); };

  const fnSearchSelectedFilterModal =()=> {
    const changeDataFormat = constructQueryString({ stars: selectedData?.stars, sport_ids: selectedData?.sport_ids, city_ids: selectedData?.city_ids, from_date, to_date  });
    if((selectedData?.stars || selectedData?.city_ids?.length > 0 || selectedData?.sport_ids?.length > 0)) {
      setSelectedHotelSlug(changeDataFormat); 
    }
  };

  const fnOnClickSport = (sport) => {
    setSelectedData(prevSelectedData => {
      const sportIds = prevSelectedData?.sport_ids;
      const isAlreadySelectedSport = sportIds?.find(id => id == sport?.id);

      if (isAlreadySelectedSport) {
        const filterSportIds = sportIds?.filter(id => id !== sport?.id);
        const filteredData = { ...prevSelectedData, sport_ids: filterSportIds };
        return filteredData;
      } else {
        const newData = { ...prevSelectedData, sport_ids: [...sportIds, sport?.id] };
        return newData;
      }
    });
  };

  const fnOnClickCity = (city) => {
    setSelectedData(prevSelectedData => {
      const cityIds = prevSelectedData?.city_ids;
      const isAlreadySelectedCity = cityIds?.find(id => id == city?.id);

      if (isAlreadySelectedCity) {
        const filterCityIds = cityIds?.filter(id => id !== city?.id);
        const filteredData = { ...prevSelectedData, city_ids: filterCityIds };
        return filteredData;
      } else {
        const newData = { ...prevSelectedData, city_ids: [...cityIds, city?.id] };
        return newData;
      }
    });
  };

  return (
    
    <div className="hotel__page">
      <MetaTags
        href={"hotels"}
        pageName={"hotels"}
        content={description}
        metaTitle={
          "Training Camps for Everyone | Danish Travel Agency - YakSport"
        }
      />

      <div className="page__header section relative">
        <h1 className="poppins__semiBold__24">{t("hotels.heading")}</h1>

        <span onClick={()=> setShowFilterModal(!showFilterModal)} className="poppins__semiBold__16">{t("hotels.filter")}</span>

        { showFilterModal && 
          <div ref={modalRef} className="bg-[#FFF] absolute top-[100%] right-0 shadow-2xl shadow-[#4A6DA730] flex items-end gap-[24px] rounded-[16px] max-sm:w-[360px] sm:min-w-[460px] sm: flex-col bg-[#FFF] p-[24px] z-[1]">
            <div className="flex flex-col gap-[12px] w-full">
              <span className="text-[20px] text-[var(--solid-primary-100)]">
                Rank
              </span>
              <div className="flex flex-wrap w-full gap-[16px]">
                <span 
                  onClick={()=> {
                    const selectFilterData = {...selectedData, stars : !selectedData?.stars};
                    setLocalStorage(KEYS.search_by_filter, selectFilterData);
                    setSelectedData(selectFilterData);
                  }} 
                  className={selectedData?.stars ? activeBtnClassName : inActiveBtnClassName}
                >
                  5 Star
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-[12px] w-full">
              <span className="text-[20px] text-[var(--solid-primary-100)]">
                Sport
              </span>
              <div className="flex flex-wrap w-full gap-[16px]">
                {hotel_data?.data?.sports?.map((sport)=> {
                  const isSelectedSport = selectedData?.sport_ids?.find((id)=> id == sport?.id);
                  return (
                    <span key={sport?.id} onClick={()=> fnOnClickSport(sport)} className={isSelectedSport ? activeBtnClassName : inActiveBtnClassName}>
                      <DZText>{sport?.name}</DZText>
                    </span>
                  )
                })}
              </div>
            </div>
            <div className="flex flex-col gap-[12px] w-full">
              <span className="text-[20px] text-[var(--solid-primary-100)]">
                Destinations
              </span>
              <div className="flex flex-wrap w-full gap-[16px]">
                {hotel_data?.data?.cities?.map((city)=>{
                  const isSelectedCity = selectedData?.city_ids?.find((id)=> id == city?.id);
                  console.log('city =========>', city)
                  return (
                    <span key={city?.id} onClick={()=> fnOnClickCity(city)} className={isSelectedCity ? activeBtnClassName : inActiveBtnClassName}>
                      <DZText>{city?.name}</DZText> | <DZText>{city?.country?.name}</DZText>
                    </span>
                  )
                })}
              </div>
            </div>
            <button 
              onClick={()=> {
                setShowFilterModal(false); 
                fnSearchSelectedFilterModal();
              }} 
              className="w-fit bg-[var(--solid-orange-100)] px-[24px] py-[12px] text-[var(--solid-black-5)] rounded-[16px] text-center "
            >
              Show Result
            </button>
          </div>
        }
      </div>
      
     { (searchFilteredData?.from_date && searchFilteredData?.to_date) &&  <span className="text-[#333] flex items-center gap-[12px] w-full section">
        <FlightUpIcon/>
        <span className="text-[#4A6DA7]">
            {`${Config.fnMultilingualDate(searchFilteredData?.from_date)}`}
        </span>
        - 
        <span className="text-[#4A6DA7]">
            {` ${Config.fnMultilingualDate(searchFilteredData?.to_date)}`}
        </span>
        <FlightDownIcon/>
      </span>}

      <div className="cards__container section grid grid-cols-4 max-lg:grid-cols-2 max-sm:grid-cols-1">
        {
          isLoadingFilteredHotels ? ( !showLoader && <Loader className="col-start-1 col-end-5" /> )
          : currentHotels?.length > 0 ? currentHotels?.map((hotel, index) => <HotelCard key={index} hotel={hotel} hotelPage={true} />)
          :  <LoadingAnimation containerClassName={'col-start-1 col-end-5'} />
        }
      </div>
      { currentHotels?.length > 0 && <div className="pagination__container section">
        <span
          className={`pagination__arrow pagination__text ${
            currentPage === 1 ? "low__opacity" : ""
          }`}
          onClick={() => {
            if (currentPage !== 1) {
              handlePageChange(currentPage - 1);
            }
          }}
        >
          « {t("hotels.previous")}
        </span>
        {Array.from({ length: totalPages }, (_, index) => (
          <span
            key={index}
            className={`pagination__number ${
              currentPage === index + 1 ? "active__pagination" : ""
            }`}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </span>
        ))}
        <span
          className={`pagination__arrow pagination__text ${
            currentPage === totalPages ? "low__opacity" : ""
          }`}
          onClick={() => {
            if (currentPage < totalPages) {
              handlePageChange(currentPage + 1);
            }
          }}
        >
          {t("hotels.next")} »
        </span>
      </div> }
    </div>
  );
};
export default Hotels;