import React from "react";
import DZText from "../../text/DZText";
import { useTranslation } from "react-i18next";

function ViewLeadModal({ onClose, data }) {

  const { t } = useTranslation();

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#00000020]">
      <div className="grid grid-cols-2 gap-[24px] rounded-[24px] bg-[#FFF] p-[24px] max-sm:m-[16px] sm:w-[460px] md:w-[680px]">
        <div className="flex flex-col gap-[4px]">
          <DZText className="text-[16px] text-[#666] max-sm:text-[12px]">
            {t("lead_modal.lead_type")}
          </DZText>
          <span className="text-[20px] font-[500] text-[#4A6DA7] max-sm:text-[16px]">
            {data?.lead_type}
          </span>
        </div>
        <div className="flex flex-col gap-[4px]">
          <span className="text-[16px] text-[#666] max-sm:text-[12px]">
            {t("lead_modal.sport")}
          </span>
          <DZText className="text-[20px] font-[500] text-[#4A6DA7] max-sm:text-[16px]">
            {data?.sport?.name}
          </DZText>
        </div>
        {data?.hotel?.name && (
          <div className="flex flex-col gap-[4px]">
            <span className="text-[16px] text-[#666] max-sm:text-[12px]">
              {t("lead_modal.hotel")}
            </span>
            <span className="text-[20px] font-[500] text-[#4A6DA7] max-sm:text-[16px]">
              {data?.hotel?.name}
            </span>
          </div>
        )}
        <div className="flex flex-col gap-[4px]">
          <span className="text-[16px] text-[#666] max-sm:text-[12px]">
            {t("lead_modal.country")}
          </span>
          <DZText className="text-[20px] font-[500] text-[#4A6DA7] max-sm:text-[16px]">
            {data?.country?.name}
          </DZText>
        </div>
        <div className="flex flex-col gap-[4px]">
          <span className="text-[16px] text-[#666] max-sm:text-[12px]">
            {t("lead_modal.guests")}
          </span>
          <span className="text-[20px] font-[500] text-[#4A6DA7] max-sm:text-[16px]">
            {data?.no_guests}
          </span>
        </div>
        <div className="flex flex-col gap-[4px]">
          <span className="text-[16px] text-[#666] max-sm:text-[12px]">
            {t("lead_modal.from_-_to")}
          </span>
          { data?.from_date &&  data?.to_date && <span className="text-[20px] font-[500] text-[#4A6DA7] max-sm:text-[16px]">{`${data?.from_date} - ${data?.to_date}`}</span>}
        </div>
        <div className="flex flex-col gap-[4px]">
          <span className="text-[16px] text-[#666] max-sm:text-[12px]">
            {t("lead_modal.airport")}
          </span>
          <span className="text-[20px] font-[500] text-[#4A6DA7] max-sm:text-[16px]">
            {data?.airport?.name}
          </span>
        </div>
        <div className="flex flex-col gap-[4px]">
          <span className="text-[16px] text-[#666] max-sm:text-[12px]">
            {t("lead_modal.club_name")}
          </span>
          <span className="text-[20px] font-[500] text-[#4A6DA7] max-sm:text-[16px]">
            {data?.club_name}
          </span>
        </div>
        <div className="flex flex-col gap-[4px]">
          <span className="text-[16px] text-[#666] max-sm:text-[12px]">
            {t("lead_modal.city")}
          </span>
          <DZText className="text-[20px] font-[500] text-[#4A6DA7] max-sm:text-[16px]">
            {data?.city?.name}
          </DZText>
        </div>
        <div className="flex flex-col gap-[4px]">
          <span className="text-[16px] text-[#666] max-sm:text-[12px]">
            {t("lead_modal.budget")}
          </span>
          <span className="text-[20px] font-[500] text-[#4A6DA7] max-sm:text-[16px]">
            {data?.budget}
          </span>
        </div>
        <button
          onClick={() => onClose()}
          className="bg-[var(--solid-primary-100)] text-[#FFF] w-full col-span-2 p-[12px] rounded-[12px]"
        >
          {t("lead_modal.close")}
        </button>
      </div>
    </div>
  );
}

export default ViewLeadModal;
