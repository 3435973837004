"use client";
import React from "react";
import "./HotelCard.css";
import FlightBlue from "../../../assets/Icons/flight__blue";
import CheckboxBlack from "../../../assets/Icons/checkbox__black";
import { Link, useLocation } from "react-router-dom";
import YAKText from "../../../components/shared/YakText/YAKText";
import { Config } from "../../../constant/Index";
import NoImage from "../../../assets/Images/noImage.jpg";
import RoutesLink from "../../../constant/RoutesLink";
import DZText from "../../text/DZText";
import { useTranslation } from "react-i18next";
import { useGetRegionDataQuery } from "../../../redux/storeApis";
import { getLocalStorage } from "../local_storage";
const HotelCard = ({ hotel, hotelPage, style, guests, showPriceSection = true }) => {
const location = useLocation();
const { data: languages } = useGetRegionDataQuery();
const language = getLocalStorage(Config.language)
const region=languages?.data?.regions?.find(region => region.name === language);
  const params = new URLSearchParams(location.search);
  guests= params.get('no_persons');

console.log(region,"sdsd");
  const { hotel_attributes, city, country, hotel_airports, images } = hotel;
  const defaultAirport = hotel_airports?.find((airport) => airport?.default == 1)?.airport?.name;
  const frontImageLink = images?.find((image) => image?.sports_id == null)?.image;
  const { t } = useTranslation();

  const hotelStyle = {
    width: "100%"
  }
  const formatPrice = (price) => {
    if (price === null || price === 0) {
        return "Ask For Price";
    }
    return parseFloat(price).toString();
};
const hotelPrice = hotel?.hotel_total_price ? parseFloat(hotel.hotel_total_price) : 0;
const flightPrice = hotel?.flight_total_price ? parseFloat(hotel.flight_total_price) : 0;
const totalPrice = hotelPrice + flightPrice;
  return (
    <Link to={`${RoutesLink.HOTEL_DETAIL_USE}/${encodeURIComponent(hotel?.slug)}`}
      className={` cursor__p ${hotelPage ? "hotel__pageCard" : "w-full max-w-[400px]"
        } hotel__card `}
      // style={style + { width: '100%' }}
      style={hotelStyle}
    >
      <img
        src={
          frontImageLink ? Config?.hotelSportImage + frontImageLink : NoImage
        }
        className="hotel__img"
        alt=""
      />
      <div className="hotel__content">
        <div className="content__header">
          <DZText className={'poppins__semiBold__16'} >{hotel?.name}</DZText>
          <span className="poppins__14 location" >
            <DZText className={"poppins__14 location"}>{city?.name}</DZText>,
            <DZText className={'"poppins__14 location"'} >{country?.name}</DZText>
          </span>
          <span className="poppins__14 d__flex align__center gap__4 flex__wrap">
            <FlightBlue />
            {defaultAirport && (
              <DZText className={'"poppins__14 align__center g__12"'}>{defaultAirport}</DZText>
            )}
            ,
            {hotel_airports
              ?.filter((airport) => airport?.airport?.name !== defaultAirport)
              ?.map((airport, index) => (
                <DZText key={index} className={'"poppins__14 align__center g__12"'}>{airport?.airport?.name}</DZText>
              ))}
          </span>
        </div>
        <hr />
        <div className="hotel__points">
          {hotel_attributes?.slice(0, 4).map((attribute) => {
            return (
              <div key={attribute} className="point__li g__12 d__flex gap__8">
                <CheckboxBlack />
                <DZText className={'poppins__14'} >{attribute?.name}</DZText>
              </div>
            );
          })}
        </div>
        <hr />
        <Link
          to={`${RoutesLink.HOTEL_DETAIL_USE}/${encodeURIComponent(hotel?.slug)}`}
          className="sec__btn card__btn"
        >
          <DZText>{'hotel_card.learn_more'}</DZText>
        </Link>
        { showPriceSection && <div className="flex items-center justify-between w-full">
          <DZText className={'font-[500] text-[var(--solid-primary-100)]'} children=  {hotel?.hotel_total_price == null || hotel?.hotel_total_price == 0 ? t("hotel_card.ask_for_price") :  `${t("hotel_card.total")} : ${(totalPrice * region?.currency_factor).toFixed(2)} ${region?.short_currency_symbol} `} /> 
          <p className={'font-[500] text-[var(--solid-black-60)]'}>{`${guests==null?'':guests} ${t("hotel_card.guest")}`}</p>
        </div>}
      </div>
    </Link>
  ); 
};
export default HotelCard;