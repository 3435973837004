import React from "react";
import LeadLayout from "../../components/leadLayout/LeadLayout ";
import { useGetUserLeadsOffersQuery } from "../../redux/storeApis";
import LeadOfferCard from "../leadsPage/LeadsPageCard";
import Loader from "../../assets/Loader";
import { useTranslation } from "react-i18next";
const LeadOffersPage = () => {
  const { t } = useTranslation();
  const { data: userLeadsOffers, isLoading: isLoadingUserLeadsOffer } =
    useGetUserLeadsOffersQuery();
  const userLeadsOffersData = userLeadsOffers?.data?.user_lead_offers;

  const handleOnClick = (slug) => {
    window.open(`https://new-yaksport.danzeetech.com/lead-offer/${slug}`);
  };

  return (
    <LeadLayout>
      <div className="flex flex-col gap-[32px] w-full  min-h-[80vh]">
        <h1 className="text-center text-[32px] text-[#333333] font-[500]">
          <span className="text-[#4A6DA7]">{t("profile.leads_offer")}</span>
        </h1>
        {isLoadingUserLeadsOffer ? (
          <Loader />
        ) : (
          <div className="grid grid-cols-3 max-[1024px]:grid-cols-2 max-sm:grid-cols-1 w-full gap-[16px]">
            {userLeadsOffersData?.map((data) => (
              <LeadOfferCard
                key={data?.id}
                heading={data?.lead?.club_name}
                subHeading={data?.hotel?.name}
                type={data?.lead?.lead_type}
                status={data?.status}
                createdDate={data?.created_at}
                expiryDate={data?.expired_at}
                user={`${data?.lead?.lead_user?.first_name} ${data?.lead?.lead_user?.last_name}`}
                onClick={() => handleOnClick(data?.slug)}
                buttonText={"View Offer"}
              />
            ))}
          </div>
        )}
      </div>
    </LeadLayout>
  );
};

export default LeadOffersPage;
