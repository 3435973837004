import React, { useState } from "react";
import { DeleteIcon, FileIcon } from "../../assets/Icons/AllIcons";
import { Download } from "@mui/icons-material";
import { Config } from "../../constant/Index";

function FileCard({ data, index, isUser, actionType = "download", handleDeleteFile }) {
  const filePath = `${Config.messageFile}${data?.file_path}`;
  const fileType = data?.file_path?.split(".").pop().toLowerCase();
  const imageTypes = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
  const [isDownloading, setIsDownloading] = useState(false);

  // const handleDownload = async () => {
  //   try {
  //     const response = await fetch(filePath);
  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", data?.file_path?.split("/").pop());
  //     document.body.appendChild(link);
  //     link.click();
  //     link.parentNode.removeChild(link);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error("Error downloading the file:", error);
  //   }
  // };

  
  const handleDownload = (folder) => {
    // setIsDownloading(true);
    const folderName = encodeURIComponent(folder?.name);
    // const fileUrl = `${Config.baseCorsProductUrl}${pathForFile}/${folderName}`;
    const updateUrl = filePath.replace(/%2F/g, "/");
    const fileName = folder?.name;

    fetch(updateUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filePath);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error("Error downloading file:", error))
      // .finally(() => setIsDownloading(false));
  };
  // const handleDownload = async () => {
  //   try {
  //     console.log("Starting download:", filePath);
  //     const response = await fetch(filePath, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'image/png',
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error(`Network response was not ok. Status: ${response.status}`);
  //     }

  //     const blob = await response.blob();
  //     console.log("Blob created:", blob); 
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", data?.file_path?.split("/").pop() || 'downloaded-file');
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     window.URL.revokeObjectURL(url);
  //     console.log("Download successful"); 
  //   } catch (error) {
  //     console.error("Error downloading the file:", error);
  //   }
  // };

  console.log('file path', filePath);

  return (
    <div
      key={index}
      className={`relative flex flex-col items-center gap-[6px] rounded-[8px] min-w-[160px]  max-w-[160px]  p-[24px] ${isUser ? "bg-[#4A6DA7]" : "bg-[#F6F8FB]"
        }`}
    >
      {actionType == "download" ? (
        <a href={filePath} target="_blank" rel="noopener noreferrer" >
        <Download
          className={"cursor-pointer absolute right-[10px] top-[10px]"}
          sx={{ color: `${isUser ? "#F6F8FB" : "#4A6DA7"}` }}
          onClick={handleDownload}
        />
        </a>
      ) : (
        <DeleteIcon
          onClick={() => handleDeleteFile(index)}
          iconColor={isUser ? "#C44D56" : "#C44D56"}
          className={"cursor-pointer absolute right-[10px] top-[10px]"}
        />
      )}

      {imageTypes.includes(fileType) ? (
        <img src={filePath} alt="noImage" className="w-full h-full rounded" />
      ) : (
        <FileIcon
          className={"w-[46px] h-[46px]"}
          iconColor={isUser ? "#F6F8FB" : "#4A6DA7"}
        />
      )}
      <span
        className={`text-[14px] ${isUser ? "text-[#FFFFFF]" : "text-[#333333]"
          } truncate w-full text-center`}
      >
        {data?.name || data?.file_path?.split("/")?.pop()}
      </span>
    </div>
  );
}

export default FileCard;
