import React, { useState } from "react";
import ArrowBlack from "../../../assets/Icons/arrow__black";
import CheckBoxBlack from "../../../assets/Icons/checkbox__black";
import YAKText from "../../shared/YakText/YAKText";
import DZText from "../../text/DZText";

function ServicesCard({ heading, icon, services }) {
  const [servicesPosition, setServicesPosition] = useState(false);
  const toggleServicesPosition = () => {
    setServicesPosition(!servicesPosition);
  };
  return (
    <div className="d__flex flex__col align__center gap__24 p__24 b__1__black10 radi__12">
      <div
        className="w__full d__flex align__center justify__between cursor__p"
        onClick={toggleServicesPosition}
      >
        <div className="d__flex align__center gap__16  cursor__p">
          {icon}
          <YAKText text={heading} className="fs__22 fw__500 solid__black__80 md-fs__20 capitalize" />
        </div>
        <span style={{ transform: `${servicesPosition? "rotate(180deg)" : ""}`  }}>
        <ArrowBlack
          className="w__18 h__18 cursor__p"
        />
        </span>
      </div>
      {servicesPosition ? (
        <div className="d__flex align__start flex__col gap__16 w__full">
          {services?.map((service) => {
            return (
              <div className="d__flex align__center gap__12">
                <CheckBoxBlack />
                <span className="fs__16 solid__black__60 md-fs__14">
                  <DZText>{service?.title}</DZText>
                </span>
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ServicesCard;
