import React from "react";
import useLeadContract from "../../../hooks/useLeadContract";
import HotelBlue from "../../../assets/Icons/hotel__blue";
import BedBlue from "../../../assets/Icons/bed__blue";
import ClubBlue from "../../../assets/Icons/club__blue";
import UserMultiple from "../../../assets/Icons/user__multiple";
import { useTranslation } from "react-i18next";
function ContractDetail() {
  const { t } = useTranslation();
  const { clubName, hotelName, numberOfGuest } = useLeadContract();
  return (
    <div className="d__flex flex__col gap__24 w__full">
      <h2 className="fw__600 solid__black__80 fs__30 md-fs__26">
        {t("lead_offer_contract.contract_partial_hoists")}
      </h2>
      <div className="d__grid grid__tcol__2 gap__24 sm-grid__tcol__1">
        <div className="d__flex align__center gap__24 pl__24 pr__24 pb__20 pt__20 b__1__black10 radi__12">
          <ClubBlue className="min__w__32 min__h__32" />
          <div className="d__flex flex__col gap__4">
            <span className="fs__18 fw__600 solid__black__80">{clubName}</span>
            <span className="fs__16 solid__black__60 md-fs__14">{t("lead_offer_contract.club_name")}:</span>
          </div>
        </div>
        <div className="d__flex align__center gap__24 pl__24 pr__24 pb__20 pt__20 b__1__black10 radi__12">
          <HotelBlue />
          <div className="d__flex flex__col gap__4">
            <span className="fs__18 fw__600 solid__black__80">{hotelName}</span>
            <span className="fs__16 solid__black__60 md-fs__14">{t("lead_offer_contract.hotel_name")}</span>
          </div>
        </div>
        <div className="d__flex align__center gap__24 pl__24 pr__24 pb__20 pt__20 b__1__black10 radi__12">
          <BedBlue />
          <div className="d__flex flex__col gap__4">
            <span className="fs__18 fw__600 solid__black__80">{t("lead_offer_contract.all_inclusive")}</span>
            <span className="fs__16 solid__black__60 md-fs__14">{t("lead_offer_contract.accommodation")}</span>
          </div>
        </div>
        <div className="d__flex align__center gap__24 pl__24 pr__24 pb__20 pt__20 b__1__black10 radi__12">
          <UserMultiple className={"min__w__32 min__h__32"} />
          <div className="d__flex flex__col gap__4">
            <span className="fs__18 fw__600 solid__black__80">{numberOfGuest}</span>
            <span className="fs__16 solid__black__60 md-fs__14">
              {t("lead_offer_contract.number_of_travelers")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContractDetail;
