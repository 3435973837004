import React from 'react'

const DZRenderHtml = ({ encodedDetail, className }) => {
    const decodeAndConvertToHTML = (encodedString) => {
        const decodedString = document.createElement("textarea");
        decodedString.innerHTML = encodedString;
        const originalForm = decodedString.value;
        return `<p>${originalForm}</p>`;
      };
      const htmlString = decodeAndConvertToHTML(encodedDetail);
      return <div className={className} dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

export default DZRenderHtml;