import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import Home from "../pages/home/Home";
import Hotels from "../pages/hotels/Hotels";
import HotelDetail from "../pages/hotelDetail/HotelDetail";
import LeadOffer from "../pages/leadOffer/LeadOffer";
import "../assets/globals.css";
import "../assets/devClasses.css";
import ContractPage from "../pages/contractPage/ContractPage";
import About from "../pages/about/About";
import Info from "../pages/info/Info";
import Page from "../pages/page/Page";
import Referencer from "../pages/referencer/Referencer";
import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy";
import RoutesLink from "../constant/RoutesLink";
import TradeConditions from "../pages/tradeConditions/TradeConditions";
import ContractPassenger from "../pages/contractPassenger/ContractPassenger";
import LeadsPage from "../pages/leadsPage/LeadsPage";
import LeadOffersPage from "../pages/leadOffersPage/LeadOffersPage";
import LeadContractPage from "../pages/leadContractPage/LeadContractPage";
import ProfilePage from "../pages/profilePage/ProfilePage";
import MessagePage from "../pages/messagesPage/MessagePage";
import FaqsPage from "../pages/faqPage/FaqsPage";
import PrivateRoute from "./PrivateRoute.jsx";

function ReactRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={RoutesLink.HOME} element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={RoutesLink.HOTELS} element={<Hotels />} />
          <Route path={RoutesLink.ABOUT} element={<About />} />
          <Route path={RoutesLink.INFO} element={<Info />} />
          <Route path={RoutesLink.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route
            path={RoutesLink.TRADES_CONDITIONS}
            element={<TradeConditions />}
          />
          <Route path={RoutesLink.REFERENCER} element={<Referencer />} />
          <Route path={RoutesLink.PAGE} element={<Page />} />
          <Route path={RoutesLink.HOTEL_DETAIL} element={<HotelDetail />} />
          <Route path={RoutesLink.LEAD_OFFER} element={<LeadOffer />} />
          <Route path={RoutesLink.LEAD_CONTRACT} element={<ContractPage />} />
          <Route
            path={RoutesLink.CONTRACT_PASSENGER}
            element={<ContractPassenger />}
          />

          <Route element={<PrivateRoute />}>
            <Route path={RoutesLink.LEADS_PAGE} element={<LeadsPage />} />
            <Route
              path={RoutesLink.LEADS_OFFERS_PAGE}
              element={<LeadOffersPage />}
            />
            <Route
              path={RoutesLink.LEADS_CONTRACT_PAGE}
              element={<LeadContractPage />}
            />
            <Route path={RoutesLink.PROFILE_PAGE} element={<ProfilePage />} />
            <Route path={RoutesLink.MESSAGE_PAGE} element={<MessagePage />} />
            <Route path={RoutesLink.FAQS} element={<FaqsPage />} />
          </Route>

          <Route path="*" element={"Not Found"} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default ReactRoutes;
