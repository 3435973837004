import React, { useEffect, useRef, useState } from "react";
import LocationBlue from "../../../../assets/Icons/location__blue.jsx";
import CategoryBlue from "../../../../assets/Icons/category__blue.jsx";
import DateBlue from "../../../../assets/Icons/calender__blue.jsx";
import UserMultiple from "../../../../assets/Icons/user__multiple.jsx";
import FlightBlue from "../../../../assets/Icons/flight__blue.jsx";
import SearchWhite from "../../../../assets/Icons/search__white.jsx";
import GuestsPopup from "./Components/GuestsPopup/GuestsPopup.jsx";
import FlightPopup from "./Components/FlightPopup/FlightPopup.jsx";
import LocationPopup from "./Components/LocationPopup/LocationPopup.jsx";
import CategoryPopup from "./Components/CategoryPopup/CategoryPopup.jsx";
import DateCalender from "./Components/DateCalender/DateCalender.jsx";
import "./SearchFilter.css";
import YAKText from "../../../shared/YakText/YAKText.js";
import SportIcon from "../../../shared/SportIcon/SportIcon.js";
import useSearchFilter from "../../../../hooks/searchFilter/useSearchFilter.js";
import useHomePageDataManager from "../../../../hooks/useHomePageDataManager.js";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../shared/local_storage/index.js";
import useOutsideClick from "../../../../hooks/useOutsideClick.js";
import DZText from "../../../text/DZText.js";
import { Config } from "../../../../constant/Index.js";
const SearchFilter = ({ NavSearchFilter }) => {
  const {
    airportData,
    activeFilter,
    searchFields,
    setSearchFields,
    handleClosePopup,
    handleFilter,
    handleSearch,
    setActiveFilter,
    setAirportData,
  } = useSearchFilter();
  const {
    airportsByCountryIds,
    airportsByCityIds,
    countries,
    regionAirports,
    sports,
  } = useHomePageDataManager();

  const searchRef = useRef();
  const selectedLanguage = getLocalStorage(Config.language);

  useOutsideClick(searchRef, handleClosePopup);
  useEffect(() => {
    if (!searchFields?.country || !searchFields?.countryId) {
      setSearchFields({
        ...searchFields,
        country: countries?.[0]?.name,
        countryId: countries?.[0]?.id,
      });
    }
    setSearchFields((prev) => ({
      ...prev,
      sport: sports?.[0]?.name?.en,
      sportId: sports?.[0]?.id,
    }));
  }, [countries, sports]);
  useEffect(() => {
    setSearchFields((prev) => ({
      ...prev,
      flight: airportData?.[0]?.name,
      flightId: airportData?.[0]?.id,
    }));
  }, [
    airportData,
    searchFields?.city,
    searchFields?.cityId,
    searchFields?.country,
    searchFields?.countryId,
  ]);
  useEffect(() => {
    const bAirports = searchFields?.countryId
      ? Object.values(airportsByCountryIds[searchFields?.countryId] || {})
      : Object.values(airportsByCityIds[searchFields?.cityId] || {});
    const commonAirports = regionAirports?.filter((rAirport) => {
      return bAirports?.some((bAirport) =>
        rAirport && bAirport ? rAirport.id === bAirport.id : false
      );
    });
    setAirportData(commonAirports);
  }, [
    airportsByCountryIds,
    airportsByCityIds,
    searchFields?.countryId,
    searchFields?.cityId,
  ]);
  useEffect(() => {
    setSearchFields(JSON.parse(getLocalStorage("searchField")) || searchFields);
  }, [getLocalStorage("searchField")]);

  return (
    <div
      ref={searchRef}
      className={`${
        NavSearchFilter
          ? "d__flex align__center gap__8 p__08  radi__48 b__1__primary20 nav__searchFilter"
          : "hero__search"
      }`}
    >
      <SearchSection
        handleFilter={handleFilter}
        activeFilter={activeFilter == "location" ? "search__tabActive" : ""}
        searchFields={searchFields}
        setSearchFields={setSearchFields}
        handleClosePopup={handleClosePopup}
        popupComponent={
          <LocationPopup
            setSearchFields={setSearchFields}
            searchFields={searchFields}
            activeFilter={activeFilter}
            handleClosePopup={handleClosePopup}
          />
        }
        popupIcon={
          <LocationBlue className={`${NavSearchFilter ? "w__16 h__16" : ""}`} />
        }
        type={"location"}
        value={
          searchFields?.country ? (
            <DZText>{searchFields?.country}</DZText>
          ) : searchFields?.city ? (
            searchFields?.city
          ) : (
            <DZText>{"search_filter.select_location"}</DZText>
          )
        }
        NavSearchFilter={NavSearchFilter}
      />
      <SearchSection
        handleFilter={handleFilter}
        activeFilter={activeFilter == "category" ? "search__tabActive" : ""}
        searchFields={searchFields}
        setSearchFields={setSearchFields}
        handleClosePopup={handleClosePopup}
        popupComponent={
          <CategoryPopup
            setSearchFields={setSearchFields}
            searchFields={searchFields}
            activeFilter={activeFilter}
            handleClosePopup={handleClosePopup}
          />
        }
        popupIcon={
          searchFields?.sport?.en ? (
            <SportIcon
              sport={searchFields?.sport?.en}
              iconColor="#4A6DA7"
              className={`${NavSearchFilter ? "w__16 h__16" : "w__24 h__24"}`}
            />
          ) : (
            <CategoryBlue
              className={`${NavSearchFilter ? "w__16 h__16" : "w__24 h__24"}`}
            />
          )
        }
        type={"category"}
        value={
          searchFields?.sport ? (
            <DZText
              className={`${
                NavSearchFilter ? "solid__black__80 fs__12" : "poppins__18"
              }`}
            >
              {searchFields?.sport}
            </DZText>
          ) : (
            <DZText>{"search_filter.select_sport"}</DZText>
          )
        }
        NavSearchFilter={NavSearchFilter}
      />
      <SearchSection
        handleFilter={handleFilter}
        activeFilter={activeFilter == "guests" ? "search__tabActive" : ""}
        searchFields={searchFields}
        setSearchFields={setSearchFields}
        handleClosePopup={handleClosePopup}
        popupComponent={
          <GuestsPopup
            values={searchFields?.guests}
            setSearchFields={setSearchFields}
            searchFields={searchFields}
            activeFilter={activeFilter}
            onClose={handleClosePopup}
          />
        }
        popupIcon={
          <UserMultiple className={`${NavSearchFilter ? "w__16 h__16" : ""}`} />
        }
        type={"guests"}
        value={searchFields?.guests}
        NavSearchFilter={NavSearchFilter}
      />
      <SearchSection
        handleFilter={handleFilter}
        activeFilter={activeFilter == "date" ? "search__tabActive" : ""}
        searchFields={searchFields}
        setSearchFields={setSearchFields}
        handleClosePopup={handleClosePopup}
        popupComponent={
          <DateCalender
            setSearchFields={setSearchFields}
            searchFields={searchFields}
            activeFilter={activeFilter}
            date={searchFields.date}
            setActiveFilter={setActiveFilter}
          />
        }
        popupIcon={
          <DateBlue className={`${NavSearchFilter ? "w__16 h__16" : ""}`} />
        }
        type={"date"}
        value={
          searchFields?.from_date || searchFields?.to_date ? (
            `${Config.fnMultilingualDate(searchFields?.from_date)} - ${Config.fnMultilingualDate(searchFields?.to_date)}`
          ) : (
            <DZText>{"search_filter.date"}</DZText>
          )
        }
        NavSearchFilter={NavSearchFilter}
      />
      <SearchSection
        handleFilter={handleFilter}
        activeFilter={activeFilter == "flight" ? "search__tabActive" : ""}
        searchFields={searchFields}
        setSearchFields={setSearchFields}
        handleClosePopup={handleClosePopup}
        popupComponent={
          <FlightPopup
            activeFilter={activeFilter}
            airportData={airportData}
            searchFields={searchFields}
            setSearchFields={setSearchFields}
            setActiveFilter={setActiveFilter}
          />
        }
        popupIcon={
          <FlightBlue className={`${NavSearchFilter ? "w__16 h__16" : ""}`} />
        }
        type={"flight"}
        value={
          searchFields?.flight ? (
            searchFields?.flight
          ) : (
            <DZText>{"search_filter.select_airport"}</DZText>
          )
        }
        NavSearchFilter={NavSearchFilter}
      />
      <div
        className={` ${
          NavSearchFilter
            ? "back__orange__100 d__flex align__center justify__center gap__8 pl__8 pr__8 pt__8 pb__8 radi__20 cursor__p b__1__transparent"
            : "search__tab align__center justify__center search__btn"
        }`}
        onClick={handleSearch}
      >
        <SearchWhite className={`${NavSearchFilter ? "w__16 h__16" : ""}`} />
        <DZText className={NavSearchFilter ? "d__none" : "poppins__18"}>
          {"search_filter.search"}
        </DZText>
      </div>
    </div>
  );
};

const SearchSection = ({
  handleFilter,
  activeFilter,
  popupComponent,
  popupIcon,
  type,
  value,
  NavSearchFilter,
}) => {
  return (
    <div
      className={`${
        NavSearchFilter
          ? "position__rel"
          : type == "location"
          ? "search__tab w__full align__center"
          : "search__tab align__center justify__center"
      } ${activeFilter} `}
    >
      <div
        className={` ${
          NavSearchFilter
            ? "back__primary__05 d__flex align__center justify__center gap__8 pl__12 pr__12 pt__8 pb__8 radi__20 cursor__p hover-border__blue transition__03 b__1__transparent"
            : "w__full d__flex gap__8 align__center"
        }`}
        onClick={() => handleFilter(type)}
      >
        <span className={` ${NavSearchFilter ? "lg-d__none" : ""}`}>
          {popupIcon}
        </span>
        <span
          className={` text__capitalize ${
            NavSearchFilter ? "solid__black__80 fs__12" : "poppins__18"
          }`}
        >
          {value}
        </span>
      </div>
      {popupComponent}
    </div>
  );
};
export default SearchFilter;
