import React from "react";
import ServicesCard from "../includeServices/ServicesCard";
import { useTranslation } from "react-i18next";
// import {ContractForTraned}  from "../../../constant/ContractForTraning";
function ContractForTraning({ lead_contract_conditions }) {
  const { t } = useTranslation();
  // const ContractForTraning = [
  //   {
  //     heading: t("lead_offer_contract.important_info"),
  //     icon: "",
  //     services: lead_contract_conditions?.filter(
  //       (item) => item?.type === "Important Info"
  //     ),
  //   },
  //   {
  //     heading: t("lead_offer_contract.workout"),
  //     icon: "",
  //     services: lead_contract_conditions?.filter((item) => item?.type === "Workout"),
  //   },
  //   {
  //     heading: t("lead_offer_contract.meal_time"),
  //     icon: "",
  //     services: lead_contract_conditions?.filter((item) => item?.type === "Meal Times"),
  //   },
  // ];
  return (
    <div className="d__flex flex__col gap__24 w__full">
      <h2 className="fw__600 solid__black__80 fs__30 md-fs__26">
        {t("lead_offer_contract.contract_for_training_camp")}
      </h2>
      {lead_contract_conditions?.map((data, index) => {
        return (
          <ServicesCard
            key={index}
            heading={data?.name}
            services={data?.contract_conditions}
          />
        );
      })}
    </div>
  );
}
export default ContractForTraning;