import React from "react";
import CompanyBlue from "../../../assets/Icons/company__blue";
import CvrBlue from "../../../assets/Icons/cvr__blue";
import SignBlue from "../../../assets/Icons/sign__blue";
import AddressBlue from "../../../assets/Icons/location__blue";
import useLeadContract from "../../../hooks/useLeadContract";
import { useTranslation } from "react-i18next";
function ComponyDetail() {
  const {t} = useTranslation()
    // const { componyDetail } = useLeadContract();
  return (
    <div className="inner__container">
      <div className="container__li">
        <CompanyBlue />
        <div className="d__flex flex__col w__full">
          <span className="li__bold row__text overflow__h w__full">
            Yaksport
          </span>
          <span className="poppins__18">{t("lead_offer_contract.compony_name")}</span>
        </div>
      </div>
      <div className="container__li">
        <AddressBlue className={"min__w__28 min__h__28"} />
        <div className="d__flex flex__col w__full">
          <span
            className="li__bold row__text overflow__h w__full"
            style={{ textOverflow: "ellipsis" }}
          >
            Karen Blixens Blvd. 7, 8220 Brabrand
          </span>
          <span className="poppins__18 row__text overflow__h w__full">
            {t("lead_offer_contract.address")}
          </span>
        </div>
      </div>
      <div className="container__li">
        <CvrBlue />
        <div className="d__flex flex__col w__full">
          <span className="li__bold row__text overflow__h w__full">
            38 71 56 90
          </span>
          <span className="poppins__18">CVR</span>
        </div>
      </div>
      <div className="container__li">
        <SignBlue />
        <div className="d__flex flex__col w__full">
          <span className="li__bold row__text overflow__h w__full">{t("lead_offer_contract.sender")}</span>
          <span className="poppins__18">{t("lead_offer_contract.after_this")}</span>
        </div>
      </div>
    </div>
  );
}

export default ComponyDetail;
