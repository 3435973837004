import React from "react";
import FlagBlue from "../../../assets/Icons/flag__blue";
import { useTranslation } from "react-i18next";
import useLeadContract from "../../../hooks/useLeadContract";

function HTMLRenderer({ encodedDetail,milestoneDate1, milestoneDate2 }) {
  const decodeAndConvertToHTML = (encodedString) => {
    // console.log(encodedString);
    if (!encodedString) return '';
    let decodedString = encodedString.replace('[[[MILESTONE_DATE1]]]', milestoneDate1);
    decodedString = decodedString.replace('[[[MILESTONE_DATE2]]]', milestoneDate2);

    // Create temporary element to decode HTML entities
    const tempElement = document.createElement("textarea");
    tempElement.innerHTML = decodedString;
    const originalForm = tempElement.value;
    return `<p>${originalForm}</p>`;
    // const decodedString = document.createElement("textarea");
    // decodedString.innerHTML = encodedString;
    // const originalForm = decodedString.value;
    // return `<p>${originalForm}</p>`;
  };
  const htmlString = decodeAndConvertToHTML(encodedDetail);
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

function MilestoneDetail() {
  const { t } = useTranslation();
  const { milestones_detail,milestone_date1,milestone_date2 } = useLeadContract();
  const selectedLanguage = localStorage.getItem("i18nextLng") || "en";
  return (
    <>
      <HTMLRenderer encodedDetail={milestones_detail?.[selectedLanguage]}  milestoneDate1={milestone_date1}
        milestoneDate2={milestone_date2} />
    </>
  );
}

export default MilestoneDetail;
