import React from 'react'
import DZText from '../../../components/text/DZText'

const HotelSportTab = ({handleSelectedSport,activeSport,sports,hotel}) => {
  return (
    <div className="d__flex align__center gap__16 min__w__full md-overflowY__s md-gap__8 md-">
    <span
      className={`md-pr__16 md-pl__16 row__text ${
        activeSport
          ? "solid__black__80 poppins__18"
          : "opacity__white__100 back__primary__100 "
      } d__flex align__center justify__center pt__16  pb__16  pr__24 pl__24 radi__32  cursor__p lg-pt__12 lg-pb__12`}
      onClick={() => handleSelectedSport(null)}
    >
      {hotel}
    </span>

    {sports?.map((sport, index) => (
      <span
        key={index}
        className={`md-pr__16 md-pl__16 row__text ${
          activeSport == sport
            ? "opacity__white__100 back__primary__100"
            : " poppins__18"
        } d__flex align__center justify__center pt__16  pb__16  pr__24 pl__24 radi__32  cursor__p lg-pt__12 lg-pb__12`}
        onClick={() => handleSelectedSport(sport)}
      >
        <DZText className={'capitalize'}>{sport?.name}</DZText>
      </span>
    ))}
  </div>
  )
}

export default HotelSportTab