import React from "react";
import CheckBoxBlack from "./../../../assets/Icons/checkbox__black";
import { useTranslation } from "react-i18next";
import DZText from "../../../components/text/DZText";
const Attributes = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <h2 className="solid__black__80 fs__30 fw__500 lg-fs__24 md-fs__20">
        {t("hotel_detail.facilities_practical")}
      </h2>
      <div className="w__full b__1__black10 p__24 d__flex flex__col gap__16 radi__16">
        {data?.map((data, index) => (
          <div className="d__flex align__center gap__8" key={index}>
            <CheckBoxBlack ClassName="w__20 h__20" />
            <p className="solid__black__60 fs__16 fw__400 md-fs__14">
              {/* {data?.name?.en} */}
              <DZText>{data?.name}</DZText>
            </p>
          </div>
        ))}
      </div>
    </>
  );
};
export default Attributes;