import React from 'react'

const CvrBlue = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none" className={className}>
            <path d="M1.33398 14C1.33398 8.02889 1.33398 5.04332 3.18897 3.18833C5.04397 1.33334 8.02953 1.33334 14.0007 1.33334C19.9718 1.33334 22.9573 1.33334 24.8123 3.18833C26.6673 5.04332 26.6673 8.02889 26.6673 14C26.6673 19.9711 26.6673 22.9567 24.8123 24.8117C22.9573 26.6667 19.9718 26.6667 14.0007 26.6667C8.02953 26.6667 5.04397 26.6667 3.18897 24.8117C1.33398 22.9567 1.33398 19.9711 1.33398 14Z" stroke="#4A6DA7" stroke-width="1.5" />
            <path d="M1.33398 10L26.6673 10" stroke="#4A6DA7" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M7.33373 6H7.3457" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.6677 6H12.6797" stroke="#4A6DA7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19.3333 15.3333L20.9687 16.7429C21.6562 17.3355 22 17.6318 22 18C22 18.3682 21.6562 18.6645 20.9687 19.2571L19.3333 20.6667" stroke="#4A6DA7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.66667 15.3333L7.03128 16.7429C6.34376 17.3355 6 17.6318 6 18C6 18.3682 6.34376 18.6645 7.03128 19.2571L8.66667 20.6667" stroke="#4A6DA7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.3346 14L12.668 22" stroke="#4A6DA7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default CvrBlue