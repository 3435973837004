import React from "react";
import { useTranslation } from "react-i18next";
import MetaTags from "../../components/SEO/MetaTags";
import PAGE_URLS from "../../constant/Index";

function PrivacyPolicy() {
  const { t } = useTranslation();

  const description = `
  At YakSport, we prioritize data security and responsible handling of personal information. 
  Our Privacy Policy outlines how we collect and use your personal information as a customer, 
  and how you can access your data. Learn more about our commitment to protecting your privacy.
  `

  return (
    <>
      <MetaTags href={"privacypolicy"} pageName={"privacy policy"} content={description} metaTitle={"Privacy Policy | YakSport - Data Security and Personal Information Handling"} />

      <div className="d__flex flex__col gap__20 section">
        <h1 className="section__heading fs__44 ">{t("privacy_policy.heading")}</h1>

        <h1 className="section__heading">{t("privacy_policy.section_1_heading")}</h1>
        <p className="paragraph">{t("privacy_policy.section_1_detail")}</p>

        <h1 className="section__heading">{t("privacy_policy.section_2_heading")}</h1>
        <p className="paragraph">{t("privacy_policy.section_2_detail")}</p>

        <h1 className="section__heading">{t("privacy_policy.section_3_heading")}</h1>
        <p className="paragraph">{t("privacy_policy.section_3_detail_1")}</p>
        <p className="paragraph">{t("privacy_policy.section_3_detail_2")}</p>
        <p className="paragraph">{t("privacy_policy.section_3_detail_3")}</p>
        <p className="paragraph">{t("privacy_policy.section_3_detail_4")}</p>
        <p className="paragraph">{t("privacy_policy.section_3_detail_5")}</p>

        <h1 className="section__heading">{t("privacy_policy.section_4_heading")}</h1>
        <p className="paragraph">{t("privacy_policy.section_4_detail_1")}</p>
        <p className="paragraph">{t("privacy_policy.section_4_detail_2")}</p>
        <p className="paragraph">{t("privacy_policy.section_4_detail_3")}</p>
        <p className="paragraph">{t("privacy_policy.section_4_detail_4")}</p>
        <p className="paragraph">{t("privacy_policy.section_4_detail_5")}</p>
        <p className="paragraph">{t("privacy_policy.section_4_detail_6")}</p>
        <p className="paragraph">{t("privacy_policy.section_4_detail_7")}</p>

        <h1 className="section__heading">{t("privacy_policy.section_5_heading")}</h1>
        <p className="paragraph">{t("privacy_policy.section_5_detail_1")}</p>

        <h1 className="section__heading">{t("privacy_policy.section_6_heading")}</h1>
        <p className="paragraph">{t("privacy_policy.section_6_detail_1")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_2")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_3")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_4")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_5")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_6")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_7")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_8")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_9")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_10")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_11")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_12")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_13")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_14")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_15")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_16")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_17")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_18")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_19")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_20")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_21")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_22")}</p>
        <p className="paragraph">{t("privacy_policy.section_6_detail_23")}</p>

        <h1 className="section__heading">{t("privacy_policy.section_7_heading")}</h1>
        <p className="paragraph">{t("privacy_policy.section_7_detail_1")}</p>
        <p className="paragraph">{t("privacy_policy.section_7_detail_2")}</p>
        <p className="paragraph">{t("privacy_policy.section_7_detail_3")}</p>
        <p className="paragraph">{t("privacy_policy.section_7_detail_4")}</p>
        <p className="paragraph">{t("privacy_policy.section_7_detail_5")}</p>
        <p className="paragraph">{t("privacy_policy.section_7_detail_6")}</p>
        <p className="paragraph">{t("privacy_policy.section_7_detail_7")}</p>
        <p className="paragraph">{t("privacy_policy.section_7_detail_8")}</p>
        <p className="paragraph">{t("privacy_policy.section_7_detail_9")}</p>

        <h1 className="section__heading">{t("privacy_policy.section_8_heading")}</h1>
        <p className="paragraph">{t("privacy_policy.section_8_detail_1")}</p>
        <p className="paragraph">{t("privacy_policy.section_8_detail_2")}</p>

        <h1 className="section__heading">{t("privacy_policy.section_9_heading")}</h1>
        <p className="paragraph">{t("privacy_policy.section_9_detail_1")}</p>
        <p className="paragraph">{t("privacy_policy.section_9_detail_2")}</p>
        <p className="paragraph">{t("privacy_policy.section_9_detail_3")}</p>
        <p className="paragraph">{t("privacy_policy.section_9_detail_4")}</p>
        <p className="paragraph">{t("privacy_policy.section_9_detail_5")}</p>
        <p className="paragraph">{t("privacy_policy.section_9_detail_6")}</p>
        <p className="paragraph">{t("privacy_policy.section_9_detail_7")}</p>

        <h1 className="section__heading">{t("privacy_policy.section_10_heading")}</h1>
        <p className="paragraph">{t("privacy_policy.section_10_detail_1")}</p>
      </div>
    </>
  );
}
export default PrivacyPolicy;