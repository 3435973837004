// PersonDetail.js
import React from "react";

function PersonDetail({ person, onChange, personIndex }) {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };
console.log(person);
  return (
    <div key={personIndex} className="flex flex-col gap-[16px]">
      <span className="text-[#333]">Person {personIndex + 1}</span>
      <div className="flex flex-col items-center gap-[6px] ">
        <select
          className="w-full rounded-[10px] border-[1px] border-[#999] bg-transparent p-[10px] text-[#999] outline-none"
          name="gender"
          value={person.gender}
          onChange={handleInputChange}
        >
          <option value="MR">Mr.</option>
          <option value="MRS">Mrs.</option>
          <option value="Inf">Inf.</option>
        </select>
        <input
          type="date"
          className="w-full rounded-[10px] border-[1px] border-[#999] bg-transparent p-[10px] text-[#999] outline-none placeholder:text-[#999]"
          placeholder="Date of Birth"
          name="dob"
          value={person.dob}
          onChange={handleInputChange}
        />
        <input
          type="text"
          className="w-full rounded-[10px] border-[1px] border-[#999] bg-transparent p-[10px] text-[#999] outline-none placeholder:text-[#999]"
          placeholder="First Name"
          name="first_name"
          value={person.first_name}
          onChange={handleInputChange}
        />
        <input
          type="text"
          className="w-full rounded-[10px] border-[1px] border-[#999] bg-transparent p-[10px] text-[#999] outline-none placeholder:text-[#999]"
          placeholder="Last Name"
          name="last_name"
          value={person.last_name}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
}

export default PersonDetail;
