import React from 'react'
import LeadsSideBar from '../shared/leadsSideBar/LeadsSideBar'

// import createSvgIcon from './utils/createSvgIcon';


const LeadLayout = ({ children, badgeText='' }) => {
  return (
    <div className="flex items-center justify-center w-full">
      <div className='flex items-start gap-[32px] w-full section'>
        <LeadsSideBar badgeText={badgeText} />
        {children}      
      </div>
    </div>
  )
}

export default LeadLayout 