import React from 'react'
import Hero from "../../components/cHome/hero/index"
import useHomePageDataManager from '../../hooks/useHomePageDataManager'
import HotelCards from "../../components/cHome/hotelCards/index"
import GallerySection from "../../components/cHome/gallerySection/index"
import TriPlanner from '../../components/cHome/triPlanner/index'
import Loader from '../../assets/Loader'
import DZText from '../../components/text/DZText'
import MetaTags from '../../components/SEO/MetaTags'
import Faqs from '../../components/cHome/faqs/Faqs'
function Home() {
  const { popularSportsHotels, isLoadingAllHomeData } = useHomePageDataManager();
  const popularSportsHotelsArray = popularSportsHotels ? Object.values(popularSportsHotels) : [];
  const description = `
  Discover personalized training camp offers for Danish clubs, associations, and schools at YakSport. 
  With over 15 years of experience, we ensure short response times, free consultations, and satisfied customers.
  `
  return (
    <>
      <MetaTags href={"home"} pageName={"home"} content={description} metaTitle={"Training Camps for Everyone | Danish Travel Agency - YakSport"}/>
      <Hero />
      {isLoadingAllHomeData ? <Loader />
        : <>
          {popularSportsHotelsArray?.map((hotel, index) => {
            return (
              <HotelCards
                key={index}
                SectionHeading={hotel?.sports?.name}
                cardsData={hotel?.hotels}
              />
            );
          })}
          <GallerySection />
        </>
      }
      <TriPlanner />
    </>
  );
}
export default Home