import React, { useState } from "react";
import CrossIcon from "../../assets/Icons/crossIcon.png";
import MailIcon from "../../assets/Icons/mail__blue";
import DZText from "../../components/text/DZText";
import Input from "../../components/shared/input/Input";
import { Button } from "@mui/material";
import { useUpdateContractStatusMutation } from "../../redux/storeApis";
import { useSnackBarManager } from "../../hooks/useSnackBarManager";
function CodeModal({ setShowCodeModal, otpCodeData, modalType, slug }) {
  const [inOtpCode, setInOtpCode] = useState();
const {fnShowSnackBar} = useSnackBarManager()
  const [updateContractStatus, { isLoading: isUpdateContractStatusLoading }] =
    useUpdateContractStatusMutation();
  const fnHandleSubmit = async () => {
    const data = {
      code: inOtpCode,
      status: modalType == "sign" ? "success" : "reject",
    };
    await updateContractStatus({ slug, data }).unwrap().then((res) => {
      if (res?.success) {
        setShowCodeModal(false);
        fnShowSnackBar(res?.message ||"Code verified successfully");
        window.location.reload();
      }
    }).catch((err) => {
      fnShowSnackBar(err?.data?.message ||"Something went wrong", true)
    })
  };
  return (
    <>
      <span className={`model__back`}></span>
      <div className={`login__model`}>
        <img
          src={CrossIcon}
          className="cursor__p position__abs r__20 t__20"
          alt=""
          onClick={() => setShowCodeModal(false)}
        />
        <div className="d__flex flex__col gap__12 align__center w__full">
          <h2 className="model__heading">
            {modalType == "sign" ? "Code for Sign" : "Code for Reject"}
          </h2>
          <p className="poppins__16">Enter your OTP</p>
        </div>
        <Input
          icon={<MailIcon className="w__24 h__24" />}
          placeholder="Enter your Otp code"
          type="text"
          name="email"
          required={true}
          onChange={(e) => setInOtpCode(e.target.value)}
          value={inOtpCode}
        />
        <dev
          className={
            "cursor__p w__full md-fs__15 back__primary__100 opacity__white__100 pl__32 pr__32 pb__12 pt__12 radi__24 min__w__200 text__center sm-min__w__full"
          }
          onClick={fnHandleSubmit}
        >
          {isUpdateContractStatusLoading? "Loading..." :"Submit"}
        </dev>
      </div>
    </>
  );
}
export default CodeModal;