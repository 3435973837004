import YAKText from "../../../../../shared/YakText/YAKText";
import useHomePageDataManager from "../../../../../../hooks/useHomePageDataManager";
import React from "react";
import "./locationPopup.css";
import DZText from "../../../../../text/DZText";
const LocationPopup = ({
  activeFilter,
  setSearchFields,
  searchFields,
  handleClosePopup,
  inputFiled,
  setInquiryFirstData,
}) => {
  const { countries, findCountryHotelsCount, findCityHotelsCount } =
    useHomePageDataManager();
  const handleSelectCountry = (value, id) => {
    if (inputFiled) {
      setInquiryFirstData((prevSearchFields) => ({
        ...prevSearchFields,
        country: value,
        countryId: id,
        city: undefined,
        cityId: undefined,
      }));
    } else {
      setSearchFields({
        ...searchFields,
        country: value,
        countryId: id,
        city: undefined,
        cityId: undefined,
      });
    }
  };
  const handleSelectCities = (value, id) => {
    if (inputFiled) {
      setInquiryFirstData((prevSearchFields)=> ({
        ...prevSearchFields,
        city: value,
        cityId: id,
        country: undefined,
        countryId: undefined,
      }));
    } else {
      setSearchFields({
        ...searchFields,
        country: undefined,
        countryId: undefined,
        city: value,
        cityId: id,
      });
    }
  };
  return (
    <div
      className={`search__popup  ${
        inputFiled ? "min__w__300 overflow__s h__300" : "min__w__360"
      }  ${
        activeFilter == "location" || inputFiled == "location"
          ? "active__popup"
          : ""
      }`}
    >
      {countries?.map((country) => {
        return (
          <>
            <span
              className="poppins__18 cursor__p d__flex align__center gap__12"
              onClick={() => {
                handleSelectCountry(country?.name?.en, country?.id);
                handleClosePopup();
              }}
            >
              <DZText className={`${searchFields?.countryId == country?.id && "fw__500 solid__primary__100" }`}>{country?.name}</DZText>
              <DZText className={'back__black__10 d__flex align__center justify__center w__fit w__28 h__28 radi__30'} >{findCountryHotelsCount(country?.id)}</DZText>
            </span>
            {!inputFiled &&country?.cities?.map((city) => {
              return (
                <>
                  <span
                    className="poppins__18 cursor__p d__flex align__center gap__12"
                    onClick={() => {
                      handleSelectCities(city?.name?.en, city?.id);
                      handleClosePopup();
                    }}
                  >
                    <DZText className={`pl__12 ${ searchFields?.cityId == city?.id && "fw__500 solid__primary__100"}`}>{city?.name}</DZText>{" "}
                    <DZText className={'back__black__10 d__flex align__center justify__center w__fit w__28 h__28 radi__30'} >{findCityHotelsCount(city?.id)}</DZText>
                  </span>
                </>
              );
            })}
          </>
        );
      })}
    </div>
  );
};
export default LocationPopup;