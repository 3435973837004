import React from "react";
import HotelBlue from "../../../assets/Icons/hotel__blue";
import ClubBlue from "../../../assets/Icons/club__blue";
import useLeadContract from "../../../hooks/useLeadContract";
import { useTranslation } from "react-i18next";
import DZText from "../../text/DZText";
function TravelDates() {
  const {
    endDate,
    startDate,
    cityName,
    airportName,
    flightTime,
    flightReturnTime,
  } = useLeadContract();
  const {t} = useTranslation()
  return (
    <div className="d__flex flex__col gap__24 w__full">
      <h2 className="fw__600 solid__black__80 fs__30 md-fs__26">{t("lead_offer_contract.travel_dates")}</h2>
      <div className="d__grid grid__tcol__2 gap__24 sm-grid__tcol__1">
        <div className="d__flex align__center gap__24 pl__24 pr__24 pb__20 pt__20 b__1__black10 radi__12">
          <ClubBlue className="min__w__32 min__h__32" />
          <div className="d__flex flex__col gap__4">
            <span className="fs__18 fw__600 solid__black__80">{t("lead_offer_contract.departure_date")}</span>
            <span className="fs__16 solid__black__60 md-fs__14">
              {startDate} {flightTime} {"("}
              {airportName}-<DZText>{cityName}</DZText>
              {")"}
            </span>
          </div>
        </div>
        <div className="d__flex align__center gap__24 pl__24 pr__24 pb__20 pt__20 b__1__black10 radi__12">
          <HotelBlue />
          <div className="d__flex flex__col gap__4">
            <span className="fs__18 fw__600 solid__black__80">{t("lead_offer_contract.return_date")}</span>
            <span className="fs__16 solid__black__60 md-fs__14">
              {endDate} {flightReturnTime} {"("}
              <DZText>{cityName}</DZText>-{airportName}
              {")"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TravelDates;