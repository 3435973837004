// ContractPassenger.js
import React, { useEffect, useState } from "react";
import {
  useAddContractPassengerMutation,
  useGetContractPassengerQuery,
} from "../../redux/storeApis";
import { useParams } from "react-router-dom";
import PlusIcon from "../../assets/Icons/PlusIcon";
import PersonDetail from "./PersonDetail";
import uniqid from "uniqid";
import { useSnackBarManager } from "../../hooks/useSnackBarManager";

function ContractPassenger() {
  const { slug } = useParams();
  const { data: contractPassenger, isLoading } =
    useGetContractPassengerQuery(slug);
  const [addContractPassenger, { isLoading: isAddContractPassengerLoading }] =
    useAddContractPassengerMutation();
  const [leadContractInfo, setLeadContractInfo] = useState({});
  const [roomTypes, setRoomTypes] = useState([]);
  const [rooms, setRooms] = useState([]);
  const { fnShowSnackBar } = useSnackBarManager();
  useEffect(() => {
    if (contractPassenger) {
      
      setRoomTypes(contractPassenger.data.room_types)
    }
    if (contractPassenger && contractPassenger.data.passenger_rooms) {
   
      // Initialize rooms based on passenger rooms and their corresponding room types
      const initializedRooms = [];
      const seenRandomBindIds = new Set(); // Use a Set to keep track of seen random_bind_ids
  
      contractPassenger.data.passenger_rooms.forEach(passengerDetail => {
        const { room_type, id, random_bind_id } = passengerDetail;
        const { no_beds, bed_type } = room_type;
  
        // Check if the random_bind_id has been seen before
        if (!seenRandomBindIds.has(random_bind_id)) {
          // If not seen, add the personal details
          const personal_details = contractPassenger.data.passenger_rooms
            .filter(detail => detail.random_bind_id === random_bind_id)
            .map(detail => ({
              id: detail?.id || uniqid(),
              passenger_id_room:detail?.id,
              first_name: detail?.first_name || "",
              passenger_type_id: detail.id,
              last_name: detail?.last_name || "",
              dob: detail?.dob || "",
              gender: detail?.gender || "",
            }));
  
          // Add the initialized room to the array
          initializedRooms.push({
            id: uniqid(),
            bed_type: bed_type,
            passenger_type_id: id,
            personal_details: personal_details,
          });
  
          // Add the random_bind_id to the seen set
          seenRandomBindIds.add(random_bind_id);
        }
      });
  
      setRooms(initializedRooms);
    }
  }, [contractPassenger]);
  
  
  
  

  const handlePersonDetailChange = (roomId, personId, fieldName, value) => {
    setRooms((prevRooms) => {
      return prevRooms.map((room) => {
        if (room.id === roomId) {
          return {
            ...room,
            personal_details: room.personal_details.map((person) => {
              if (person.id === personId) {
                return {
                  ...person,
                  [fieldName]: value,
                };
              }
              return person;
            }),
          };
        }
        return room;
      });
    });
  };

  const handleClickAddRoom = (data) => {
    const { bed_type, no_beds, id } = data;
    const newRoom = {
      id: uniqid(),
      bed_type: bed_type,
      passenger_type_id: id,
      personal_details: new Array(parseInt(no_beds)).fill(null).map(() => ({
        id: uniqid(),
        first_name: "",
        passenger_type_id: id,
        last_name: "",
        dob: "",
        gender: "",
      })),
    };
    setRooms([...rooms, newRoom]);
    fnShowSnackBar("Room added successfully");
  };
  const handleClickRemoveRoom = (roomId) => {
    setRooms((prevRooms) => prevRooms.filter((room) => room.id !== roomId));
    fnShowSnackBar("Room removed successfully");
  };
  const handleSubmit = () => {
    const data = {
      rooms: rooms,
      slug: slug,
    };
    addContractPassenger(data)
    .then((response) => {
      console.log(response);
      if (response?.data?.success) {
      
        fnShowSnackBar(response?.data?.message);
        window.location.reload();
      } else {
        fnShowSnackBar(response?.error?.data?.message);
     
      }
    })
    .catch((error) => {
      console.error("API Error:", error);
      fnShowSnackBar(error);
    })
} 
 
  return (
    <div className="flex w-full flex-col items-center justify-center">
      {isLoading && <div>Loading...</div>}
      <div className="section flex w-full flex-col gap-[24px] p-4">
        <div className="flex w-full items-center justify-center gap-[10px] max-[720px]:flex-wrap">
          {roomTypes.map((room, index) => (
            <div
              onClick={() => handleClickAddRoom(room)}
              key={index}
              className="flex w-full cursor-pointer items-center justify-center gap-[6px] rounded-[10px] bg-[#222] p-[10px] px-[32px] text-[#FFF] transition hover:bg-[#ff6B13] max-[720px]:w-[300px] max-sm:w-full"
            >
              <PlusIcon iconColor="#FFF" />
              {room.bed_type}
            </div>
          ))}
        </div>
        {rooms.map((room, roomIndex) => (
          <div
            key={roomIndex}
            className="flex w-full flex-col gap-[20px] rounded-[10px] bg-[#4a6da710] p-[10px]"
          >
            <div className="flex w-full items-center justify-between">
              <span className="text-[#333] font-[600] text-[18px]">
                {room.bed_type}{" "}
              </span>
              <PlusIcon
                onClick={() => handleClickRemoveRoom(room?.id)}
                className={"rotate-45"}
              />
            </div>
            <div className="grid grid-cols-2 gap-[24px] max-sm:grid-cols-1">
              {room.personal_details.map((person, personIndex) => (
                <PersonDetail
                  key={personIndex}
                  personIndex={personIndex}
                  person={person}
                  onChange={(fieldName, value) =>
                    handlePersonDetailChange(
                      room.id,
                      person.id,
                      fieldName,
                      value
                    )
                  }
                />
              ))}
            </div>
          </div>
        ))}
        <button
          type="button"
          onClick={handleSubmit}
          className="flex w-full items-center justify-center rounded-[10px] bg-[#222] p-[10px] px-[32px] text-[#FFF]"
        >
          {isAddContractPassengerLoading ? "Loading..." : "Save Passenger List"}
        </button>
      </div>
    </div>
  );
}

export default ContractPassenger;
