import React from "react";

const PlusIcon = ({className, iconColor = "#333", onClick}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="20"
      height="20"
      color={iconColor}
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M12 8V16M16 12L8 12"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
        stroke="currentColor"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default PlusIcon;
