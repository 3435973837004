import React from "react";
import UserBlue from "../../../assets/Icons/user__blue";
import MailBlue from "../../../assets/Icons/mail__blue";
import ComputerVideoCall from "../../../assets/Icons/computer__call";
import PhoneBlue from "../../../assets/Icons/phone__blue";
import Signature from "../../../assets/Images/signature.png";
import useLeadContract from "../../../hooks/useLeadContract";
import { useTranslation } from "react-i18next";
function ClientSection() {
  const { userDetail, clubName } = useLeadContract();
  const { t } = useTranslation();
  return (
    <div className="inner__container position__rel">
      <img
        src={Signature}
        alt=""
        className="position__abs t__12 r__12 lg-t__60"
      />
      <div className="container__li">
        <UserBlue />
        <div className="d__flex flex__col w__full">
          <span className="li__bold row__text overflow__h w__full">
            {userDetail?.first_name} {userDetail?.last_name}
          </span>
          <span className="poppins__18">{t("lead_offer_contract.name")}</span>
        </div>
      </div>
      <div className="container__li">
        <PhoneBlue className={""} />
        <div className="d__flex flex__col w__full">
          <span
            className="li__bold row__text overflow__h w__full"
            style={{ textOverflow: "ellipsis" }}
          >
            {userDetail?.mobile}
          </span>
          <span className="poppins__18 row__text overflow__h w__full">
            {t("lead_offer_contract.mobile")}
          </span>
        </div>
      </div>
      <div className="container__li">
        <ComputerVideoCall />
        <div className="d__flex flex__col w__full">
          <span className="li__bold row__text overflow__h w__full">
            {clubName}
          </span>
          <span className="poppins__18">{t("lead_offer_contract.behalf")}</span>
        </div>
      </div>
      <div className="container__li">
        <MailBlue />
        <div className="d__flex flex__col w__full">
          <span className="li__bold row__text overflow__h w__full">
            {userDetail?.email}
          </span>
          <span className="poppins__18">{t("lead_offer_contract.email")}</span>
        </div>
      </div>
      <span className="poppins__18">{t("lead_offer_contract.signature")}</span>
    </div>
  );
}
export default ClientSection;